import React,{useState,useEffect,useContext} from "react";
import classes from './sidebarList.module.css';
import CategoryPoint from './CategoryPoint';
import i18next from "i18next";
import {  Select, Radio, Button, Drawer, Space, Divider,Checkbox } from 'antd'
import LanguageContext from "../../../store/language-context";
import { useTranslation } from "react-i18next";

function SideBarList ({checkedList}) {
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation();
  const languageCtx = useContext(LanguageContext);

  function CategoryList({category}) {
    return  <CategoryPoint  categoryID={category} />
  }
  
  return (
    <div className={classes.sidebar_open}>
     
      <div className={classes.inner}>
   
      <div className="app11">
      <div className={classes.dummy11}>
        
          {checkedList.length == 0 &&
             <h2>Επιλέξτε κατηγορία</h2>
          }
          {checkedList.length != 0 &&
             <div>
              {checkedList.map(category => 
                  <CategoryList category={category} />
              )}
             </div>
          }
          
        </div>
             
        </div>
      </div>
      
    </div>
   
  );
};
export default SideBarList;
