import React, { useState, useEffect, useContext } from "react"
import { Row, Col, Select, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import classes from './styles.module.css'
import { useLocation, useParams, Link } from 'react-router-dom'
import cookies from "js-cookie";
import utils from 'utils'
import i18next from "i18next"
import LanguageContext from "../../store/language-context"
import { useTranslation } from "react-i18next"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'
import { CategoriesProps } from "services/props"
const { Option } = Select

const Categories: React.FC = () => {

  const items: TabsProps['items'] = [
    {
      key: 'Όλες',
      label: `Όλες οι Κατηγορίες`,
    },
    {
      key: 'Γνωρίστε το Ρέθυμνο',
      label: `Γνωρίστε το Ρέθυμνο`
    },
    {
      key: 'Αρχιτεκτονική',
      label: `Αρχιτεκτονική`
    }
  ];

  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation()
  const location = useLocation()

  var currentLanguageCode = cookies.get("i18next") || "el";
  const languageCtx = useContext(LanguageContext);


  const [current, setCurrent] = useState<string>(location?.state?.day ? location?.state?.day : '40');

  const { id } = useParams()
  console.log("IDcat:", location.state.category);

  const [categories, setCategories] = useState([]);
  const [listData, setListData] = useState([]);
  const [isActive1, setActive1] = useState(true);
  const [isActive2, setActive2] = useState(false);
  const [isActive3, setActive3] = useState(false);
  // Function to collect data
  const getCategories = async () => {
    const url = `https://rethymno.mitos.cityguideplatform.com/api/maincategories?lang=${currentLanguageCode}`
    const response = await fetch(url,
      {
        signal,
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    if (!response.ok) {
      throw new Error('Data could not be fetched!')
    }
    else {

      return response.json()

    }

  };
  const [category, setCategory] = useState('');

  const handleChange = (key: string, value: string) => {
    setCategory(value);

    switch (key) {
      case 'category':
        setCategory(value)
        let datas = utils.filterCategoriesArray(categories, value, current)
        setListData(datas)
        break;
      default:
        setCurrent(value)
    }
  };
  const toggleClass = (key: string) => {
    switch (key) {
      case 'Όλες':
        setActive1(true);
        setActive2(false);
        setActive3(false);
        break;
      case 'Γνωρίστε το Ρέθυμνο':
        setActive1(false);
        setActive2(true);
        setActive3(false);
        break;
      case 'Αρχιτεκτονική':
        setActive1(false);
        setActive2(false);
        setActive3(true);
        break;
      default:
        break;
    }


  };
  const onTabChange = (key: string) => {
    console.log(key);

    toggleClass(key);
    handleChange('category', key)
  };
  
  useEffect(() => {
    console.log("effect 1");
    getCategories()
      .then((res) => {
        // Filter out objects with IDs 15, 16, and 17
        const filteredCategories = res.success.filter((cat:any) => cat.id !== 21 && cat.id !== 20 && cat.id !== 19);
        setCategories(filteredCategories);
  
        const newCat = filteredCategories.map((cat:any) => {
          if (cat.extras._subcategories.value === location.state.category) {
            console.log(cat.extras._subcategories.value);
          }
          return cat; // Don't forget to return the mapped value
        });
  
        console.log("newCat", newCat);
  
        setListData(filteredCategories);
        console.log(res);
      })
      .catch((e) => {
        console.log(e.message);
      });
  
    console.log("effect 2");
  }, [i18next.language]);
  


  const myStyle = {
    backgroundImage: `url('img/rethymnoRivaldiFountain.png')`,
    height: '530px',
    fontSize: '50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
  const bgimg = {
    backgroundImage: `url("img/P1000423.jpg")`,
    height: '',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: '-130px',
    backgroundPosition: '25% 88%'
  };

  if (listData.length <= 0) { return <Spinner /> }

  console.log("end");

  // let datas = utils.filterCategoriesArray(categories, location.state.category, current)
  //       console.log(datas);

  //       console.log(datas);

  //       setListData(datas)
  //       console.log("ListData: ", listData);
  //       onTabChange(location.state.category)
  // const cat = location.state.category;
  // toggleClass(cat)
  return (
    <>
      <Row justify="end">
        <div style={bgimg}>

          <div className={classes.top_banner_txt}>
            <div>
              {/* <a href='/map?cat=11' className={classes.btn1}><img src='/img/explore.png' />{t("Γνωρίστε το Ρέθυμνο")}</a><div></div>
              <a href='/map?cat=12' className={classes.btn2}><img src='/img/arch.png' />{t("Αρχιτεκτονική του Ρεθύμνου")}</a> */}
              <span>{t('Γνώρισε τα σημαντικότερα αξιοθέατα του Ρεθύμνου')}</span>
              <h2><a href="/map" className={classes.btn1}>{t("Δες τον χάρτη")}</a></h2>
            </div>
          </div>

        </div>
      </Row>
      <Row justify="end">
        <Col xs={24} sm={24} lg={24}>
          <div className={classes.divider}>
            <div className={classes.dividerBlock}></div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className={classes.top_cat}>

          <Tabs className={[isActive1 ? `${classes.tabsNav1Active}` : (isActive2 ? `${classes.tabsNav2Active}` : (isActive3 ? `${classes.tabsNav3Active}` : `${classes.tabsNav}`)), `${classes.tabsNav}`].join(' ')} defaultActiveKey="Όλες" items={items} onChange={onTabChange} />
          {/* <Row className={classes.filters}>
          
            <Col xs={24} xl={24} lg={24}>
              <h4>{t('Κατηγορία')}</h4>&nbsp;
              <Select
                placeholder={t("Κατηγορία")}
                className={classes.type}
                size={'large'}
                placement="bottomLeft"
                showArrow={false}
                defaultValue={'Όλες'}
                autoClearSearchValue={false}
                onChange={(e) => {
                  setCategory(e)
                  handleChange("category", e)
                }} >

                <Option value="Όλες" className={'ant-option'}>{t("Όλες")} </Option>
                <Option value="Γνωρίστε το Ρέθυμνο" className={'ant-option'}>Γνωρίστε το Ρέθυμνο</Option>
                <Option value="Αρχιτεκτονική" className={'ant-option'}>Αρχιτεκτονική</Option>
              </Select>




            </Col>
          </Row> */}
          <div className="app11">
            {listData.map(category =>
              <Link to={`/category/${category['id']}`} state={{ category: category['name'] }}>
                <div className={classes.categoriesimg}>
                  <img src={category['main_image']} />
                  <span><b>{category['name']}</b></span>
                </div>
              </Link>
            )}
          </div>
          <br />
          <br />
        </div>
      </Row>


    </>
  )
}

export default Categories