import axios from 'axios'
import { IEvents } from "../services/props"

const API = ' https://rethymno-admin.repository.gr/api'

export const axiosEvents = {

  async getEvents() {
    const { data } = await axios.get(`https://rethymno-events.dotsoft.gr//api/el/events`)
    return data.events

  }

}