import React, { useState, useRef, useEffect,useContext } from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'
import { usePoints } from 'hooks/useMap'
import { useLocation } from "react-router-dom";
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup, useMapEvent ,GeoJSON} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import SvgPointer from "../../images/svg_pointers/index"
import Acropoli from "../../images/svg_pointers/map_marker_poi.svg"
import AcropoliA from "../../images/svg_pointers/map_marker_poiA.svg"
import AcropoliB from "../../images/svg_pointers/map_marker_poiB.svg"
import AcropoliC from "../../images/svg_pointers/map_marker_poiC.svg"
import AcropoliD from "../../images/svg_pointers/map_marker_poiD.svg"
import AcropoliE from "../../images/svg_pointers/map_marker_poiE.svg"
import AcropoliF from "../../images/svg_pointers/map_marker_poiF.svg"
import AcropoliG from "../../images/svg_pointers/map_marker_poiG.svg"
import AcropoliH from "../../images/svg_pointers/map_marker_poiH.svg"
import AcropoliI from "../../images/svg_pointers/map_marker_poiI.svg"
import AcropoliJ from "../../images/svg_pointers/map_marker_poiJ.svg"
import AcropoliK from "../../images/svg_pointers/map_marker_poiK.svg"
import AcropoliL from "../../images/svg_pointers/map_marker_poiL.svg"
import AcropoliM from "../../images/svg_pointers/map_marker_poiM.svg"
import AcropoliN from "../../images/svg_pointers/map_marker_poiN.svg"

import AcropoliaA from "../../images/svg_pointers/map_marker_poiaA.svg"
import AcropoliaB from "../../images/svg_pointers/map_marker_poiaB.svg"
import AcropoliaC from "../../images/svg_pointers/map_marker_poiaC.svg"
import AcropoliaD from "../../images/svg_pointers/map_marker_poiaD.svg"
import AcropoliaE from "../../images/svg_pointers/map_marker_poiaE.svg"
import AcropoliaF from "../../images/svg_pointers/map_marker_poiaF.svg"
import AcropoliaG from "../../images/svg_pointers/map_marker_poiaG.svg"
import AcropoliaH from "../../images/svg_pointers/map_marker_poiaH.svg"
import AcropoliaI from "../../images/svg_pointers/map_marker_poiaI.svg"
import AcropoliaJ from "../../images/svg_pointers/map_marker_poiaJ.svg"
import AcropoliaK from "../../images/svg_pointers/map_marker_poiaK.svg"
import AcropoliaL from "../../images/svg_pointers/map_marker_poiaL.svg"
import AcropoliaM from "../../images/svg_pointers/map_marker_poiaM.svg"
import AcropoliaN from "../../images/svg_pointers/map_marker_poiaN.svg"

import img0 from "../../images/png_pointers/0.png"
import img1 from "../../images/png_pointers/1.png"
import Acropoli1 from "../../images/svg_pointers/Acropoli.svg"
import HelmetComponent from 'components/Helmet'
import SideBar from "./LeftPanel/SideBar";
import i18next from "i18next"
import LanguageContext from "../../store/language-context"
import { useTranslation } from "react-i18next"
import cookies from "js-cookie";

const iconClicked = new Icon({
  iconUrl: SvgPointer[1],
  popupAnchor: [0, -20],
  iconSize: [32, 32]
})

let  SVG_Point = new Icon({
  iconUrl: Acropoli,
  popupAnchor: [0, -20],
  iconSize: [35, 47]
})
let  SVG_Point0 = new Icon({
    iconUrl: AcropoliA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })

let  SVG_Pointa1 = new Icon({
    iconUrl: AcropoliaA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa2 = new Icon({
    iconUrl: AcropoliaB,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa3 = new Icon({
    iconUrl: AcropoliaC,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa4 = new Icon({
    iconUrl: AcropoliaD,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa5 = new Icon({
    iconUrl: AcropoliaE,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa6 = new Icon({
    iconUrl: AcropoliaF,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa7 = new Icon({
    iconUrl: AcropoliaG,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa8 = new Icon({
    iconUrl: AcropoliaH,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa9 = new Icon({
    iconUrl: AcropoliaI,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa10 = new Icon({
    iconUrl: AcropoliaJ,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa11 = new Icon({
    iconUrl: AcropoliaK,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa12 = new Icon({
    iconUrl: AcropoliaL,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa13 = new Icon({
    iconUrl: AcropoliaM,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa14 = new Icon({
    iconUrl: AcropoliaN,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa15 = new Icon({
    iconUrl: AcropoliaA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa16 = new Icon({
    iconUrl: AcropoliaA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa17 = new Icon({
    iconUrl: AcropoliaA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa18 = new Icon({
    iconUrl: AcropoliaA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa19 = new Icon({
    iconUrl: AcropoliaA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Pointa20 = new Icon({
    iconUrl: AcropoliaA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })

  let  SVG_Point1 = new Icon({
    iconUrl: AcropoliA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point2 = new Icon({
    iconUrl: AcropoliB,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point3 = new Icon({
    iconUrl: AcropoliC,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point4 = new Icon({
    iconUrl: AcropoliD,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point5 = new Icon({
    iconUrl: AcropoliE,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point6 = new Icon({
    iconUrl: AcropoliF,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point7 = new Icon({
    iconUrl: AcropoliG,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point8 = new Icon({
    iconUrl: AcropoliH,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point9 = new Icon({
    iconUrl: AcropoliI,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point10 = new Icon({
    iconUrl: AcropoliJ,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point11 = new Icon({
    iconUrl: AcropoliK,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point12 = new Icon({
    iconUrl: AcropoliL,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point13 = new Icon({
    iconUrl: AcropoliM,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point14 = new Icon({
    iconUrl: AcropoliN,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point15 = new Icon({
    iconUrl: AcropoliA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point16 = new Icon({
    iconUrl: AcropoliA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point17 = new Icon({
    iconUrl: AcropoliA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point18 = new Icon({
    iconUrl: AcropoliA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point19 = new Icon({
    iconUrl: AcropoliA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
  let  SVG_Point20 = new Icon({
    iconUrl: AcropoliA,
    popupAnchor: [0, -20],
    iconSize: [35, 47]
  })
const MapRoutes = () => {

    const { t } = useTranslation();
    var currentLanguageCode = cookies.get("i18next") || "el";
    const languageCtx = useContext(LanguageContext);  const controller = new AbortController();
  const { signal } = controller;
  const { isLoading, Points } = usePoints()
  const location = useLocation()
  const mapRef = useRef(null)
  const [points, setPoints] = useState([]);
  const [cats, setCats] = useState([]);
  const [poi, setPoi] = useState([]);
  const RP =[];
  const RP1 =[];
  const RP2 =[];
  const icons=[SVG_Point1,SVG_Point2,SVG_Point3,SVG_Point4,SVG_Point5,SVG_Point6,SVG_Point7,SVG_Point8,SVG_Point9,SVG_Point10,SVG_Point11,SVG_Point12,SVG_Point13,SVG_Point14,SVG_Point15,SVG_Point16,SVG_Point17,SVG_Point18,SVG_Point19,SVG_Point20]
  const icons1=[SVG_Pointa1,SVG_Pointa2,SVG_Pointa3,SVG_Pointa4,SVG_Pointa5,SVG_Pointa6,SVG_Pointa7,SVG_Pointa8,SVG_Pointa9,SVG_Pointa10,SVG_Pointa11,SVG_Pointa12,SVG_Pointa13,SVG_Pointa14,SVG_Pointa15,SVG_Pointa16,SVG_Pointa17,SVG_Pointa18,SVG_Pointa19,SVG_Pointa20]
  const [ActiveRoute, setActiveRoute] = useState([]);
  const [ActiveCoords, setActiveCoords] = useState([]);
  const [ActivePois, setActivePois] = useState([]);
  const [OpenPoint, setOpenPoint] = useState(-1);
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  }
  const queryParameters = new URLSearchParams(window.location.search);
  const aroute = queryParameters.get("route");

  const geojson1 = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          properties: {"Stroke": "126, 0, 126 "},

          coordinates: [
            [
                24.47252354423891,
                35.37165569123725,
                0
            ],
            [
                24.47192755062723,
                35.37160677576968,
                0
            ],
            [
                24.47191863047517,
                35.3718496342814,
                0
            ],
            [
                24.47229125447888,
                35.37197504235431,
                0
            ],
            [
                24.47228183599,
                35.37204774195381,
                0
            ],
            [
                24.4721970187088,
                35.3720850140239,
                0
            ],
            [
                24.4721322745827,
                35.37218176134825,
                0
            ],
            [
                24.47206484285102,
                35.37220528086003,
                0
            ],
            [
                24.47196327623426,
                35.37231330285284,
                0
            ],
            [
                24.47192025110164,
                35.37236194401767,
                0
            ],
            [
                24.47193561377038,
                35.37241509826728,
                0
            ],
            [
                24.47200105923269,
                35.3724200608539,
                0
            ],
            [
                24.47204805586863,
                35.37247308201044,
                0
            ],
            [
                24.4720762878827,
                35.3725687197402,
                0
            ],
            [
                24.47211826590949,
                35.37266278472369,
                0
            ],
            [
                24.47208379292677,
                35.37278759227816,
                0
            ],
            [
                24.47205495994367,
                35.37283151628989,
                0
            ],
            [
                24.47202610579819,
                35.37285819603862,
                0
            ],
            [
                24.47194120120144,
                35.37293222156408,
                0
            ],
            [
                24.47191243143597,
                35.3729847283678,
                0
            ],
            [
                24.47188366163307,
                35.37303723516469,
                0
            ],
            [
                24.47178024860699,
                35.37307564674078,
                0
            ],
            [
                24.47170492398538,
                35.37310215370422,
                0
            ],
            [
                24.47163468855651,
                35.37307030711555,
                0
            ],
            [
                24.47158125540221,
                35.37301569015175,
                0
            ],
            [
                24.47156976322984,
                35.37296434084885,
                0
            ],
            [
                24.47149930189793,
                35.3729338447887,
                0
            ],
            [
                24.47145676981469,
                35.372890610498,
                0
            ],
            [
                24.47140414660301,
                35.37288540450882,
                0
            ],
            [
                24.4713421418472,
                35.37287664355029,
                0
            ],
            [
                24.4708251241338,
                35.37294881584324,
                0
            ],
            [
                24.47037491183465,
                35.37298996371759,
                0
            ],
            [
                24.47027903867868,
                35.37313931661992,
                0
            ],
            [
                24.47019317566836,
                35.373195476204,
                0
            ],
            [
                24.46996769851357,
                35.37308138096854,
                0
            ],
            [
                24.46979838131977,
                35.37303602705133,
                0
            ],
            [
                24.46969539356884,
                35.37294957710528,
                0
            ],
            [
                24.46940935787421,
                35.37275603230184,
                0
            ],
            [
                24.46936933255241,
                35.37250888894241,
                0
            ],
            [
                24.46940538270357,
                35.37232513071522,
                0
            ],
            [
                24.46950835679527,
                35.37241289316853,
                0
            ],
            [
                24.4696583803368,
                35.372493397983,
                0
            ],
            [
                24.46973167843799,
                35.37257415812736,
                0
            ],
            [
                24.46978965603739,
                35.37266458823745,
                0
            ],
            [
                24.46992766366002,
                35.37277544609677,
                0
            ],
            [
                24.47006129550247,
                35.37283899942955,
                0
            ],
            [
                24.47017124468465,
                35.37287107704459,
                0
            ],
            [
                24.47026215803745,
                35.37287120559878,
                0
            ],
            [
                24.47035726174698,
                35.37288221659905,
                0
            ],
            [
                24.47039444332656,
                35.37282944912406,
                0
            ],
            [
                24.47046842362231,
                35.37280492021579,
                0
            ],
            [
                24.47070835275958,
                35.37276416206333,
                0
            ],
            [
                24.4707984836753,
                35.37272912812379,
                0
            ],
            [
                24.47081332145562,
                35.37265901313281,
                0
            ],
            [
                24.47080514827559,
                35.3725785312798,
                0
            ],
            [
                24.47063994289221,
                35.37164520256759,
                0
            ]
        ]
        },
      },
    ],
  };

  const geojson2 = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: { "strokeColor": "red"},
        geometry: {
          type: "LineString",
          properties: {"Stroke": "126, 0, 126 "},

          coordinates: [
            [
                24.47437219474329,
                35.36730698444169,
                0
            ],
            [
                24.47468369618225,
                35.3682466493071,
                0
            ],
            [
                24.47471462873065,
                35.36847659478933,
                0
            ],
            [
                24.4742847461836,
                35.36851581210386,
                0
            ],
            [
                24.47408775563382,
                35.36880219922291,
                0
            ],
            [
                24.47448473331917,
                35.36902513363808,
                0
            ],
            [
                24.4747011715096,
                35.36912513107642,
                0
            ],
            [
                24.47474069739366,
                35.3691307394336,
                0
            ],
            [
                24.47480777845389,
                35.36914754546976,
                0
            ],
            [
                24.47476446585073,
                35.36924845651303,
                0
            ],
            [
                24.47438238616683,
                35.36925728576382,
                0
            ],
            [
                24.47419830688606,
                35.36925602388879,
                0
            ],
            [
                24.47403433616429,
                35.36929008068351,
                0
            ],
            [
                24.47363313495572,
                35.36934794854719,
                0
            ],
            [
                24.47362484542414,
                35.36938174622706,
                0
            ],
            [
                24.47337379335337,
                35.36942423425498,
                0
            ],
            [
                24.4730788382729,
                35.36940040076259,
                0
            ],
            [
                24.47276950390313,
                35.36935833731103,
                0
            ],
            [
                24.47272654018478,
                35.3695630448297,
                0
            ],
            [
                24.47280206629985,
                35.36965208677044,
                0
            ],
            [
                24.4732456612951,
                35.36981628378745,
                0
            ],
            [
                24.47358230362975,
                35.36991890087644,
                0
            ],
            [
                24.47405107710959,
                35.36995994957567,
                0
            ],
            [
                24.47448209701413,
                35.36997534233943,
                0
            ],
            [
                24.47461738034285,
                35.36996764586029,
                0
            ],
            [
                24.47466457192192,
                35.36989837851837,
                0
            ],
            [
                24.47481265244422,
                35.36988118067161,
                0
            ],
            [
                24.47574999112475,
                35.36996763977302,
                0
            ],
            [
                24.47663427844442,
                35.36984302663265,
                0
            ],
            [
                24.47674105029546,
                35.36984675051605,
                0
            ],
            [
                24.47697498520703,
                35.36969922676663,
                0
            ],
            [
                24.47761375770594,
                35.36964651616481,
                0
            ],
            [
                24.47745961125608,
                35.36968100033121,
                0
            ],
            [
                24.47730666194086,
                35.36973007404703,
                0
            ],
            [
                24.47723276485513,
                35.36983102547276,
                0
            ],
            [
                24.47718120834371,
                35.3699698339199,
                0
            ],
            [
                24.47708326507334,
                35.37021608655254,
                0
            ],
            [
                24.47691263459699,
                35.37020869427172,
                0
            ],
            [
                24.47624336369899,
                35.3702144447464,
                0
            ],
            [
                24.47585493743617,
                35.37007417284372,
                0
            ],
            [
                24.47580684653222,
                35.37008925517132,
                0
            ],
            [
                24.47582349024732,
                35.37059753938959,
                0
            ],
            [
                24.47584903478097,
                35.37071242927797,
                0
            ],
            [
                24.47513981425291,
                35.37066128937943,
                0
            ],
            [
                24.47436171395325,
                35.37073633963493,
                0
            ],
            [
                24.47417723950322,
                35.37073510380041,
                0
            ],
            [
                24.47396817758964,
                35.37119927044535,
                0
            ],
            [
                24.47289868346583,
                35.37150703643239,
                0
            ],
            [
                24.47228122097867,
                35.37163637994873,
                0
            ]
         ]
        },
      },
    ],
  };

  const geojson3 = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: { "strokeColor": "red"},
        geometry: {
          type: "LineString",
          properties: {"Stroke": "126, 0, 126 "},

          coordinates: [
            [
                24.47435031258252,
                35.36715674900577,
                0
            ],
            [
                24.47444451795957,
                35.36752607135041,
                0
            ],
            [
                24.47457998602299,
                35.3679203044609,
                0
            ],
            [
                24.47458854778159,
                35.36797038316641,
                0
            ],
            [
                24.47470263479509,
                35.36833815099322,
                0
            ],
            [
                24.47471509046215,
                35.36847765525866,
                0
            ],
            [
                24.47421147634278,
                35.36850992615057,
                0
            ],
            [
                24.47420739358037,
                35.36926722356334,
                0
            ],
            [
                24.47392836547851,
                35.3693023862544,
                0
            ],
            [
                24.47366074351236,
                35.36936167374212,
                0
            ],
            [
                24.47356865893358,
                35.3694060694475,
                0
            ],
            [
                24.47335259089606,
                35.36942455247053,
                0
            ],
            [
                24.47314992034817,
                35.36942040613297,
                0
            ],
            [
                24.47277141922209,
                35.36935185873721,
                0
            ],
            [
                24.47272217838972,
                35.36953827218377,
                0
            ],
            [
                24.47214400839564,
                35.36944134604052,
                0
            ],
            [
                24.47178775380735,
                35.36935192059374,
                0
            ],
            [
                24.47136522973596,
                35.36930611832273,
                0
            ],
            [
                24.47037599080485,
                35.369321873553,
                0
            ],
            [
                24.46997009831227,
                35.36942342657174,
                0
            ],
            [
                24.47006361596097,
                35.3698921891027,
                0
            ],
            [
                24.47030319500164,
                35.36993425012751,
                0
            ],
            [
                24.47031167470638,
                35.37034536202942,
                0
            ],
            [
                24.47108559517387,
                35.37045174243516,
                0
            ],
            [
                24.47149326301867,
                35.37042176870735,
                0
            ],
            [
                24.47186582298955,
                35.37044675868629,
                0
            ],
            [
                24.47195079805577,
                35.37005408517163,
                0
            ],
            [
                24.47261964428301,
                35.3701342866382,
                0
            ],
            [
                24.47265278236815,
                35.36998709922523,
                0
            ],
            [
                24.47269499550915,
                35.36983198319111,
                0
            ],
            [
                24.47312046922289,
                35.36995208462745,
                0
            ],
            [
                24.47353989408937,
                35.37004750653573,
                0
            ],
            [
                24.4739862047234,
                35.37011959745771,
                0
            ],
            [
                24.47444629924289,
                35.37012721196459,
                0
            ],
            [
                24.47446444799509,
                35.36997420816775,
                0
            ],
            [
                24.47463996739248,
                35.36995649953457,
                0
            ],
            [
                24.47469844590785,
                35.36987259430841,
                0
            ],
            [
                24.47577971208709,
                35.36995568278525,
                0
            ],
            [
                24.47579809244496,
                35.37006899360323,
                0
            ],
            [
                24.47590429550952,
                35.37007565937975,
                0
            ],
            [
                24.47613712493001,
                35.37019563611744,
                0
            ],
            [
                24.47636587014234,
                35.37021229996597,
                0
            ],
            [
                24.47695831073644,
                35.3702090369162,
                0
            ],
            [
                24.47685700654608,
                35.37000876416737,
                0
            ],
            [
                24.47674011793202,
                35.36983710254791,
                0
            ],
            [
                24.47657647476608,
                35.36972584036766,
                0
            ],
            [
                24.47654920147371,
                35.36950649521272,
                0
            ],
            [
                24.47656089039944,
                35.3693380130007,
                0
            ],
            [
                24.47663881533898,
                35.36901694318268,
                0
            ],
            [
                24.47669336233199,
                35.36881985096318,
                0
            ],
            [
                24.4768608153475,
                35.36863424684068,
                0
            ],
            [
                24.47638720672472,
                35.36842463964143,
                0
            ],
            [
                24.47657944703709,
                35.3681779604521,
                0
            ],
            [
                24.47668051231489,
                35.36805071686039,
                0
            ],
            [
                24.47680712150862,
                35.3679114764993,
                0
            ],
            [
                24.4770640238816,
                35.36774464730405,
                0
            ],
            [
                24.47746073340929,
                35.36749511653326,
                0
            ],
            [
                24.47747820956439,
                35.36746517264644,
                0
            ],
            [
                24.47780616169892,
                35.36725577816784,
                0
            ],
            [
                24.47783267975799,
                35.36720967436621,
                0
            ],
            [
                24.47817950275397,
                35.36706380835858,
                0
            ]
        ]
        },
      },
    ],
  };

  const geojson4 = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: { "strokeColor": "red"},
        geometry: {
          type: "LineString",
          properties: {"Stroke": "126, 0, 126 "},

          coordinates:[
            [
                24.47778850283776,
                35.36407933889218,
                0
            ],
            [
                24.47845620781711,
                35.36429832403235,
                0
            ],
            [
                24.47811416640522,
                35.3647417784175,
                0
            ],
            [
                24.4780887168395,
                35.36529278935541,
                0
            ],
            [
                24.47749060702709,
                35.36610063054447,
                0
            ],
            [
                24.47861989861723,
                35.36580398125851,
                0
            ],
            [
                24.47877808046994,
                35.3661998388293,
                0
            ],
            [
                24.47906837902698,
                35.36660471974053,
                0
            ],
            [
                24.47920915075269,
                35.36664298629854,
                0
            ],
            [
                24.47782926954264,
                35.36722475884397,
                0
            ],
            [
                24.4767727304554,
                35.36798152534855,
                0
            ],
            [
                24.47620564640404,
                35.36871202291728,
                0
            ],
            [
                24.47581936093067,
                35.36952294254306,
                0
            ],
            [
                24.47583579350793,
                35.36993845779659,
                0
            ],
            [
                24.4747509165758,
                35.36986472678504,
                0
            ],
            [
                24.47468811975772,
                35.36989752956512,
                0
            ],
            [
                24.47466324388827,
                35.36997056130853,
                0
            ],
            [
                24.47358850515568,
                35.36991376902746,
                0
            ],
            [
                24.4727406247682,
                35.3696380692348,
                0
            ],
            [
                24.4726933969082,
                35.36990210067184,
                0
            ],
            [
                24.47264350826303,
                35.37013150112635,
                0
            ],
            [
                24.47253039842239,
                35.37060889481022,
                0
            ],
            [
                24.47213703462276,
                35.37048378859904,
                0
            ],
            [
                24.4715589226573,
                35.3704092323803,
                0
            ],
            [
                24.47119464924315,
                35.37043801155743,
                0
            ],
            [
                24.47099861511336,
                35.37044609489751,
                0
            ],
            [
                24.47030792216258,
                35.37032465099522,
                0
            ],
            [
                24.47033640095785,
                35.37009063333438,
                0
            ],
            [
                24.47031569738815,
                35.36987155987633,
                0
            ],
            [
                24.47027094230403,
                35.36955650043658,
                0
            ],
            [
                24.47007669836839,
                35.36937079129841,
                0
            ],
            [
                24.47073830406658,
                35.36931008478573,
                0
            ],
            [
                24.47170058537673,
                35.3693250112781,
                0
            ],
            [
                24.4719638962976,
                35.36939000321262,
                0
            ],
            [
                24.47235472008849,
                35.36949780300782,
                0
            ],
            [
                24.47274265163472,
                35.36956078953534,
                0
            ],
            [
                24.47277041384417,
                35.36935371259557,
                0
            ],
            [
                24.47317652107379,
                35.36942527342438,
                0
            ],
            [
                24.47337402502809,
                35.36942606079057,
                0
            ],
            [
                24.47357576585641,
                35.36939933230027,
                0
            ],
            [
                24.4736912927012,
                35.36934728091835,
                0
            ],
            [
                24.47399649402483,
                35.36929706061196,
                0
            ],
            [
                24.4742701501965,
                35.36925648494625,
                0
            ],
            [
                24.47470418735487,
                35.36925027953761,
                0
            ],
            [
                24.47480074683082,
                35.36918978724614,
                0
            ],
            [
                24.47485247513736,
                35.36900690332646,
                0
            ],
            [
                24.47481454138268,
                35.36892108853811,
                0
            ],
            [
                24.47477488342597,
                35.36880995133066,
                0
            ],
            [
                24.4747378371009,
                35.36846936652856,
                0
            ],
            [
                24.47417000700072,
                35.36851039791477,
                0
            ],
            [
                24.47388816549431,
                35.36839765336702,
                0
            ],
            [
                24.47372824915316,
                35.36838823745268,
                0
            ],
            [
                24.47371835793707,
                35.36830215153295,
                0
            ],
            [
                24.47371176392843,
                35.36821203041473,
                0
            ],
            [
                24.47369527875107,
                35.3680694508264,
                0
            ],
            [
                24.47387662646148,
                35.36804927501289,
                0
            ],
            [
                24.47413051296625,
                35.36801430300157,
                0
            ],
            [
                24.47459464256665,
                35.36797715218582,
                0
            ],
            [
                24.47444345433763,
                35.36755110516423,
                0
            ],
            [
                24.4744095591167,
                35.36740110739561,
                0
            ],
            [
                24.47433254262504,
                35.36715515493468,
                0
            ],
            [
                24.47297044340069,
                35.36750446839179,
                0
            ],
            [
                24.47165553576302,
                35.36785037971718,
                0
            ],
            [
                24.47144119149636,
                35.36801328170653,
                0
            ],
            [
                24.47124578449198,
                35.36818398290414,
                0
            ],
            [
                24.47118268972985,
                35.36830300638398,
                0
            ],
            [
                24.47115266044111,
                35.36842821481879,
                0
            ],
            [
                24.47053999402307,
                35.36838593846295,
                0
            ],
            [
                24.47050026596743,
                35.36855574428024,
                0
            ],
            [
                24.47003991114621,
                35.36855298035547,
                0
            ]
        ]
        },
      },
    ],
  };

    // Function to collect data
    const getRoute = async () => {
        const url = `https://rethymno.mitos.cityguideplatform.com/api/routes?lang=${currentLanguageCode}`
        const response = await fetch(url, 
        {signal, 
        headers: {
         'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
         'Content-Type': 'application/json',
         'Accept': 'application/json'}
         })
         if (!response.ok) {
          throw new Error('Data could not be fetched!')
        } 
      else {
          return response.json()
      }
    };

    useEffect(() => {
        setOpenPoint(-1)
      getRoute()
        .then((res) => {
            console.log(res.success);
          setPoints(res.success.pois)
          
        })
        .catch((e) => {
          console.log(e.message)
        
        })        
    },[ActiveRoute])
    
  let name = t('Κανονικό')

  function SetViewOnClick(animateRef) {
    const map = useMapEvent('click', (e) => {
      map.setView(e.latlng, map.getZoom(), {
        animate: animateRef.current || false,
      })
    })

    return null
  }
  
  const MapContainerHead = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const aroute = queryParameters.get("route");

    return (
      
      <>
        <MapContainer
          key={JSON.stringify([35.3675697,24.4776761])} // To center every time
          center={[35.3675697,24.4776761]}
          zoom={16}
          tap={false}
          style={{ height: '100vh', width: '100%', zIndex: '0' }}
          ref={mapRef}
          zoomControl={false}
        >
          <div className={classes.dummy}>
         

                   <>
                       {ActiveRoute=='3_6' &&
            <GeoJSON data={geojson1} color="#d55e2d" stroke-width="10"/>
          }
          {ActiveRoute=='3_5' &&
            <GeoJSON data={geojson2}  color="#d55e2d"/>
          }
          {ActiveRoute=='3_7' &&
            <GeoJSON data={geojson3}  color="#d55e2d"/>
          }
          {ActiveRoute=='3_8' &&
            <GeoJSON data={geojson3}  color="#d55e2d"/>
          }
          {ActiveRoute=='3_9' &&
            <GeoJSON data={geojson4}  color="#d55e2d"/>
          }
          {ActivePois.map((p) => (
              RP.push({id: p["id"], name: p["name"], longitude: p["longitude"],latitude: p["latitude"]})
          ))}
          
    
          {RP.map((p,index) => ( 
      
            <Marker
            key={p.id}
            //icon={ icons[index]}
            icon={ OpenPoint>=0?OpenPoint==index?icons1[index]:icons[index]:icons[index]}
            position={[parseFloat(p["latitude"]), parseFloat(p["longitude"])]}
            
            zIndex={OpenPoint>=0?OpenPoint==index?100:10:10}
            eventHandlers={{
              mouseover: (e) => e.target.openPopup(),
              mouseout: (e) => e.target.closePopup(),
              click: (e) => window.location.href = `/rethymno/${p.id}`
            }}
          >                                                                                  
            <Popup>
              <div className={classes.gpsInfoWindow}>
              <div className={classes.title}>{p.name}</div>
              </div>
            </Popup>
          </Marker>  
        
          ))}
          {RP1.map((p,index) => (            
            <Marker
            key={p.id}
           // icon={ icons[index]}
           icon={ OpenPoint>=0?OpenPoint==index?icons1[index]:icons[index]:icons[index]}
            position={[parseFloat(p["latitude"]), parseFloat(p["longitude"])]}
           
            zIndex={OpenPoint>=0?OpenPoint==index?100:10:10}
            eventHandlers={{
              mouseover: (e) => e.target.openPopup(),
              mouseout: (e) => e.target.closePopup(),
              click: (e) => window.location.href = `/rethymno/${p.id}`
            }}
          >                                                                                  
            <Popup>
              <div className={classes.gpsInfoWindow}>
              <div className={classes.title}>{index} {p.name}</div>
              </div>
            </Popup>
          </Marker>  
          
          ))}
                   </>
               )
           
      
          </div>
          <ZoomControl position='topright' />
          <SetViewOnClick animateRef={mapRef.current} />
          
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name={name}>
             
              <LayerGroup >
                <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
              </LayerGroup>
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={t('Δορυφόρος')}>
              <LayerGroup >
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" />
              </LayerGroup>
            </LayersControl.BaseLayer>
          </LayersControl>
        </MapContainer>
      </>
    )
  }

  return (
    <>

      <HelmetComponent title={t('Χάρτης Σημείων')} descrition={t('Χάρτης Σημείων')} />

      <div style={{ width: '100%', height: '100%' }}>
        <Row >
          <Col xs={24} lg={24} xl={24} >
            <MapContainerHead />

            <SideBar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} setActiveRoute={setActiveRoute} setActiveCoords={setActiveCoords} setActivePois={setActivePois} setOpenPoint={setOpenPoint}/>
            
          </Col>
        </Row>
      </div>
    </>
  )
}

export default MapRoutes