import React, { useState, useEffect, useContext, useRef } from "react"
import { Row, Col, Card, Button } from 'antd'
import classes from './styles.module.css'
import { usePoint } from 'hooks/useMap'
import { useParams, Link } from 'react-router-dom'
import "antd/dist/antd.css";
import Spinner from 'common/Spinner'
import HelmetMetaData from 'components/Helmet'
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { copyToClipboard, getShareUrl, SocialPlatforms } from "@phntms/react-share";

import cookies from "js-cookie";
import i18next from "i18next"
import LanguageContext from "../../store/language-context"
import { useTranslation } from "react-i18next"

export type NewsPost = {
    id: string;
    title: string;
    // image: image;
    created: string;
    url: string;
}

export type PostTypes = NewsPost[];


const SingleEvent: React.FC = () => {


    var currentLanguageCode = cookies.get("i18next") || "el";
    async function getUrFromService(): Promise<string> {
        // The real implementation would make a network call here.
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return event?.image.url!;
    }

    const shareButton = useRef<HTMLButtonElement>(null);
    const [url, setUrl] = useState<string>("none"); // Unfortunately, we have to have a dummy string here, or FacebookShareButton will blow up.

    // Provide an onClick handler that asyncronously fetches the url and sets it in the state.
    const onClick = async () => {
        // Be sure to check for the "none" state, so we don't trigger an infinite loop.
        if (url === "none") {
            const newUrl = await getUrFromService();
            setUrl(newUrl);
        }
    };

    const { t } = useTranslation()
    const controller = new AbortController();
    const { signal } = controller;
    var currentLanguageCode = cookies.get("i18next") || "el";
    const languageCtx = useContext(LanguageContext);

    const params = useParams();
    const [event, setEvent] = useState<{ category: { id: string, title: string }, client: { id: string, title: "string" }, venues: [{ title: string }], description: string, files: [], gallery: [], id: string, image: { alternative_text: string, url: string }, organizers: [{}], tickets: [{ price: number, description: string }], title: string }>();
    const [pois, setPois] = useState([]);
    // Function to collect data
    const pathname = window.location.pathname;
    const activecategory = pathname.split("/").pop();

    // const getEvent = async () => {
    //     //alert(i18next.language);
    //     const furl = `https://rethymno-events.dotsoft.gr/api/el/events/${params.id}`;
    //     const response = await fetch(furl,
    //         {
    //             signal,
    //             headers: {
    //                 'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json'
    //             }
    //         })
    //     if (!response.ok) {
    //         throw new Error('Data could not be fetched!')
    //     }
    //     else {
    //         return response.json()
    //     }
    // };



    const getEvent = async () => {
        // const url = `https://events.explorechania.gr/api/${currentLanguageCode}/events/${params.id}`
        const url = `https://rethymno-events.dotsoft.gr/api/${currentLanguageCode}/events/${params.id}`
        const response = await fetch(url,
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
        if (!response.ok) {
            throw new Error('Data could not be fetched!')
        }
        else {

            return response.json()
        }

    };


    useEffect(() => {
        if (url !== "none") {
            shareButton.current?.click();
            setUrl("none");
        }

        getEvent()
            .then((res) => {
                setEvent(res[0])
                console.log(res);

            })
            .catch((e) => {
                console.log(e.message)
            })




    }, [i18next.language, url, shareButton])


    return (
        <>
            <HelmetMetaData title={event?.title}
                image={"https://rethymno.dotsoft.gr/img/logo.jpg"}
            ></HelmetMetaData>
            <div className={classes.container}>
                <Row justify="center" >
                    <div>
                        <h1 style={{ textAlign: "center" }}>{event?.title}</h1>

                        <br />
                    </div>
                </Row>
                <Row justify="center" >
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <div className={classes.calendarCard__imgBorder2}>
                            <div className={classes.container}>
                                <img src={event?.image.url} alt={event?.image.alternative_text} />
                                <div style={{ padding: 15, marginTop: 50 }}>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={10}>
                                            <span>{t('Κοινοποίηση Εκδήλωσης')}:</span>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={10}>
                                            <FacebookShareButton
                                                ref={shareButton}
                                                // Disable calling the dialog if we don't have a url yet.
                                                openShareDialogOnClick={url !== "none"}
                                                url={`https://rethymno-events.dotsoft.gr/events/${event?.id}`}
                                                onClick={onClick}
                                            ><FacebookIcon />
                                            </FacebookShareButton>
                                        </Col>
                                    </Row>

                                    {/* <a href={getShareUrl(SocialPlatforms.Facebook, { url: "http://localhost:3000/events/519" })}>
                                    Share to Facebook
                                </a> */}

                                    {/* <div style={{ cursor: 'pointer' }} onClick={() => copyToClipboard("https://phantom.land")}>Copy</div> */}
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={10}>
                        <div className={classes.container}>
                            <h4 style={{ textAlign: "center", display: "inline-block" }}>&nbsp;<span> {t('Tοποθεσία')}:&nbsp;</span>{event?.venues[0].title} </h4><br />
                            <h4 style={{ textAlign: "center", display: "inline-block" }}>&nbsp;<span> {t('Εισιτήριο')}:&nbsp;</span>{event?.tickets[0].description} {event?.tickets[0].price != null ? `${event?.tickets[0].price} €` : ""} </h4>
                        </div>
                        <div className={classes.container} dangerouslySetInnerHTML={{ __html: event?.description! }} ></div>
                    </Col>
                </Row>


            </div>
        </>
    )
}

export default SingleEvent