import React, { useState } from 'react'
import classes from './styles.module.css'
import { Button, Checkbox, Form } from 'antd'
import { useTranslation } from "react-i18next";
import { RightOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useNavigate, Link } from "react-router-dom";
import ReactPlayer from 'react-player';
import { useParams } from 'react-router';
import { Image } from 'react-photo-album';
var youtubeThumbnail = require('youtube-thumbnail');

interface Video {
  caption: string;
  url: string;
  thumbnail: Image;
}
export default function Modal(data: Video) {
  const navigate = useNavigate();
  const { t } = useTranslation()

  const [modal, setModal] = useState(false);
  const [criminalRecords, setcriminalRecords] = useState<boolean>(false);
  const [confidStatement, setConfidStatement] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<boolean>(false);




  const toggleModal = () => {
    console.log(data.caption);

    setModal(!modal)
  }

  const onChangeCriminalRecords = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setcriminalRecords(true);
    }
    else
      setcriminalRecords(false);
    // console.log(`checked = ${e.target.checked}`);

  };
  const onChangeConfidStatement = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setConfidStatement(true);
    }
    else
      setConfidStatement(false);
    // console.log(`checked = ${e.target.checked}`);

  };
  const onChangeAgreement = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setAgreement(true);
    }
    else
      setAgreement(false);
    // console.log(`checked = ${e.target.checked}`);

  };

  let Idnum = 1;

  // React.useEffect(() => {
  // }, []);



  if (modal) {
    document.body.classList.add('activeModal')
  } else {
    document.body.classList.add('activeModal')
  }

  return (
    <>
      {
        <div
          className={classes.DeclareBtn}
          onClick={toggleModal}
        >
          <div className={classes.carouselslider}>


            <div className={classes.carouselimg}></div>




            <div className={classes.carouseldesc}>
              <h3>{data["caption"]}</h3>
              <div className={classes.carouselmore}>

              </div>
            </div>
          </div>
        </div>

      }

      {/* <iframe title='Ψηφιακή Περιήγηση' width='853' height='480' src='https://my.matterport.com/show/?m=YiHGNMDVn8W' 
      frameBorder='0' allowFullScreen allow='xr-spatial-tracking'></iframe>
      <button style={{borderRadius:25, border: 'black', width: 42, height: 42, backgroundColor: 'black', fontSize: '1rem', color: 'white', cursor: "pointer", marginBottom: '525px', position: 'absolute', lineHeight: 1}} onClick={() => setShown(!shown)}>X</button> */}
      {
        modal && (
          <div className={classes.modal}>
            <div className={classes.overlay}></div>
            <div className={classes.modalContent}>

              <button className={classes.closeModal} onClick={() => setModal(!modal)}>x</button>
              <ReactPlayer url={data.url} controls={true} />
            </div>
          </div>
        )
      }
    </>
  )

};