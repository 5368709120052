import React,{useState,useEffect,useContext} from "react";
import classes from './sidebar.module.css'
import i18next from "i18next"
import LanguageContext from "../../../store/language-context"
import { useTranslation } from "react-i18next"
import {  Select, Radio, Button, Drawer, Space, Divider,Checkbox,Collapse } from 'antd'
import SideBarList from "./SideBarList"
import cookies from "js-cookie";

const SideBar = props => {
  const sidebarClass = props.isOpen ? "sidebar_open" : "sidebar";

  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation();
  var currentLanguageCode = cookies.get("i18next") || "el";
  const languageCtx = useContext(LanguageContext);
  const [checkedList, setCheckedList] = useState([]);
  const [sidebarListOpen, setSideBarListOpen] = useState(false);
  const [myroute, setmyroute] = useState(false);
  const showPoints = (event) => {
    setSideBarListOpen(!sidebarListOpen);
  };

  const handleSelect = (routeid,coords,pois) => {
     //props.setOpenPoint(-1);
      props.setActiveRoute(routeid);
      props.setActiveCoords(coords);
      props.setActivePois(pois);
  };

  const [routes, setRoutes] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search);
  const aroute = queryParameters.get("route");

  // Function to collect data
  const getRoutes = async () => {
    const url = `https://rethymno.mitos.cityguideplatform.com/api/routes?lang=${currentLanguageCode}`
    const response = await fetch(url, 
      {signal, 
      headers: {
       'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
       'Content-Type': 'application/json',
       'Accept': 'application/json'}
       })
       if (!response.ok) {
        throw new Error('Data could not be fetched!')
      } 
    else {
        return response.json()
    }
    
  };

  useEffect(() => {
   
    getRoutes()
      .then((res) => {
        setRoutes(res.success)
     
      })
      
      .catch((e) => {
        console.log(e.message)
      })
  },[props.setActiveRoute,props.OpenPoint])
  const { Panel } = Collapse;
  const panelStyle = {
    marginBottom: 24,
    background: 'red',
    borderRadius: '2px',
    border: 'none',
  };
  return (
    <div className={props.isOpen ? classes.sidebar_open : classes.sidebar}>
     
      <h2 style={{borderBottom: '0.5px solid #FFF'}}>Δείτε τις προτεινόμενες διαδρομές</h2>
      <div className={classes.inner}>
      <div className={classes.inner1}>
      <br/>
    
      {aroute!='' && myroute==false &&
routes.map(route => 
  route['id'] == aroute &&
  handleSelect(route['id'],route['route_coordinates'],route['pois'])
)
      }
      {routes.length && 

     
      <div className={classes.categoriesinner} >
         
    <Collapse accordion
    expandIcon={""} defaultActiveKey={aroute}>
      {routes.map(route => 
  
        <Panel header={route['name']} key={route['id']} className={classes.categoriesrect} showArrow='False' onClick={() => {handleSelect(route['id'],route['route_coordinates'],route['pois'])}}> 
        <h4> <a href={"route/"+route['id']}>{t('Δες τα σημεία σε λίστα')} </a></h4>
            <ol type="1">
            {route['pois'].map((p,index) => 
                <li><div className={classes.categoriesd}  onClick={() => props.setOpenPoint(index)}>{p.name}</div></li>
            )}
            </ol>
        </Panel>
      )}
     </Collapse>
     </div>
    }
      </div>
     </div>
    </div>
   
  );
};
export default SideBar;