import React, { useState, useEffect, useContext } from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'
import { usePoint } from 'hooks/useMap'
import { useParams, Link } from 'react-router-dom'

import cookies from "js-cookie";
import i18next from "i18next"
import LanguageContext from "../../store/language-context"
import { useTranslation } from "react-i18next"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'

const RoutesMap: React.FC = () => {
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation()

  var currentLanguageCode = cookies.get("i18next") || "el";
  const languageCtx = useContext(LanguageContext);

  const { id } = useParams()
  const [routes, setRoutes] = useState([]);
  // Function to collect data
  const getCategories = async () => {
    const url = `https://rethymno.mitos.cityguideplatform.com/api/routes?lang=${currentLanguageCode}`
    const response = await fetch(url,
      {
        signal,
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    if (!response.ok) {
      throw new Error('Data could not be fetched!')
    }
    else {
      console.log(response);

      return response.json()

    }

  };

  useEffect(() => {

    getCategories()
      .then((res) => {
        setRoutes(res.success)
      })

      .catch((e) => {
        console.log(e.message)
      })
  }, [i18next.language])

  const itemsPerPage = 20;
  const [hasMore, setHasMore] = useState(true);
  const [records, setrecords] = useState(itemsPerPage);
  const loadMore = () => {
    if (records === routes.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage);
      }, 2000);
    }
  };

  const myStyle = {
    backgroundImage: `url('img/rethymnoRivaldiFountain.png')`,
    height: '530px',
    fontSize: '50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      {/* <HelmetComponent title='lll' descrition={ `${Point.title} ${t("στην")} ${Point.subject}` } image={Point.thumbnail} /> */}
      <Row>
        <div className={classes.top_banner}>
          <Col xs={24} sm={12} lg={12}>
            <div className={classes.top_banner_img} style={myStyle}>

            </div>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <div className={classes.top_banner_txt}>
              <p className={classes.top_banner_txt1}>
                <h2>{t("Γνώρισε τις προτεινόμενες διαδρομές του Ρεθύμνου")}</h2>
                <h2><a href="/routes_map" className={classes.btn}>{t("Δες τον χάρτη")}</a></h2>
              </p>

            </div>
          </Col>

        </div>
      </Row>
      <Row justify="end">
        <Col xs={24} sm={24} lg={24}>
          <div className={classes.divider}>
            <div className={classes.dividerBlock}></div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className={classes.top_cat}>
          <div className="app11">
            {routes.map(route =>
              <Link to={`/route/${route['id']}`} state={{ route: route['name'] }}>
                <div className={classes.categoriesimg}>
                  <img src={route['pois'][0]['main_image']} />
                  <span><b>{route['name']}</b></span>
                </div>
              </Link>
            )}
          </div>
          <br />
          <br />
        </div>
      </Row>


    </>
  )
}

export default RoutesMap