import React, { useState, useRef, useEffect } from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'
import { usePoints } from 'hooks/useMap'
import { PointsProps } from "services/props"
import { useLocation } from "react-router-dom";
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup, useMapEvent } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import SvgPointer from "../../images/svg_pointers/index"
import Acropoli from "../../images/svg_pointers/Acropoli.svg"
import Oikismos from "../../images/svg_pointers/Oikismos.svg"
import Ypaifro from "../../images/svg_pointers/Ypaifro.svg"
import utils from '../../utils'
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'
import DrawerComponent from './Drawer/Drawer'
import SideBar from "./LeftPanel/SideBar";
import { useTranslation } from "react-i18next"

const mpoints: any = []

const iconClicked = new Icon({
  iconUrl: SvgPointer[1],
  popupAnchor: [0, -20],
  iconSize: [32, 32]
})

let SVG_Point = new Icon({
  iconUrl: Acropoli,
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point2 = new Icon({
  iconUrl: 'img/categories/Τζαμιά.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point1 = new Icon({
  iconUrl: 'img/categories/Τζαμιά.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point3 = new Icon({
  iconUrl: 'img/categories/Ναοί - Μονές.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point4 = new Icon({
  iconUrl: 'img/categories/Πρόσωπα.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point5 = new Icon({
  iconUrl: 'img/categories/Κρήνες.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point6 = new Icon({
  iconUrl: 'img/categories/Κάστρο Φορτέτζα.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point7 = new Icon({
  iconUrl: 'img/categories/Μνημεία.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point8 = new Icon({
  iconUrl: 'img/categories/Παραδοσιακά επαγγέλματα.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point9 = new Icon({
  iconUrl: 'img/categories/Οικίες.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point10 = new Icon({
  iconUrl: 'img/categories/Κτήρια με επιμέρους αρχιτεκτονικά χαρακτηριστικά.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point11 = new Icon({
  iconUrl: 'img/categories/Μουσεία.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point12 = new Icon({
  iconUrl: 'img/categories/Οδοί - πλατείες.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point13 = new Icon({
  iconUrl: 'img/categories/Ιδιαίτερα Αρχιτεκτονικά Χαρακτηριστικά Κτηρίων.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point14 = new Icon({
  iconUrl: 'img/categories/Οροφογραφίες.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point15 = new Icon({
  iconUrl: 'img/categories/Θυρώματα.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point16 = new Icon({
  iconUrl: 'img/categories/Κιόσκια - Σαχνισιά.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point17 = new Icon({
  iconUrl: 'img/categories/Φωτογραφικό Αρχείο Giuseppe Gerola.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point18 = new Icon({
  iconUrl: 'img/categories/Ιστορία του Ρεθύμνου μέσα απο παλιές φωτογραφίες.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point19 = new Icon({
  iconUrl: 'img/categories/Συλλογή Παλαιοντολογικού.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point20 = new Icon({
  iconUrl: 'img/categories/Συλλογή Λαογραφικού.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point21 = new Icon({
  iconUrl: 'img/categories/Συλλογή Αρχαιολογικού.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
let SVG_Point22 = new Icon({
  iconUrl: 'img/categories/Ιστορικοί Χάρτες.png',
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})
const Map: React.FC = () => {

  const { t } = useTranslation()
  const controller = new AbortController();
  const { signal } = controller;

  const { isLoading, Points } = usePoints()

  const location = useLocation()

  const mapRef = useRef<any>(null)
  const [points, setPoints] = useState([]);
  const [cats, setCats] = useState([]);
  const [radioValue, setRadioValue] = useState([]);

  const [sidebarOpen, setSideBarOpen] = useState(false);
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  }

  // Function to collect data
  const getPoints = async () => {
    const furl = `https://rethymno.mitos.cityguideplatform.com/api/pois?lang=el`
    const response = await fetch(furl,
      {
        signal,
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    if (!response.ok) {
      throw new Error('Data could not be fetched!')
    }
    else {
      return response.json()
    }
  };
  useEffect(() => {
    console.log('1223')
    console.log(POINTS)
    console.log('wd')
    getPoints()
      .then((res) => {

        setPoints(res.success)
        setCats(res.success.category_path_ids)

      })
      .catch((e) => {
        console.log(e.message)
      })


  }, [setRadioValue])

  const POINTS: any[] = []

  let name = t('Κανονικό')

  const MarkersPois = () => {
    return <>

      {radioValue.length == 0 &&
        <div className={classes.dummy}>
          {points.map((p) => (
            POINTS.push({ id: p["id"], title: p["coordinates"][0]["name"], address: p["coordinates"][0]["address"], type: p["category_path_ids"], longitude: p["coordinates"][0]["longitude"], lantitude: p["coordinates"][0]["latitude"] })
          ))}

        </div>
      }
      {radioValue.length != 0 &&
        <div>
          {radioValue.map((cat) => (
            <div className={classes.dummy}>
              {points.map((p) => (
                p["category_path_ids"][0] == cat &&
                POINTS.push({ id: p["id"], title: p["coordinates"][0]["name"], address: p["coordinates"][0]["address"], type: p["category_path_ids"][0], longitude: p["coordinates"][0]["longitude"], lantitude: p["coordinates"][0]["latitude"] })
              ))}

              {points.map((p) => (
                p["category_path_ids"][1] == cat &&
                POINTS.push({ id: p["id"], title: p["coordinates"][0]["name"], address: p["coordinates"][0]["address"], type: p["category_path_ids"][1], longitude: p["coordinates"][0]["longitude"], lantitude: p["coordinates"][0]["latitude"] })
              ))}
            </div>
          ))}
        </div>
      }

      {
        POINTS.map((marker: any) => {

          if (marker.type == 2) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point2}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }

          else if (marker.type == 3) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point3}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }
          else if (marker.type == 3) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point3}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>
            )
          }
          else if (marker.type == 4) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point4}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>

            )
          }
          else if (marker.type == 5) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point5}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }

          else if (marker.type == 6) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point6}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }
          else if (marker.type == 7) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point7}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }
          else if (marker.type == 8) {

            return (
              <Marker
                key={marker.id}
                icon={SVG_Point8}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>
            )
          }

          else if (marker.type == 9) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point9}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }
          else if (marker.type == 10) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point10}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }
          else if (marker.type == 11) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point11}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }
          else if (marker.type == 12) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point12}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>


            )
          }

          else if (marker.type == 13) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point13}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >
                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>
            )
          }
          else if (marker.type == 14) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point14}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>
            )
          }

          else if (marker.type == 15) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point15}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>
            )
          }
          else if (marker.type == 16) {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point16}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>
            )
          }
          else if (marker.type == 17) {

            return (

              <div></div>

            )
          }
          else if (marker.type == 18) {

            return (
              <div></div>

            )
          }
          else if (marker.type == 19) {

            return (
              <div></div>
            )
          }
          else if (marker.type == 20) {

            return (

              <div></div>

            )
          }
          else if (marker.type == 21) {

            return (
              <div></div>

            )
          }
          else if (marker.type == 22) {

            return (

              <div></div>


            )
          }
          else {

            return (

              <Marker
                key={marker.id}
                icon={SVG_Point1}

                position={[parseFloat(marker.lantitude), parseFloat(marker.longitude)]}
                eventHandlers={{
                  // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                  click: () => window.open(`../rethymno/${marker.id}`, "_self"),
                  mouseover: (e) => e.target.openPopup(),
                  mouseout: (e) => e.target.closePopup()
                }}
              >

                <Popup >
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.title}>{marker.title}</div>
                    <div className={classes.address}>{marker.address} </div>
                  </div>
                </Popup>
              </Marker>

            )
          }

        })
      }

    </>
  }


  function SetViewOnClick(animateRef: any) {
    const map = useMapEvent('click', (e: any) => {
      map.setView(e.latlng, map.getZoom(), {
        animate: animateRef.current || false,
      })
    })

    return null
  }

  const MapContainerHead = () => {

    return (
      <>
        <MapContainer
          key={JSON.stringify([35.3675697, 24.4776761])} // To center every time
          center={[35.3675697, 24.4776761]}
          zoom={16}
          tap={false}
          style={{ height: '100vh', width: '100%', zIndex: '0' }}
          ref={mapRef}
          zoomControl={false}
        >

          <ZoomControl position='topright' />
          <SetViewOnClick animateRef={mapRef.current} />

          <MarkersPois />

          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name={name}>
              <LayerGroup >
                <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
              </LayerGroup>
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={t('Δορυφόρος')}>
              <LayerGroup >
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png" />
              </LayerGroup>
            </LayersControl.BaseLayer>
          </LayersControl>
        </MapContainer>
      </>
    )
  }

  return (
    <>

      <HelmetComponent title={t('Χάρτης Σημείων')} descrition={t('Χάρτης Σημείων')} />

      <div style={{ width: '100%', height: '100%' }}>
        <Row >
          <Col xs={24} lg={24} xl={24} >
            <MapContainerHead />

            <SideBar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} setRadioValue={setRadioValue} />

          </Col>
        </Row>
      </div>
    </>
  )
}

export default Map