import React, { useState, useEffect } from "react"
import { Tabs, Image, Carousel, Row, Col, Modal } from 'antd';
import classes from './styles.module.css'
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { BarsOutlined, CameraOutlined, VideoCameraOutlined, PictureOutlined, ReadOutlined, SoundOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player/lazy'
// import { SampleNextArrow, SamplePrevArrow } from './arrows'
import { SampleNextArrow, SamplePrevArrow } from 'common/ArrowsForCarousel'
import cookies from "js-cookie";

interface Props {
  Pointid: any,
  Pointinfo: any,
  PointThumb: any,
  VoiceMp3: any,
  PointGallery: any,
  Bibliography: any,
  Point3d: any,
  Point360: any,
  Collection: any
}

const ComponentTabs: React.FC<Props> = ({ Pointid, Pointinfo, PointThumb, VoiceMp3, PointGallery, Bibliography, Point360, Point3d, Collection }) => {
  const { t } = useTranslation()
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [pois, setPois] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [ActiveID, setActiveID] = useState();
  const [Activeimg, setActiveimg] = useState();
  const [Activename, setActivename] = useState();
  const [Activeinfo, setActiveinfo] = useState();
  let activecategory = '';
  if (Pointid == '1_314')
    activecategory = '20';
  else if (Pointid == '1_318')
    activecategory = '21';
  else if (Pointid == '1_313')
  activecategory = '19';
  var currentLanguageCode = cookies.get("i18next") || "el";

  function openPoint(point: any, img: any, name: any, info: any) {
    setIsActive(current => !current);
    setActiveID(point);
    setActiveimg(img);
    setActivename(name);
    setActiveinfo(info)
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }



  const getCategoryPois = async () => {
    const url = `https://rethymno.mitos.cityguideplatform.com/api/pois?category_id=${activecategory}&lang=${currentLanguageCode}`
    const response = await fetch(url,
      {
        signal,
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    if (!response.ok) {
      throw new Error('Data could not be fetched!')
    }
    else {

      return response.json()
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  useEffect(() => {

    getCategoryPois()
      .then((res1) => {
        console.log(res1);

        setPois(res1.success)
      })

      .catch((e) => {
        console.log(e.message)
      })
  }, [i18next.language])

  const isMobile = width <= 768;
  const controller = new AbortController();
  const { signal } = controller;

  const [pointsId, setPointsId] = useState();

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  const settingsCarousel = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const items1 = []
  const [index, setIndex] = React.useState(-1);
  items1.push({
    label: <div><span> <span style={{ fontSize: '36px', border: 'none' }}><img src="/img/tabicons/description.png" /></span></span><b>{t("Περιγραφή")}</b></div>,
    key: '1',
    children:
      <div>
        <h2 className={classes.title}>{t("Περιγραφή")}</h2>
        {VoiceMp3.map((mp: any) => {
          return (
            <ReactPlayer width={'100%'} height={'50px'} controls url={mp.url} />
          )
        })}
        {isMobile ?
          <div className={classes.p} dangerouslySetInnerHTML={{ __html: Pointinfo }} />
          :
          <div className={classes.p} dangerouslySetInnerHTML={{ __html: Pointinfo }} />
        }
      </div>
  })

  {
    PointGallery.length > 1 &&
      items1.push({
        label: <div><span> <span style={{ fontSize: '36px', border: 'none' }}><img src="/img/tabicons/photos.png" /></span></span><b>{t("Φωτογραφίες")}</b></div>,
        key: '2',
        children:
          <div>
            <h2 className={classes.title}>{t("Φωτογραφίες")}</h2>
            <Image.PreviewGroup>

              {PointGallery.map((image: any) => {
                return (
                  <span className={classes.pimg} style={{ padding: '4px' }}>
                    <Image src={image.url} width={420} />
                  </span>
                )
              })}
            </Image.PreviewGroup>
          </div>
      })
  }

  {
    (Pointid == '1_314' || Pointid == '1_318' || Pointid == '1_313') &&
      items1.push({
        label: <div><span> <span style={{ fontSize: '36px', border: 'none' }}><img src="/img/tabicons/doc.png" /></span></span><b>{t("Συλλογές")}</b></div>,
        key: '6',
        children:
          <div>
            <h2 className={classes.title}>{t("Συλλογές")}</h2>
            <div className={classes.top_cat_gal}>
              <div className="car3dc">
                <Carousel {...settingsCarousel}>
                  {pois.map(poi =>
                    <div key={poi['id']} className={classes.categoriesimggal}>
                      <span style={{
                        display: isActive ? 'block' : 'none',
                      }}>
                        <img src={poi['main_image']} width="100%" />
                        <span><b>{poi['name']}</b>

                          {/* <div className={classes.p} dangerouslySetInnerHTML={{ __html: poi['portal_main_info'] }} /> */}<br />
                          <span className={classes.p1} onClick={() => openPoint(poi['id'], poi['main_image'], poi['name'], poi['portal_main_info'])}>{t("Δείτε περισσότερα")}</span>
                        </span>
                      </span>
                      <span className={classes.fullpt} style={{
                        display: isActive ? 'none' : 'block',

                      }}>
                        <img src={poi['main_image']} width="100%" />
                        <span><b>{poi['name']}</b>
                          <div className={classes.p11} dangerouslySetInnerHTML={{ __html: poi['portal_main_info'] }} />
                          <span className={classes.p1} onClick={() => openPoint(poi['id'], poi['main_image'], poi['name'], poi['portal_main_info'])}>{t("Δείτε λιγότερα")}</span>
                        </span>
                      </span>
                    </div>
                  )}
                </Carousel>

              </div>
            </div>

          </div>
      })
  }

  {
    Point360 &&
      items1.push({
        label: <div><span> <span style={{ fontSize: '36px', border: 'none' }}><img src="/img/tabicons/360.png" /></span></span><b>360<sup>o</sup></b></div>,
        key: '3',
        children:
          <div>
            <h2 className={classes.title}>{t("Εικονικό Πανόραμα")} 360<sup>o</sup></h2>
            {Pointid == '1_340' &&
              <div>
                <iframe src='/360/1.html' width="100%" height="480px" scrolling="no" ></iframe>
              </div>
            }
          </div>
      })
  }
  {
    Point3d &&
      items1.push({
        label: <div><span> <span style={{ fontSize: '36px', border: 'none' }}><img src="/img/tabicons/3d.png" /></span></span><b>3D</b></div>,
        key: '4',
        children:
          <div>
            <h2 className={classes.title}>{t("3D Μοντέλο")}</h2>
            {Pointid == '1_313' && <div className={classes.sketchfab_embed}> <iframe title="Kara Musa Pasha Cami, Καρά Μουσά Τζαμί-Ρέθυμνο" allowFullScreen allow="autop1lay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/a5c26546541e45b09a2718ab8f943efc/embed?autostart=1&dnt=1"> </iframe> </div>}
            {Pointid == '1_340' && <div className={classes.sketchfab_embed}> <iframe title="Kara Musa Pasha Cami, Καρά Μουσά Τζαμί-Ρέθυμνο" allowFullScreen allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/64f12e67cd444f6f98641323ae7f9199/embed?autostart=1&dnt=1"> </iframe> </div>}
            {Pointid == '1_339' && Pointid == '1_337' && Pointid == '1_338' && Pointid == '1_328' && Pointid == '1_339' && Pointid == '1_329' && <div className={classes.sketchfab_embed}> <iframe title="Kara Musa Pasha Cami, Καρά Μουσά Τζαμί-Ρέθυμνο" allowFullScreen allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/bf5156372a3343c1a74e514cca5247fb/embed?autostart=1&dnt=1"> </iframe> </div>}
          </div>
      })
  }
  {
    Bibliography &&
      items1.push({
        label: <div><span> <span style={{ fontSize: '36px', border: 'none' }}><img src="/img/tabicons/bibliography.png" /></span></span><b>{t("Βιβλιογραφία")}</b></div>,
        key: '5',
        children:
          <div>
            <h2 className={classes.title}>{t("Βιβλιογραφία")}</h2>
            <div className={classes.p} dangerouslySetInnerHTML={{ __html: Bibliography }} />
          </div>
      })
  }


  return (
    <><br />

      <Tabs
        defaultActiveKey="1"
        centered
        tabBarStyle={{ color: 'black', outlineOffset: 'none' }}
        items={items1}
      />
    </>
  )
}

export default ComponentTabs