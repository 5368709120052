import React, {useEffect} from "react"
import classes from './styles.module.css'

import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'

const CommonPage2: React.FC = () => {

  const { i18n } = useTranslation()
  const location = useLocation()


  // const { isLoading, isFetching, error, data, refetch } = useQuery( ['page'],
  //   async () => {
  //     const { data } = await axios("https://api.chucknorris.io/jokes/random");
  //     return data;
  //   }
  // );

  // console.log('common page')


  
  return (
    <>
      <HelmetComponent title='Προσωπικά Δεδομένα & ΓΚΠΔ' descrition="SEO Προσωπικά Δεδομένα και Πολιτική Απορρήτου περιγραφή" />
      
      <div className={classes.container}>
        <h3 className={classes.h3}>Προσωπικά Δεδομένα & ΓΚΠΔ</h3>
        <div><b>Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα</b>
<p>
Έχετε δικαίωμα να υποβάλλετε καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (www.dpa.gr), η οποία είναι η αρμόδια εποπτική αρχή για την προστασία των θεμελιωδών δικαιωμάτων και ελευθεριών των φυσικών προσώπων έναντι της επεξεργασίας των προσωπικών τους δεδομένων του, εφόσον θεωρείτε ότι προσβάλλονται τα δικαιώματά σας με οποιοδήποτε τρόπο. Σας παροτρύνουμε να δείτε την Πολιτική για την Ιδιωτικότητα και την Προστασία των Προσωπικών Δεδομένων του Ιδρύματος στον σύνδεσμο Πολιτική Ιδιωτικότητας και προστασίας δεδομένων
</p>
        </div>
      </div> 
    </>
  )
}

export default CommonPage2
