import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
iframe{
  border:none!important;
}
.whitebubble{
  border-radius: 50px;
  background-color: #323f5f;
  cursor: pointer;
  will-change: background-color;
  transition: background-color 0.3s ease 0s;
  border: 1px solid #323f5f;
  padding: 6px;
  font-weight: bold;
  line-height: 1;
  font-size: 13px;
  color: white !important;
}
.ant-carousel .slick-next:hover,.ant-carousel .slick-prev:hover{
  color:#323F5F!important;
}
.ant-tabs-tabpane {
  outline: none;
  margin-bottom: 50px!important;
  float:left;
  width:100%;
  position: relative;
}
.ant-drawer {
z-index:999999;
}
.ant-carousel{
  width:110%;
}
  .ant-tabs {
    &-nav {
      display: flex;
  
      .ant-tabs-tab {
        flex-grow: 1;
        margin-right: 0px;
        width: 98%;
        text-align: center;
      }
    }
  }
  .ant-collapse-header::before{
    width:36px;
    height:36px;
    background:url('/img/routes/routes.png');
    background-repeat:no-repeat;
    background-position:center center;
    padding:15px;
    background-color:#D55E2D;
    content:'1';
    color:transparent;
    border-radius:20px!important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding:0!important;
    line-height: 35px;
  }
  .ant-collapse{
    background-color: transparent;
    border: none!important;
  }
  .ant-collapse-content {
    background-color: transparent;
    border-top: none!important;
    height:350px;
    overflow-y:scroll;
    max-width:100%;
}
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: transform .3s;
    width: 98%;
  }
  
 /* .car3d:not(.car3d1) .slick-current div img,.car3d .slick-current div{
    border-bottom-left-radius	:60px!important;
  }*/
  
  .car3dc .slick-slider{
    left:-50px;
  }
  .car3dc .slick-active div, .car3dc .slick-active img{
    width:40%;
    height:240px;
  }
  .car3dc .slick-active div img{
    padding:30px 50px;
    display:block;
    opacity:0.3;
   }
  
   .car3dc .slick-active div,.car3dc .slick-active img{
    width:100%;
    height:420px;
  }
  .car3dc .slick-current{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.86) 0%, #FFFFFF 53.65%);
border-radius: 0px;
filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.45));
   }
   .car3dc .slick-next{
    top:210px !important;
    left: 66.5% !important;
    z-index:9999999!important;
   }
   .car3dc .slick-prev{
    top:210px !important;
    left: 31% !important;
    z-index:9999999!important;
   }
  .car3dc .slick-current div{
    height:auto;
   
   }
  .car3dc .slick-current div img{
   padding:0px;
   display:block;
   opacity:1;
   left:0px;
    right:0px;
    position:relative;
  }
  .ant-tabs-tab-active svg, .ant-tabs-tab:hover svg  {
   color: #B9BC66 !important;
  }
.ant-tabs-tab-btn span img {
    padding: 5px;
    width: 35px;
	float: left;
}
.ant-tabs-tab-btn span span{
    background: rgba(150, 129, 91, 0.2);
    backdrop-filter: blur(5px);
    padding: 10px;
    border-radius: 30px !important;
    width: 55px;
    height: 55px;
    float: left;
}
.ant-tabs-tab-btn:hover span span,.ant-tabs-tab-active  span span{
 background: #d55e2d!important;
}
.ant-tabs-tab-btn b {
	line-height:45px;
	margin: 0px 35px 0px 10px;
	color:block!important;
	font-weight:normal;
	font-size: 18px;
}



.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
	// font-weight:bold;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn b{
  color: black;
	font-weight:bold;
}

.ant-tabs-tab-btn b:hover,.ant-tabs-tab:hover{
  color: #d55e2d !important;
	// font-weight:bold !important;
}
audio{
    margin-bottom: 20px!important;
    float: left;
    position: relative;
	display:block;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: auto;
}

//////   for bottom border   ///////////
  .ant-tabs-ink-bar {
    height: 5px;
    background: none!important;
  }
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 2px solid #d55e2d;
    content: '';
}

  /* Custom Coloring */

  .ant-carousel .slick-dots li button {
      opacity: 0.3;
      width:20px;
      height:20px;
      border-radius:50%;
      border:2px solid white;
      background-color: transparent;
  }
  .ant-carousel .slick-dots li.slick-active button {   
      opacity: 1;
      width:30px;
      height:30px;
      border-radius:50%;
      border:2px solid white;
      background-color: transparent;
      margin-top:-4px;
  }
  .ant-carousel .slick-dots li {
      margin-right: 16px;
  } 

  .ant-carousel .slick-dots-bottom {
    bottom: 140px;
  }

  .ant-carousel {
    .slick-next {
        &::before {
        content: '';
        }
    }
    .slick-prev { 
        &::before {
        content: '';
        }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 20px;}

  .ant-drawer-body {
    padding: 4px;
   
    
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
      background: #green
  }
  ::-webkit-scrollbar-track-piece {
      background: #888
  }
  ::-webkit-scrollbar-thumb {
      background: #eee
  }​

  .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label, .ant-form-vertical .ant-form-item-label {
    display: none;
  }

  .result_message a {
    color: red !important;
  }

  @media only screen and (max-width: 600px) { 
    .ant-carousel .slick-prev {
      left:28vw !important;
      top: 200px;
      
      
    }
    .ant-carousel .slick-next {
      left: 42vw !important;
      top: 200px;
    }
    
    .slick-list {
      max-height: 355px;
    }

	  nav{
		position:relative;
		float:left;
		width:100%
	  }

    .car3dc .slick-slider{
      left:-22px;
    }

    .slick-current{
      width: 81vw !important;
      margin-left: -8vw;
    }
  }
`;