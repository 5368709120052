
import React, { useState } from 'react';
import classes from './styles.module.css'
import { Row, Col, Button } from 'antd'
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import ModelViewer from './components/modelviewer';
import { BsMouse2Fill } from "react-icons/bs";
import mouseRotate from "../../images/mouse-rotate.svg";
import mouseMove from "../../images/mouse-move.svg";
import mouseZoom from "../../images/mouse-zoom.svg";


const Media: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, toggle } = useModal();
  const [threeD, setThreeD] = useState<string>('');
  const [modelTitle, setModelTitle] = useState<string>('');
  const [scale, setScale] = useState<number>(3);


  const toggleSetModal = (modelPath: string, title: string, scale: number) => {
    toggle();
    setThreeD(modelPath);
    setModelTitle(title);
    setScale(scale);
  }

  const bgimg = {
    backgroundImage: `url("/img/P1002676.jpg")`,
    height: '',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: '-130px',
    backgroundPosition: '25% 18%'
  };
  return (

    <>
      <Row justify="end">
        <div style={bgimg}>

          <div className={classes.top_banner_txt}>
            <div>

              <span>{t('Εφαρμογή τρισδιάστατων αναπαραστάσεων')}</span>
            </div>
          </div>

        </div>
      </Row>
      <Modal isOpen={isOpen} toggle={toggle}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggle}>x</button></div>
        <div>
          <h2 className={classes.title}>{t(modelTitle) + " 360"}&#176;</h2>
          <div style={{ height: '510px', boxShadow: 'rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset' }}>
            <ModelViewer modelPath={threeD} scale={scale} />
            <div className={classes.handbook}>
              <Row>
                <Col xs={24} sm={24} lg={8} xl={8} className={classes.colHandbook}>
                  <span className={classes.handbookTitle}>{t('Περιστροφή')}: </span><img className={classes.mouseIcon} src={mouseRotate} alt="rotate" />
                </Col>
                <Col xs={24} sm={24} lg={8} xl={8} className={classes.colHandbook}>
                  <span className={classes.handbookTitle}>{t('Μετακίνηση')}: </span><img className={classes.mouseIcon} src={mouseMove} alt="move" />
                </Col>
                <Col xs={24} sm={24} lg={8} xl={8} className={classes.colHandbook}>
                  <span className={classes.handbookTitle}>{t('Μεγέθυνση')}: </span><img className={classes.mouseIconBig} src={mouseZoom} alt="zoom" />
                </Col>
              </Row>
            </div>

          </div>
        </div>
      </Modal>
      <Row justify="end">
        <div className={classes.main}>

          <br />
          <div className={classes.sketchfab_embed}>
            <div className={classes.carouselslider}>
              <div className={classes.carouselimg}><img src="/img/360/Λίνδος - Ναός Αθηνάς.png" alt="Ενετικό Λιμάνι Ρεθύμνου" /></div>
              <div className={classes.carouseldesc}>
                <h3>Ενετικό Λιμάνι Ρεθύμνου</h3>
                <div className={classes.carouselmore}>
                  <a className={classes.btnToggle} onClick={() => { toggleSetModal("../threeD/Enetiko Limani Retythmou.glb", "Ενετικό Λιμάνι Ρεθύμνου", 3) }}><u>{t('Μάθε περισσότερα')}</u></a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sketchfab_embed}>
            <div className={classes.carouselslider}>
              <div className={classes.carouselimg}><img src="/img/360/Neratze Tzami Odeio 1.png" alt="Νερατζέ Τζαμί" /></div>
              <div className={classes.carouseldesc}>
                <h3>Νερατζέ Τζαμί</h3>
                <div className={classes.carouselmore}>
                  <a className={classes.btnToggle} onClick={() => { toggleSetModal("../threeD/Neratze_tzami_odeio.glb", "Νερατζέ Τζαμί", 5) }}><u>{t('Μάθε περισσότερα')}</u></a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sketchfab_embed}>
            <div className={classes.carouselslider}>
              <div className={classes.carouselimg}><img src="/img/360/Λότζια Ρέθυμνο 1.png" alt="Λότζια Ρέθυμνο" /></div>
              <div className={classes.carouseldesc}>
                <h3>Λότζια Ρέθυμνο</h3>
                <div className={classes.carouselmore}>
                  <a className={classes.btnToggle} onClick={() => { toggleSetModal("../threeD/Lotzia.glb", "Λότζια Ρέθυμνο", 8) }}><u>{t('Μάθε περισσότερα')}</u></a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sketchfab_embed}>
            <div className={classes.carouselslider}>
              <div className={classes.carouselimg}><img src="/img/360/palaiontologiko--temenos 1.png" alt="Τζαμί Μασταμπά-Παλαιοντολογικό Μουσείο Ρεθύμνου" /></div>
              <div className={classes.carouseldesc}>
                <h3>Τζαμί Μασταμπά-Παλαιοντολογικό Μουσείο Ρεθύμνου</h3>
                <div className={classes.carouselmore}>
                  <a className={classes.btnToggle} onClick={() => { toggleSetModal("../threeD/Palaiontologiko_temenos.glb", "Τζαμί Μασταμπά-Παλαιοντολογικό Μουσείο Ρεθύμνου", 6) }}><u>{t('Μάθε περισσότερα')}</u></a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sketchfab_embed}>
            <div className={classes.carouselslider}>
              <div className={classes.carouselimg}><img src="/img/360/fortetza.png" alt="Φορτέτζα Ρεθύμνου" /></div>
              <div className={classes.carouseldesc}>
                <h3>Φορτέτζα Ρεθύμνου</h3>
                <div className={classes.carouselmore}>
                  <a className={classes.btnToggle} onClick={() => { toggleSetModal("../threeD/fortetza.glb", "Φορτέτζα Ρεθύμνου", 0.6) }}><u>{t('Μάθε περισσότερα')}</u></a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sketchfab_embed}>
            <div className={classes.carouselslider}>
              <div className={classes.carouselimg}><img src="/img/360/palaiontologiko--kelia.png" alt="Κελιά-Παλαιοντολογικό Μουσείο Ρεθύμνου" /></div>
              <div className={classes.carouseldesc}>
                <h3>Κελιά-Παλαιοντολογικό Μουσείο Ρεθύμνου</h3>
                <div className={classes.carouselmore}>
                  <a className={classes.btnToggle} onClick={() => { toggleSetModal("../threeD/Palaiontologiko_kelia.glb", "Κελιά-Παλαιοντολογικό Μουσείο Ρεθύμνου", 6) }}><u>{t('Μάθε περισσότερα')}</u></a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sketchfab_embed}>
            <div className={classes.carouselslider}>
              <div className={classes.carouselimg}><img src="/img/360/Enetiko Limani Retythmou_Faros.png" alt="Ενετικό Λιμάνι Ρεθύμνου-Φάρος" /></div>
              <div className={classes.carouseldesc}>
                <h3>Ενετικό Λιμάνι Ρεθύμνου-Φάρος</h3>
                <div className={classes.carouselmore}>
                  <a className={classes.btnToggle} onClick={() => { toggleSetModal("../threeD/Enetiko Limani Retythmou_faros.glb", "Ενετικό Λιμάνι Ρεθύμνου-Φάρος", 6) }}><u>{t('Μάθε περισσότερα')}</u></a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sketchfab_embed}>
            <div className={classes.carouselslider}>
              <div className={classes.carouselimg}><img src="/img/360/kara-musa-pasha-cami.png" alt="Καρά Μουσά Τζαμί" /></div>
              <div className={classes.carouseldesc}>
                <h3>Καρά Μουσά Τζαμί</h3>
                <div className={classes.carouselmore}>
                  <a className={classes.btnToggle} onClick={() => { toggleSetModal("../threeD/kara-musa-pasha-cami.glb", "Καρά Μουσά Τζαμί", 8) }}><u>{t('Μάθε περισσότερα')}</u></a>
                </div>
              </div>
            </div>
          </div>
          <br /><br />
        </div>
      </Row>
    </>
  );
}

export default Media