import * as React from "react";

const SvgSmallClose: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg width={34} height={34} viewBox="0 0 6 30" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.145 1 13 12.855M13 1 1 12.855"
      stroke="#fff"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
);

export default SvgSmallClose;
