import React,{useState,useEffect,useContext} from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'
import { usePoint } from 'hooks/useMap'
import { useParams } from 'react-router-dom'
import ComponentTabs from './components/Tab'
import Spinner from 'common/Spinner'
import { SVG_Acropoli, SVG_Oikismos, SVG_Ypaifro } from '../../images/SVG_Acropoli_Oikismos_Ypaifros'
import HelmetComponent from 'components/Helmet'
import cookies from "js-cookie";
import i18next from "i18next"
import LanguageContext from "../../store/language-context"
import { useTranslation } from "react-i18next"


const Point: React.FC = () => {
  
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation()

  var currentLanguageCode = cookies.get("i18next") || "el";
  const languageCtx = useContext(LanguageContext);
  //const { isFetching, Point } = usePoint( id ? parseInt(id) : 3822)
  
  const pathname = window.location.pathname;
  const pid = pathname.split("/").pop(); 

  const [pointId, setPointId] = useState();
  const [pointName, setPointName] = useState();
  const [PointInfo, setPointInfo] = useState();
  const [Point3d, setPoint3d] = useState();
  const [Point360, setPoint360] = useState();
  const [PointThumb, setPointThumb] = useState();
  const [PointCat, setPointCat] = useState();
  const [PointCatId, setPointCatId] = useState();
  const [VoiceMp3, setVoiceMp3] = useState([]);
  const [Bibliography, setPointBibliography] = useState();
  const [Collection, setPointCollection] = useState();
  const [PointGallery, setPointGallery] = useState([]);
  // Function to collect data
  const getPoint = async () => {
    
    const url = `https://rethymno.mitos.cityguideplatform.com/api/poi?id=${pid}&fetch_files=1&lang=${currentLanguageCode}`
    const response = await fetch(url, 
      { signal, 
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'}
       })
       if (!response.ok) {
        throw new Error('Data coud not be fetched!')
      } 
    else {
        return response.json()
    }
  };

  useEffect(() => {
    getPoint()
      .then((res) => {
        setPointId(res.success.id)
        setPointCollection(res.success.extras._collection.value)
        setPointName(res.success.name)
        setPointInfo(res.success.portal_main_info)
        setPointBibliography(res.success.bibliographic_citation)
        setPointThumb(res.success.main_image)
        setPointCat(res.success.category_path[0])
        setPointCatId(res.success.category_path_ids[0])
        setPointGallery(res.success.files[1])
        setVoiceMp3(res.success.files[4])
        setPoint3d(res.success.files[6][0]["url"])
        setPoint360(res.success.files[1])
        
      })
      .catch((e) => {
        console.log(e.message)
      })
  },[i18next.language])
  
  const IMAGES:any = []
  const VOICE:any = []
  const COL:any = []

  const myStyle={
    backgroundImage: `url(${PointThumb})` ,
    height:'530px',
    fontSize:'50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};
  return (
    <>
    
      <HelmetComponent title={pointName} descrition={ `$pointName} ${t("στην")} ${pointName}` } image={PointThumb} />     
      <Row>
        <div className={classes.top_banner}>    
        <Col xs={24} sm={12} lg={12}>
            <div className={classes.top_banner_img} style={myStyle}>
              
            </div>
          </Col>
           <Col xs={24} sm={12} lg={12}>
           <div className={classes.top_banner_txt}>
              <p className={classes.top_banner_txt1}>
              {PointCatId ==2 ? <div className={classes.carouselicon}><img src='/img/categories/Τζαμιά.png' alt={PointCat} /></div>:""}
                   {PointCatId ==3 ? <div className={classes.carouselicon}><img src='/img/categories/Ναοί - Μονές.png' alt={PointCat} /></div>:""}
                   {PointCatId ==4 ? <div className={classes.carouselicon}><img src='/img/categories/Πρόσωπα.png' alt={PointCat} /></div>:""}
                   {PointCatId ==5 ? <div className={classes.carouselicon}><img src='/img/categories/Κρήνες.png' alt={PointCat} /></div>:""}
                   {PointCatId ==6 ? <div className={classes.carouselicon}><img src='/img/categories/Κάστρο Φορτέτζα.png' alt={PointCat} /></div>:""}
                   {PointCatId ==7 ? <div className={classes.carouselicon}><img src='/img/categories/Μνημεία.png' alt={PointCat} /></div>:""}
                   {PointCatId ==8 ? <div className={classes.carouselicon}><img src='/img/categories/Παραδοσιακά επαγγέλματα.png' alt={PointCat} /></div>:""}
                   {PointCatId ==9 ? <div className={classes.carouselicon}><img src='/img/categories/Οικίες.png' alt={PointCat} /></div>:""}
                   {PointCatId ==10 ? <div className={classes.carouselicon}><img src='/img/categories/Κτήρια με επιμέρους αρχιτεκτονικά χαρακτηριστικά.png' alt={PointCat} /></div>:""}
                   {PointCatId ==11 ? <div className={classes.carouselicon}><img src='/img/categories/Μουσεία.png' alt={PointCat} /></div>:""}
                   {PointCatId ==12 ? <div className={classes.carouselicon}><img src='/img/categories/Οδοί - πλατείες.png' alt={PointCat} /></div>:""}
                   {PointCatId ==13 ? <div className={classes.carouselicon}><img src='/img/categories/Ιδιαίτερα Αρχιτεκτονικά Χαρακτηριστικά Κτηρίων.png' alt={PointCat} /></div>:""}
                   {PointCatId ==14 ? <div className={classes.carouselicon}><img src='/img/categories/Οροφογραφίες.png' alt={PointCat} /></div>:""}
                   {PointCatId ==15 ? <div className={classes.carouselicon}><img src='/img/categories/Θυρώματα.png' alt={PointCat} /></div>:""}
                   {PointCatId ==16 ? <div className={classes.carouselicon}><img src='/img/categories/Κιόσκια - Σαχνισιά.png' alt={PointCat} /></div>:""}
                   {PointCatId ==17 ? <div className={classes.carouselicon}><img src='/img/categories/Φωτογραφικό Αρχείο Giuseppe Gerola.png' alt={PointCat} /></div>:""}
                   {PointCatId ==18 ? <div className={classes.carouselicon}><img src='/img/categories/Ιστορία του Ρεθύμνου μέσα απο παλιές φωτογραφίες.png' alt={PointCat} /></div>:""}
                   {PointCatId ==19 ? <div className={classes.carouselicon}><img src='/img/categories/Συλλογή Παλαιοντολογικού.png' alt={PointCat} /></div>:""}
                   {PointCatId ==20 ? <div className={classes.carouselicon}><img src='/img/categories/Συλλογή Λαογραφικού.png' alt={PointCat} /></div>:""}
                   {PointCatId ==21 ? <div className={classes.carouselicon}><img src='/img/categories/Συλλογή Αρχαιολογικού.png' alt={PointCat} /></div>:""}
                   {PointCatId ==22 ? <div className={classes.carouselicon}><img src='/img/categories/Ιστορικοί Χάρτες.png' alt={PointCat} /></div>:""}
                {PointCat}
              </p>
              <h2>{pointName}</h2>
            </div>
          </Col> 
          
        </div>
      </Row>

        <div className={classes.dummy}>
          
          {PointGallery.map((a4) => (
              IMAGES.push({caption: a4["caption"], url: a4["url"] })
          ))}
          
          {VoiceMp3 && VoiceMp3.map((a4, index) => (
              index == 1 && currentLanguageCode == 'el' && 
              VOICE.push({url: a4["url"] })
          ))}

          {VoiceMp3 && VoiceMp3.map((a4, index) => (
              index == 0 && currentLanguageCode != 'el' && 
              VOICE.push({url: a4["url"] })
          ))}
        </div>
      
        <Row justify="center">
        <Col xs={24} sm={20} md={20} lg={20} >
          <ComponentTabs Pointid = {pointId} Pointinfo={PointInfo} PointThumb={PointThumb} VoiceMp3={VOICE} PointGallery={IMAGES} Bibliography={Bibliography} Point360={Point360} Point3d={Point3d} Collection={Collection}/>
        </Col>
      </Row>  
    </>
  )
}

export default Point