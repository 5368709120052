import React, { useState, useEffect, useContext } from "react"
import { Row, Col, Card, Button } from 'antd'
import classes from './styles.module.css'
import { usePoint } from 'hooks/useMap'
import { useParams, Link } from 'react-router-dom'
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'
import { SampleNextArrow, SamplePrevArrow } from 'common/ArrowsForCarousel'
import cookies from "js-cookie";
import i18next from "i18next"
import LanguageContext from "../../store/language-context"
import { useTranslation } from "react-i18next"
import { Carousel } from 'antd';
import parse from 'html-react-parser';


const SingleCategory: React.FC = () => {
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation()

  var currentLanguageCode = cookies.get("i18next") || "el";
  const languageCtx = useContext(LanguageContext);

  const { id } = useParams()
  const [categories, setCategories] = useState([]);
  const [pois, setPois] = useState([]);
  // Function to collect data
  const pathname = window.location.pathname;
  const activecategory = pathname.split("/").pop();
  const [showMore, setShowMore] = useState<boolean>(false)
  const [string, setString] = useState<String>('')
  const getCategory = async () => {
    const url = `https://rethymno.mitos.cityguideplatform.com/api/maincategories?lang=${currentLanguageCode}`
    const response = await fetch(url,
      {
        signal,
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    if (!response.ok) {
      throw new Error('Data could not be fetched!')
    }
    else {
      return response.json()
    }

  };

  const getCategoryPois = async () => {
    const url = `https://rethymno.mitos.cityguideplatform.com/api/pois?category_id=${activecategory}&lang=${currentLanguageCode}`
    const response = await fetch(url,
      {
        signal,
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    if (!response.ok) {
      throw new Error('Data could not be fetched!')
    }
    else {

      return response.json()
    }
  };

  useEffect(() => {

    getCategory()
      .then((res) => {
        setCategories(res.success)
      })
    getCategoryPois()
      .then((res1) => {
        setPois(res1.success)
      })

      .catch((e) => {
        console.log(e.message)
      })
  }, [i18next.language])

  function GridPoints() {

    return (

      <Row>
        <div className={classes.top_cat}>
          <div className={classes.gridContainer}>
            {pois.map(poi =>
              <Link to={`/rethymno/${poi['id']}`} state={{ category: poi['name'] }}>
                <div className={classes.categoriesimg}>
                  <img src={poi['main_image']} />
                  {/* <span><b>{poi['name']}</b></span> */}
                  <div className={classes.carouseldesc}>
                    <p>{poi['name']}</p>
                    <div className={classes.carouselmore}>
                      <Link to={`/rethymno/${poi['id']}`} state={{ category: poi['name'] }}>
                        {t('Μάθε περισσότερα')}
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            )}

          </div>
          <br />
          <br />
        </div>
      </Row>
    )
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  function GalleryPoints() {
    const [isActive, setIsActive] = useState(true);
    const [ActiveID, setActiveID] = useState();
    const [Activeimg, setActiveimg] = useState();
    const [Activename, setActivename] = useState();
    const [Activeinfo, setActiveinfo] = useState();

    function ActivePoint() {
      return (
        <span>{ActiveID}</span>
      )
    }
    function openPoint(point: any, img: any, name: any, info: any) {
      setIsActive(current => !current);
      setActiveID(point);
      setActiveimg(img);
      setActivename(name);
      setActiveinfo(info)
    }

    return (
      <>
        <Row>

          <div className={classes.top_cat_gal}>
            <div className="car3dc">
              <Carousel {...settings}>
                {pois.map(poi =>
                  <div key={poi['id']} className={classes.categoriesimggal}>
                    <span style={{
                      display: isActive ? 'block' : 'none',
                    }}>
                      <img src={poi['main_image']} width="100%" />
                      <span><b>{poi['name']}</b>

                        {/* <div className={classes.p} dangerouslySetInnerHTML={{ __html: poi['portal_main_info'] }} /> */}<br />
                        <span className={classes.p1} onClick={() => openPoint(poi['id'], poi['main_image'], poi['name'], poi['portal_main_info'])}>{t("Δείτε περισσότερα")}</span>
                      </span>
                    </span>
                    <span className={classes.fullpt} style={{
                      display: isActive ? 'none' : 'block',

                    }}>
                      <img src={poi['main_image']} width="100%" />
                      <span><b>{poi['name']}</b>
                        <div className={classes.p11} dangerouslySetInnerHTML={{ __html: poi['portal_main_info'] }} />
                        <span className={classes.p1} onClick={() => openPoint(poi['id'], poi['main_image'], poi['name'], poi['portal_main_info'])}>{t("Δείτε λιγότερα")}</span>
                      </span>
                    </span>
                  </div>
                )}
              </Carousel>

            </div>
          </div>

          <br />
          <br />
        </Row>
        <Row>
          <div className={classes.top_cat_gal}>

          </div>
        </Row>
      </>
    )
  }

  return (
    <>
      {/* <HelmetComponent title='lll' descrition={ `${Point.title} ${t("στην")} ${Point.subject}` } image={Point.thumbnail} /> */}

      <Row>
        <div className={classes.gallerycat}>
          <div className="app11">
            <div className={classes.gallery}>
              {categories.map(category => {
                if (category['id'] == activecategory)
                  return <><Row>
                    <div className={classes.top_banner}>
                      <Col xs={24} sm={12} lg={12}>
                        <div className={classes.top_banner_img}>
                          <img src={category['main_image']} alt="category['name']" />
                        </div>
                      </Col>
                      <Col xs={24} sm={12} lg={12}>
                        <div className={classes.top_banner_txt}>
                          <p className={classes.top_banner_txt1}>
                            {category['id'] == 2 ? <div className={classes.carouselicon}><img src='/img/categories/Τζαμιά.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 3 ? <div className={classes.carouselicon}><img src='/img/categories/Ναοί - Μονές.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 4 ? <div className={classes.carouselicon}><img src='/img/categories/Πρόσωπα.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 5 ? <div className={classes.carouselicon}><img src='/img/categories/Κρήνες.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 6 ? <div className={classes.carouselicon}><img src='/img/categories/Κάστρο Φορτέτζα.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 7 ? <div className={classes.carouselicon}><img src='/img/categories/Μνημεία.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 8 ? <div className={classes.carouselicon}><img src='/img/categories/Παραδοσιακά επαγγέλματα.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 9 ? <div className={classes.carouselicon}><img src='/img/categories/Οικίες.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 10 ? <div className={classes.carouselicon}><img src='/img/categories/Κτήρια με επιμέρους αρχιτεκτονικά χαρακτηριστικά.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 11 ? <div className={classes.carouselicon}><img src='/img/categories/Μουσεία.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 12 ? <div className={classes.carouselicon}><img src='/img/categories/Οδοί - πλατείες.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 13 ? <div className={classes.carouselicon}><img src='/img/categories/Ιδιαίτερα Αρχιτεκτονικά Χαρακτηριστικά Κτηρίων.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 14 ? <div className={classes.carouselicon}><img src='/img/categories/Οροφογραφίες.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 15 ? <div className={classes.carouselicon}><img src='/img/categories/Θυρώματα.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 16 ? <div className={classes.carouselicon}><img src='/img/categories/Κιόσκια - Σαχνισιά.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 17 ? <div className={classes.carouselicon}><img src='/img/categories/Φωτογραφικό Αρχείο Giuseppe Gerola.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 18 ? <div className={classes.carouselicon}><img src='/img/categories/Ιστορία του Ρεθύμνου μέσα απο παλιές φωτογραφίες.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 19 ? <div className={classes.carouselicon}><img src='/img/categories/Συλλογή Παλαιοντολογικού.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 20 ? <div className={classes.carouselicon}><img src='/img/categories/Συλλογή Λαογραφικού.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 21 ? <div className={classes.carouselicon}><img src='/img/categories/Συλλογή Αρχαιολογικού.png' alt={category['name']} /></div> : ""}
                            {category['id'] == 22 ? <div className={classes.carouselicon}><img src='/img/categories/Ιστορικοί Χάρτες.png' alt={category['name']} /></div> : ""}
                            {t("Κατηγορία Σημείων")}
                          </p>
                          <h2>{category['name']}</h2>
                        </div>
                      </Col>

                    </div>
                  </Row>
                    <Row justify="end">
                      <Col xs={24} sm={24} lg={24}>
                        <div className={classes.divider}>
                          <div className={classes.dividerBlock}></div>
                        </div>
                      </Col>
                    </Row>


                    {/* {category['description'] && !category['extras']['_photo_galleries']['value'] && */}
                    {
                      <>
                        <Row>
                          <div className={classes.top_cat}>
                            <div className="app11">
                              <div className={classes.desc}>
                                {/* <div className={classes.p} dangerouslySetInnerHTML={{ __html: category['description'] }} /> */}

                                {/* {
                                  console.log(Object.values(parse(category['description']))['0'].props)
                                  
                                } */}


                                {Object.values(parse(category['description']))['0']?.props ? parse(category['description']).toString.length != 0 ? "" : (showMore ? parse(category['description']) : parse(`${category['description']}`.substring(0, 868) + `...`)) : ""}

                                {Object.values(parse(category['description']))['0']?.props && parse(category['description']).toString.length == 0 ? <button style={{ fontSize: 15, color: 'red', border: 'none', background: 'none', margin: '-1rem 4vw', cursor: 'pointer' }} className={classes.more} onClick={() => { setShowMore(!showMore) }}>{!showMore ? t('Δείτε περισσότερα') : t('Δείτε λιγότερα')}</button> : ""}
                              </div>

                            </div>
                          </div>
                        </Row>

                      </>
                    }
                    {!category['extras']['_photo_galleries']['value'] &&
                      <GridPoints />
                    }
                    {category['extras']['_photo_galleries']['value'] &&
                      <GalleryPoints />
                    }
                  </>
              }
              )}

            </div>
          </div>
          <br />
          <br />
        </div>
      </Row>
    </>
  )
}

export default SingleCategory