import React from "react"
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Spin } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"

interface Props{
  text?: string
}

const Spinner: React.FC<Props> = ({ text = 'Παρακαλώ περιμένετε' }: Props) => {

  const {t} = useTranslation()
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Row justify="center" >
      <Col  span={12} offset={0}>
        <div className={classes.loading}> <Spin size="large" indicator={antIcon} tip={t(text)+`...`}/></div>
      </Col>
    </Row>
  )
}

export default Spinner