import React, { useState,useEffect } from "react"
import Search from "../Search/Search";


interface Props{
  item:any
}

const SearchClickComponent: React.FC<Props> = ({item}: Props) => {

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const searchToggle = () => setIsSearchOpen((prev) => !prev);
  const closeSearch = () => setIsSearchOpen(false);

  useEffect(() => {
    if (isSearchOpen)
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    else document.getElementsByTagName("body")[0].style.overflow = "auto";
  }, [isSearchOpen]);

  return (
    <>
      <a href="#!" onClick={() => searchToggle()}>
        {item} <span style={{ display: 'none' }}>Map</span>
      </a>

      {isSearchOpen && (
          <Search onClick={closeSearch}/>
      )}

    </>
  )
}

export default SearchClickComponent
