import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { PointsProps, PointsSortProps, PointProps, CategoryCountProps, TimelineTitle, Timeline,CategoriesProps } from "../services/props"
import { axiosPoints } from "../services/map"
import { Descriptions } from "antd"
import axios from 'axios'


export const usePoints = () => {
  
  const [Points, setPoints] = useState<PointsProps[]>([]);
  const [PointsSort, setPointsSort] = useState<PointsSortProps[]>([]);
  const [CategoriesCount, setCategoriesCount] = useState<CategoryCountProps[]>([]);

  // const [CategoriesCount, setCategoriesCount] = useState({
  //   'Ακρόπολη': 0,
  //   'Οικισμός': 0,
  //   'Ύπαιθρος': 0
  // })

  const { isLoading, isFetching, error } = useQuery(['points'], () => axiosPoints.getPoints(), {

    onSuccess: (data) => {

      let arrayPoints: PointsProps[] = []
      let arrayPointsSort: PointsSortProps[] = []
      let arrayCategoriesCount: CategoryCountProps[] = []
      let id: number = 0
      let title: string = ''
      let thumbnail: string = ''
      let type: string = ''
      let longtitude: string = ''
      let lantitude: string = ''

      let countAcropoli: number = 0
      let countOikismos: number = 0
      let countYpaifros: number = 0

      console.log('mmm');
      //console.log(JSON.parse(JSON.stringify(data)));
      console.log('nnn');

      data.map((points: any) => {

        if (points['@type'][1] !== 'dctype:Image') {

          for (var key of Object.keys(points)) {

            switch (key) {
              case 'o:id': id = points[key]; break;
              case 'o:title': title = points[key]; break;
              case 'thumbnail_display_urls': thumbnail = points[key]['large']; break;
              case 'dcterms:subject': type = points[key][0]['@value']; break;
              case 'dotsoft:longtitude': longtitude = points[key][0]['@value']; break;
              case 'dotsoft:lantitude': lantitude = points[key][0]['@value']; break;
            }
          }
       
        switch (type) {
          case 'Κρήνη': countAcropoli++; break;
          case 'Οικισμός': countOikismos++; break;
          case 'Ύπαιθρος': countYpaifros++; break;
        }
       
        arrayPoints.push({
          id: id,
          title: title,
          thumbnail: thumbnail,
          type: type,
          longitude: longtitude,
          lantitude: lantitude,
        })

        }//  if (points['@type'][1] !== 'dctype:Image')
      }) // data.map
 

      
      // SORTING
       const ArrayPoints = [3864, 3822, 3826, 3823, 3831, 3832, 3857, 3858, 3843, 3839]
      arrayPoints.map((point: any) => {
       
        switch (point.id) {
          case 4597:  arrayPointsSort.push({
                        mumber: 1,
                        id: point.id,
                        title: point.title,
                        thumbnail: point.thumbnail,
                        type: point.type,
                        longitude: point.longtitude,
                        lantitude: point.lantitude,
                      }); break;
          
          case 4609:  arrayPointsSort.push({
                        mumber: 2,
                        id: point.id,
                        title: point.title,
                        thumbnail: point.thumbnail,
                        type: point.type,
                        longitude: point.longtitude,
                        lantitude: point.lantitude,
                      }); break;
          
          case 4600: arrayPointsSort.push({ 
                        mumber: 3,
                        id: point.id,
                        title: point.title,
                        thumbnail: point.thumbnail,
                        type: point.type,
                        longitude: point.longtitude,
                        lantitude: point.lantitude,
                      }); break;
          case 4605: arrayPointsSort.push({ 
                      mumber: 4,
                      id: point.id,
                      title: point.title,
                      thumbnail: point.thumbnail,
                      type: point.type,
                      longitude: point.longtitude,
                      lantitude: point.lantitude,
                    }); break;
          case 4603: arrayPointsSort.push({ 
                      mumber: 5,
                      id: point.id,
                      title: point.title,
                      thumbnail: point.thumbnail,
                      type: point.type,
                      longitude: point.longtitude,
                      lantitude: point.lantitude,
                    }); break;
          case 4602: arrayPointsSort.push({ 
                      mumber: 6,
                      id: point.id,
                      title: point.title,
                      thumbnail: point.thumbnail,
                      type: point.type,
                      longitude: point.longtitude,
                      lantitude: point.lantitude,
                    }); break;
          case 4061: arrayPointsSort.push({ 
                      mumber: 7,
                      id: point.id,
                      title: point.title,
                      thumbnail: point.thumbnail,
                      type: point.type,
                      longitude: point.longtitude,
                      lantitude: point.lantitude,
                    }); break;
          case 3858: arrayPointsSort.push({ 
                      mumber: 8,
                      id: point.id,
                      title: point.title,
                      thumbnail: point.thumbnail,
                      type: point.type,
                      longitude: point.longtitude,
                      lantitude: point.lantitude,
                    }); break;
          case 3843: arrayPointsSort.push({ 
                      mumber: 9,
                      id: point.id,
                      title: point.title,
                      thumbnail: point.thumbnail,
                      type: point.type,
                      longitude: point.longtitude,
                      lantitude: point.lantitude,
                    }); break;
          case 3839: arrayPointsSort.push({ 
                      mumber: 10,
                      id: point.id,
                      title: point.title,
                      thumbnail: point.thumbnail,
                      type: point.type,
                      longitude: point.longtitude,
                      lantitude: point.lantitude,
                    }); break;
          }

      }) 

      setPointsSort(arrayPointsSort)
      // setCategoriesCount((prevState) => {
      //   return ({
      //       ...prevState,
      //       'Ακρόπολη': countAcropoli,
      //       'Οικισμός': countOikismos,
      //       'Ύπαιθρος': countYpaifros
      //     })
      // }); 

      arrayCategoriesCount.push(
        { id: 1, name: 'Ενετικό Κάστρο Φορτέτζα', count: countAcropoli, imgBig: 'https://rethymno-admin.repository.gr/files/asset/73e327d7c1d6695f38222c4c87cc8ab15e838590.jpg', imgSmall: 'img/castle.png', alt: 'photo1' },
        { id: 2, name: 'Τα μουσεία του Ρεθύμνου', count: countOikismos, imgBig: 'https://rethymno-admin.repository.gr/files/asset/16dd9f47a8edf48448806b82f72b48d26ac3ca8e.jpg', imgSmall: 'img/cat2.png', alt: 'photo2' },
        { id: 3, name: 'Τα τζαμιά του Ρεθύμνου', count:countYpaifros,  imgBig: 'https://rethymno-admin.repository.gr/files/asset/3fb7097d1dfdea9370fe3076daffae75fe1632f2.jpg', imgSmall: 'img/cat3.png', alt: 'photo3' },
        { id: 3, name: 'Οι κρήνες του Ρεθύμνου', count:countYpaifros,  imgBig: 'https://rethymno-admin.repository.gr/files/asset/c76a2a47825326304835ffb372cff46df90005fd.jpg', imgSmall: 'img/cat4.png', alt: 'photo3' })

      setCategoriesCount(arrayCategoriesCount)
      setPoints(arrayPoints)

    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Points, PointsSort, CategoriesCount }
}

export const usePoint = (id: number) => {

  const [Point, setPoint] = useState<PointProps>({
    id: 0,
    title: '',
    subject: '',
    type: '',
    description: '',
    mobileDescription: '',
    thumbnail: '',
    longitude: '',
    lantitude: '',
    images: [{ id: '', url: '', alt: '', descriptionImg: '' }],
    images3d: [{ id: '', url: '', alt: '' }],
    videos: [{ id: '', url: '', alt: '' }],
    bibliographic: ''
  });

  const { isLoading, isFetching, error } = useQuery(['point', id], () => axiosPoints.getPoint(id), {
    
    onSuccess: (data) => {

      let arrayImages = []
      let arrImages3d = []
      let arrayVideos = []
      let arrayBiblio = []

      let images = data['mobilemedia:lowResImages']
      //let imagesDescription = data['mobilemedia:lowResImages']
      //imagesDescription.shift();

/*       for (let i = 0; i < images.length; i++) {
        
  
        const url1 = FetchImg(images[i]['value_resource_id']);
   console.log(Imgurl)
        arrayImages.push({
          id: images[i]['value_resource_id'],
          url: 'aaa',
          alt: images[i]['vdisplay_title'],
          descriptionImg : images[i]['display_title']
        })
       
      } */
      {(() => {
        if (data['o:id']== 4600) {
            
          arrayImages.push({
            id: '5411',
            url: 'https://rethymno-admin.repository.gr/files/asset/464848705c19f72a9c9e409695072c2903a99763.jpg',
            alt: 'alt',
            descriptionImg : 'Κρήνη Ριμόντι - 01'
          })
    
          arrayImages.push({
            id: '5412',
            url: 'https://rethymno-admin.repository.gr/files/asset/536b324e582d6b6950f8ef4942757d82d46e09cf.jpg',
            alt: 'alt',
            descriptionImg : 'Κρήνη Ριμόντι - 02'
          })
    
          arrayImages.push({
            id: '5413',
            url: 'https://rethymno-admin.repository.gr/files/asset/112978b1db1af41d5171f6ef093cc1d009bbb9cc.jpg',
            alt: 'alt',
            descriptionImg : 'Κρήνη Ριμόντι - 03'
          })
    
          arrayImages.push({
            id: '5878',
            url: 'https://rethymno-admin.repository.gr/files/asset/c76a2a47825326304835ffb372cff46df90005fd.jpg',
            alt: 'alt',
            descriptionImg : 'Κρήνη Ριμόντι - 04'
          })
    
          arrayVideos.push({
            id: '5672',
            url: 'https://rethymno-admin.repository.gr/files/original/8299903da4b16b42405c3bec328a58d561b10872.mp3',
            alt: 'alt',
            descriptionImg : 'Κρήνη Ριμόντι - 04'
          })
        
        }

        if (data['o:id']== 4597) {
            
          arrayImages.push({
            id: '5400',
            url: 'https://rethymno-admin.repository.gr/files/asset/144cb002b070fe7b908b5b997557612b478a8a47.jpg',
            alt: 'alt',
            descriptionImg : 'Πλατεία Μικρασιατών / Νέα Πλατεία Παλιάς Πόλης'
          })
    
          arrayImages.push({
            id: '5399',
            url: 'https://rethymno-admin.repository.gr/files/asset/0ef635225875535a08b14ce0899c1a83d37cb76f.jpg',
            alt: 'alt',
            descriptionImg : 'Πλατεία Μικρασιατών / Νέα Πλατεία Παλιάς Πόλης2'
          })
    
          arrayImages.push({
            id: '5398',
            url: 'https://rethymno-admin.repository.gr/files/asset/fd7a544ddce75f114199075269208bddc0a31f7e.jpg',
            alt: 'alt',
            descriptionImg : 'Πλατεία Μικρασιατών / Νέα Πλατεία Παλιάς Πόλης'
          })
    
          arrayImages.push({
            id: '5397',
            url: 'https://rethymno-admin.repository.gr/files/asset/d560e0171ab0f73499137438a826b0d4196adf4a.jpg',
            alt: 'alt',
            descriptionImg : 'Πλατεία Μικρασιατών / Νέα Πλατεία Παλιάς Πόλης'
          })
          arrayImages.push({
            id: '5396',
            url: 'https://rethymno-admin.repository.gr/files/asset/1e65daecfef2011eeed539f46d941bac0782d169.jpg',
            alt: 'alt',
            descriptionImg : 'Πλατεία Μικρασιατών / Νέα Πλατεία Παλιάς Πόλης'
          })
    
          arrayVideos.push({
            id: '5675',
            url: 'https://rethymno-admin.repository.gr/files/original/e9da71af24e518e2e951be76ef35b9ac3fc0c850.mp3',
            alt: 'alt',
            descriptionImg : 'Πλατεία Μικρασιατών / Νέα Πλατεία Παλιάς Πόλης'
          })
        
        }

        if (data['o:id']== 4609) {   
          arrayImages.push({
            id: '5458',
            url: 'https://rethymno-admin.repository.gr/files/asset/06e0318186a470f73c8c1ff7c64ebf2daa79ca79.jpg',
            alt: 'alt',
            descriptionImg : 'Οι Φυλακές της Κατοικίας του Ρέκτορα'
          })
    
          arrayImages.push({
            id: '5459',
            url: 'https://rethymno-admin.repository.gr/files/asset/808f7c31422c27d6b547cefbf5daeb8a70518756.jpg',
            alt: 'alt',
            descriptionImg : 'Οι Φυλακές της Κατοικίας του Ρέκτορα'
          })
        }

        if (data['o:id']== 4605) {   
                  
          arrayImages.push({
            id: '5425',
            url: 'https://rethymno-admin.repository.gr/files/asset/5470266221c01e694c959ebe980878ff9c841db1.jpg',
            alt: 'alt',
            descriptionImg : 'Φρούριο Φορτέτζα'
          })
          
          arrayImages.push({
            id: '5427',
            url: 'https://rethymno-admin.repository.gr/files/asset/884f4c3004b02842b9859272274f1f17b5df2e41.jpg',
            alt: 'alt',
            descriptionImg : 'Φρούριο Φορτέτζα'
          })
          
          arrayImages.push({
            id: '5431',
            url: 'https://rethymno-admin.repository.gr/files/asset/5470266221c01e694c959ebe980878ff9c841db1.jpg',
            alt: 'alt',
            descriptionImg : 'Φρούριο Φορτέτζα'
          })
          arrayImages.push({
            id: '5422',
            url: 'https://rethymno-admin.repository.gr/files/asset/a8bb74ed645871a2529198f42dc96c2a4a12caf6.jpg',
            alt: 'alt',
            descriptionImg : 'Φρούριο Φορτέτζα'
          })
    
          arrayImages.push({
            id: '5421',
            url: 'https://rethymno-admin.repository.gr/files/asset/d02ef35e5c48408e85f68e9f907236874b539002.jpg',
            alt: 'alt',
            descriptionImg : 'Φρούριο Φορτέτζα'
          })
          arrayImages.push({
            id: '5430',
            url: 'https://rethymno-admin.repository.gr/files/asset/9ef480f2391ddf5758a9d3965d4c82c4966d0f7a.jpg',
            alt: 'alt',
            descriptionImg : 'Φρούριο Φορτέτζα'
          })
        }
        if (data['o:id']== 4603) {   
                  
          arrayImages.push({
            id: '5434',
            url: 'https://rethymno-admin.repository.gr/files/asset/fb8b613d3b00d0669d931867ae6d499159303306.jpg',
            alt: 'alt',
            descriptionImg : 'Ναός Αγίας Σοφίας'
          })
          
          arrayImages.push({
            id: '5435',
            url: 'https://rethymno-admin.repository.gr/files/asset/e5f83c4aa71430f5c9a78903b32e2ce792f7bd0b.jpg',
            alt: 'alt',
            descriptionImg : 'Ναός Αγίας Σοφίας'
          })
          
          arrayImages.push({
            id: '5436',
            url: 'https://rethymno-admin.repository.gr/files/asset/93a669c5f9dfc80714a5c626bcf3987d36d51f8b.jpg',
            alt: 'alt',
            descriptionImg : 'Ναός Αγίας Σοφίας'
          })
          
        }


      })()}


      setPoint({
        id: data['o:id'],
        title: data['o:title'],
        subject: data['o:title'],
        type: '',
        description: data['dcterms:description'][0]['@value'],
        mobileDescription: data['dotportal:mobileDescription'][0]['@value'],
        thumbnail: data['thumbnail_display_urls']['large'],
        longitude: data['dotsoft:longtitude'][0]['@value'],
        lantitude: data['dotsoft:lantitude'][0]['@value'],
        images: arrayImages,
        images3d: [],
        videos: arrayVideos,
        bibliographic:data['dcterms:bibliographicCitation'][0]['@value']
      })

    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Point }
}


export const usePointsTimeline = () => {

  const [PointsTimelineTitle, setPointsTimelineTitle] = useState<TimelineTitle[]>([]);
  const [PointsTimeline, setPointsTimeline] = useState<Timeline[]>([]);
  const [allArrays, setAllArrays] = useState<any[]>([]);

  const { isLoading, isFetching, error } = useQuery(['pointsTimeline'], () => axiosPoints.getPoints(), {

    onSuccess: (data) => {

      let arrayPointsTimelineTitle: TimelineTitle[] = [
        // { title: 'Γεωμετρική περίοδος, 1050 - 700 π.Χ.' },
        { title: 'Αρχαϊκή περίοδος, 700 - 480 π.Χ.' },
        { title: 'Κλασική περίοδος, 480 - 323 π.Χ.' },
        { title: 'Ελληνιστική περίοδος, 323 - 31 π.Χ.' },
        { title: 'Ρωμαϊκή αυτοκρατορική περίοδος, 31 π.Χ. - 324 μ.Χ.' },
        { title: 'Βυζαντινή περίοδος, 324 - 1309 μ.Χ.' },
        { title: 'Ιπποτική μεσαιωνική περίοδος, 1309 - 1522 μ.Χ.' },
        { title: 'Οθωμανική περίοδος, 1522 - 1912 μ.Χ.'}
      ]

      let arrayGeo: any[] = []
      let arrayArx: any[] = []
      let arrayKla: any[] = []
      let arrayEll: any[] = []
      let arrayRom: any[] = []
      let arrayViz: any[] = []
      let arrayYpp: any[] = []
      let arrayOfo: any[] = []
      let arrayAll: any[] = []
      
      let arrayPointsTimeline: Timeline[] = []
      let arrayImages:any = []
      let id: number = 0
      let title: string = ''
      let thumbnail: string = ''
      let type: string = ''
      let longtitude: string = ''
      let lantitude: string = ''
      let date: string = ''
      let description: string = ''


      data.map((points: any) => {

        for (var key of Object.keys(points)) {

          if (key == 'dcterms:date') {

            for (var dates of Object.keys(points[key])) {
             
              switch (points[key][dates]['@value']) {
                
                case 'Γεωμετρική περίοδος, 1050 - 700 π.Χ.':
                  setArray(points[key][dates]['@value'], points, arrayGeo); break;
                
                case 'Αρχαϊκή περίοδος, 700 - 480 π.Χ.':
                  setArray(points[key][dates]['@value'], points, arrayArx); break;
                
                case 'Κλασική περίοδος, 480 - 323 π.Χ.':
                  setArray(points[key][dates]['@value'], points, arrayKla); break;
                
                case 'Ελληνιστική περίοδος, 323 - 31 π.Χ.':
                  setArray(points[key][dates]['@value'], points, arrayEll); break;
                
                case 'Ρωμαϊκή αυτοκρατορική περίοδος, 31 π.Χ. - 324 μ.Χ.':
                  setArray(points[key][dates]['@value'], points, arrayRom); break;
                
                case 'Βυζαντινή περίοδος, 324 - 1309 μ.Χ.':
                  setArray(points[key][dates]['@value'], points, arrayViz); break;
                
                case 'Ιπποτική μεσαιωνική περίοδος, 1309 - 1522 μ.Χ.':
                  setArray(points[key][dates]['@value'], points, arrayYpp); break;
                
                case 'Οθωμανική περίοδος, 1522 - 1912 μ.Χ.':
                  setArray(points[key][dates]['@value'], points, arrayOfo); break;

              }
            }
          }

          switch (key) {
            case 'o:id':                   id = points[key]; break;
            case 'o:title':                title = points[key]; break;
            case 'thumbnail_display_urls': thumbnail = points[key]['large']; break;
            case 'dcterms:subject':        type = points[key][0]['@value']; break;
            case 'dotsoft:longtitude':     longtitude = points[key][0]['@value']; break;
            case 'dotsoft:lantitude':      lantitude = points[key][0]['@value']; break;
            case 'dcterms:date':           date = points[key][0]['@value']; break;
            case 'dotportal:mobileDescription': description = points[key][0]['@value']; break;
          }
        }

        
        arrayImages.push({
          source: {url : thumbnail },
          type: "IMAGE"
        })
       // arrayPointsTimelineTitle.push({ title: date })
        arrayPointsTimeline.push({
          id: id,
          title: date,
          cardTitle: title, 
          media: {
            name: title,
            source: { url: thumbnail },
            type: "IMAGE",
          },
          cardDetailedText: description,
        })
      })


      arrayAll.push([
        // { id: 'Timeline_1', title: 'Γεωμετρική περίοδος, 1050 - 700 π.Χ.', data: arrayGeo },
        { id: 'Timeline_2', title: 'Αρχαϊκή περίοδος, 700 - 480 π.Χ.', data: arrayArx },
        { id: 'Timeline_3', title: 'Κλασική περίοδος, 480 - 323 π.Χ.', data: arrayKla },
        { id: 'Timeline_4', title: 'Ελληνιστική περίοδος, 323 - 31 π.Χ.', data: arrayEll },
        { id: 'Timeline_5', title: 'Ρωμαϊκή αυτοκρατορική περίοδος, 31 π.Χ. - 324 μ.Χ.', data: arrayRom },
        { id: 'Timeline_6', title: 'Βυζαντινή περίοδος, 324 - 1309 μ.Χ.', data: arrayViz },
        { id: 'Timeline_7', title: 'Ιπποτική μεσαιωνική περίοδος, 1309 - 1522 μ.Χ.', data: arrayYpp },
        { id: 'Timeline_8', title: 'Οθωμανική περίοδος, 1522 - 1912 μ.Χ.', data: arrayOfo },
      ])

      setAllArrays(arrayAll)
      setPointsTimelineTitle(arrayPointsTimelineTitle)
      setPointsTimeline(arrayPointsTimeline)

    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, PointsTimelineTitle, PointsTimeline, allArrays }
}


function setArray(title: string, array: any, setArray:any) {
  
    let Id: number = 0
    let Cardtitle: string = ''
    let Thumbnail: string = ''
    let Type: string = ''
    let Longtitude: string = ''
    let Lantitude: string = ''
    let Description: string = ''
    let Images: string = ''
    let arrayImages: any[] = []
  
    
    for (var item of Object.keys(array)) { 
      switch (item) {
        case 'o:id': Id = array[item]; break;
        case 'o:title': Cardtitle = array[item]; break;
        case 'thumbnail_display_urls': Thumbnail = array[item]['large']; break;
        case 'dcterms:subject': Type = array[item][0]['@value']; break;
        case 'dotsoft:longtitude': Longtitude = array[item][0]['@value']; break;
        case 'dotsoft:lantitude': Lantitude = array[item][0]['@value']; break;
        case 'dotportal:mobileDescription': Description = array[item][0]['@value']; break;
        case 'mobilemedia:lowResImages': Images = array[item][0]['@value']; break;
      }
    }
  
    let images = Images.split('\n') 
    for (let i in images) {
      arrayImages.push({
        id: 'image_' + (parseInt(i)+1),
        url: images[i],
        alt: Cardtitle+' '+(parseInt(i)+1)
      })
    }

    setArray.push({
      id: Id ,
      title: title,
      cardTitle: Cardtitle , 
      media: {
        name: Cardtitle ,
        source: { url: Thumbnail  },
        type: "IMAGE",
      },
      cardDetailedText: Description,
      Images: arrayImages
    })

}

export const useCategories = () => {
  
  
  const [Categories, setCategories] = useState<CategoriesProps[]>([]);
  let arrayCategories: CategoriesProps[] = []
  
  arrayCategories.push(
    { id: 11, name: 'Ενετικό Κάστρο Φορτέτζα m', count: 1, imgBig: 'https://rethymno-admin.repository.gr/files/asset/73e327d7c1d6695f38222c4c87cc8ab15e838590.jpg', imgSmall: 'img/castle.png', alt: 'photo1' },
    { id: 2, name: 'Τα μουσεία του Ρεθύμνου lala', count: 2, imgBig: 'https://rethymno-admin.repository.gr/files/asset/16dd9f47a8edf48448806b82f72b48d26ac3ca8e.jpg', imgSmall: 'img/cat2.png', alt: 'photo2' },
    { id: 3, name: 'Τα τζαμιά του Ρεθύμνου lolo', count:3,  imgBig: 'https://rethymno-admin.repository.gr/files/asset/3fb7097d1dfdea9370fe3076daffae75fe1632f2.jpg', imgSmall: 'img/cat3.png', alt: 'photo3' },
    { id: 3, name: 'Οι κρήνες του Ρεθύμνου lolos', count:4,  imgBig: 'https://rethymno-admin.repository.gr/files/asset/c76a2a47825326304835ffb372cff46df90005fd.jpg', imgSmall: 'img/cat4.png', alt: 'photo3' })

  setCategories(arrayCategories)
  return { Categories}
}