import React from 'react'
import ReactDOM from 'react-dom/client'
import { Styles } from "./styles/styles"
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import ScrollToTop from '../src/common/ScrollToTop'
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { LanguageContextProvider } from "./store/language-context";
import Cookies from "./components/cookies/Cookies";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Header from "./components/Header"
import Footer from "./components/Footer"
import Home from './pages/Home/Home'
import Point from './pages/Point/Point'
import SingleCategory from './pages/SingleCategory/SingleCategory'
import Map from './pages/Map/Map'
import Media from './pages/Media/Media'

import RoutesMap from './pages/Routes/RoutesMap'
import RoutesMaps from './pages/RoutesMap/MapRoutes'

import SingleRoute from './pages/SingleRoutes/SingleRoute'
import Events from './pages/Events/Events'
import CommonPage2 from './pages/CommonPage/CommonPage2'
import CommonPage1 from './pages/CommonPage/CommonPage1'
import CommonPage3 from './pages/CommonPage/CommonPage3'
import Categories from './pages/Categories/Categories'

import PageNotFound from './pages/404'
import SingleEvent from './pages/SingleEvent/SingleEvent'
import Videos from './pages/Videos/Videos'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
})

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: [
      "en",
      "el",
      "fr",
      "de",
      "it"
    ],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie"],
      caches: ["cookie"],
      cookieMinutes: 7 * 24 * 60 * 60 * 1000, //7 days
    },
    react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  })
  .catch((error) => console.warn(error));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <LanguageContextProvider>
      <Cookies />
        <Styles />
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route index element={<Home />} />
          <Route path="rethymno/:id" element={<Point />} />
          <Route path="category/:id" element={<SingleCategory />} />
          <Route path="map" element={<Map />} />
          <Route path="events" element={<Events />} />
          <Route path="events/:id" element={<SingleEvent />} />
          <Route path="model3d" element={<Media />} />
          <Route path="page/privacy-policy" element={<CommonPage1 />} />
          <Route path="page/gdpr" element={<CommonPage2 />} />
          <Route path="page/terms" element={<CommonPage3 />} />
          <Route path="categories" element={<Categories />} />
          <Route path="videos" element={<Videos />} />
          <Route path="routes" element={<RoutesMap />} />
          <Route path="routes_map" element={<RoutesMaps />} />
          <Route path="route/:id" element={<SingleRoute />} />
          <Route path="360/1.html" element={<Navigate to=".../360/1.html" />} />
          <Route path="360/2.html" element={<Navigate to=".../360/2.html" />} />
          <Route path="360/3.html" element={<Navigate to=".../360/3.html" />} />
          <Route path="360/4.html" element={<Navigate to=".../360/4.html" />} />
          <Route path="360/5.html" element={<Navigate to=".../360/5.html" />} />
          <Route path="360/6.html" element={<Navigate to=".../360/6.html" />} />
          <Route path="360/7.html" element={<Navigate to=".../360/7.html" />} />
          <Route path="360/8.html" element={<Navigate to=".../360/8.html" />} />
          <Route path="360/9.html" element={<Navigate to=".../360/9.html" />} />
          <Route path="360/10.html" element={<Navigate to=".../360/10.html" />} />
          <Route path="*" element={<PageNotFound />} />
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
        <Footer />
      </LanguageContextProvider>
    </BrowserRouter>
  </QueryClientProvider>
)