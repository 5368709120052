import React, { useState, useEffect } from "react"
import { Row, Col, Select, Pagination } from 'antd'
import type { PaginationProps } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next"
import { useEventsMap } from 'hooks/useEvents'
import Card from './CardComponent'
import { GeneralProps } from "services/props"
import utils from 'utils'
import classes from "./styles.module.css"
import { useLocation, Link } from "react-router-dom"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'
import { SampleNextArrow, SamplePrevArrow } from 'common/ArrowsForCarousel'


const { Option } = Select

const Events = () => {

  const { t } = useTranslation()

  const { isLoading, error, Events } = useEventsMap()

  const location = useLocation()
  const [count, setCount] = useState<number>(1);
  const [listData, setListData] = useState<GeneralProps[]>(Events);

  const [type, setType] = useState<string>('Τύπος');
  const [month, setMonth] = useState<string>('Μήνας');
  const [current, setCurrent] = useState<string>(location?.state?.day ? location?.state?.day : '40');
  const [page, setPage] = useState(0);

  const bgimg = {
    backgroundImage: `url("/img/P1002546.jpg")`,
    height: '',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: '-130px',
    backgroundPosition: '25% 35%'
  };


  useEffect(() => {

    if (current != '40') {
      let data = utils.filterEventsArray(Events, type, month, current)
      console.log("data", data);

      setListData(data)
      setCount(data.length)
    }
    else {
      setCount(Events.length)
      setListData(Events)
    }
  }, [Events])



  const handleChange = (key: string, value: string): void => {

    switch (key) {
      case 'type':
        let datas = utils.filterEventsArray(Events, type, month, current)
        setListData(datas)
        setType(value)
        break;

      case 'month':
        setMonth(value);
        break;

      default:
        setCurrent(value)
    }
  };

  const handleChangeTime = (value: string): void => setCurrent(value)

  const onChange: PaginationProps['onChange'] = (page) => {
    console.log(page);
    setPage(page);
  };

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return <SampleNextArrow />;
    }
    if (type === 'next') {
      return <SamplePrevArrow />;
    }
    return originalElement;
  }
  useEffect(() => {

    let data = utils.filterEventsArray(Events, type, month, current)
    setCount(data.length)
    if (type === "Τύπος" && month === "Μήνας" && current === "40") {
      setListData(Events)
    }
    else {
      setListData(data)
    }
  }, [type, month, current])

  console.log(listData);


  if (isLoading) { return <Spinner /> }

  return (
    <>
      <HelmetComponent title={t('SEO Events title')} descrition={t('SEO Events description')} />

      <Row justify="end">
        <div style={bgimg}>

          <div className={classes.top_banner_txt}>
            <div>

              <span>{t('Συμμετάσχετε στις εκδηλώσεις μας, ελάτε να γνωριστούμε')}</span>
            </div>
          </div>

        </div>
      </Row>

      <Row justify="center">
        <Col span={12}> <h1 className={classes.calendarContainer__container} >{t("")} </h1></Col>
      </Row>

      <Row className={classes.filters}>

        <Col xs={4} sm={4} lg={3} xl={3}>

          <Select
            placeholder={t("EventsAll")}
            className={classes.type}
            size={'large'}
            placement="bottomLeft"
            showArrow={false}
            defaultValue={'Τύπος'}
            autoClearSearchValue={false}
            onChange={(e) => handleChange("type", e)} >

            <Option value="Τύπος" className={'ant-option'}>{t("Τύπος")} <RightOutlined /></Option>
            <Option value="Κινηματογράφος" className={'ant-option'}>Κινηματογράφος</Option>
            <Option value="Εργαστήριο" className={'ant-option'}>Εργαστήριο</Option>
            <Option value="Εκδηλώσεις" className={'ant-option'}>Εκδηλώσεις</Option>
            <Option value="Συναυλίες" className={'ant-option'}>Συναυλίες</Option>
            <Option value="Χορός" className={'ant-option'}>Χορός</Option>
            <Option value="Έκθεση" className={'ant-option'}>Έκθεση</Option>
            <Option value="Θέατρο" className={'ant-option'}>Θέατρο</Option>
            <Option value="Ρεσιτάλ" className={'ant-option'}>Ρεσιτάλ</Option>
            <Option value="Συνέδριο" className={'ant-option'}>Συνέδριο</Option>
          </Select>
        </Col>

        <Col xs={4} sm={4} lg={3} xl={3}>
          <Select
            placeholder={t("Μήνας")}
            className={classes.month}
            size={'large'}
            defaultValue={'Μήνας'}
            showArrow={false}
            autoClearSearchValue={false}
            onChange={(e) => handleChange("month", e)} >

            <Option value="Μήνας" className={'ant-option'}>{t("Μήνας")} <RightOutlined /></Option>
            <Option value="Jan" className={'ant-option'}>{t("Ιανουάριος")}</Option>
            <Option value="Feb" className={'ant-option'}>{t("Φεβρουάριος")}</Option>
            <Option value="Mar" className={'ant-option'}>{t("Μάρτιος")}</Option>
            <Option value="Apr" className={'ant-option'}>{t("Απρίλιος")}</Option>
            <Option value="May" className={'ant-option'}>{t("Μάιος")}</Option>
            <Option value="Jun" className={'ant-option'}>{t("Ιούνιος")}</Option>
            <Option value="Jul" className={'ant-option'}>{t("Ιούλιος")}</Option>
            <Option value="Aug" className={'ant-option'}>{t("Αύγουστος")}</Option>
            <Option value="Sep" className={'ant-option'}>{t("Σεπτέμβριος")}</Option>
            <Option value="Oct" className={'ant-option'}>{t("Οκτώβριος")}</Option>
            <Option value="Nov" className={'ant-option'}>{t("Νοέμβριος")}</Option>
            <Option value="Dec" className={'ant-option'}>{t("Δεκέμβριος")}</Option>
          </Select>
        </Col>
        {/* <Select
            placeholder={t("Current")}
            style={{ width: '30%', padding: '4px', color: '#009664' }}
            className={'calendar'}
            size={'large'}
            allowClear={true}
            showArrow={false}
            autoClearSearchValue={false}
            onChange={(e) => handleChange("current", e)} >
            <Option value="cur">{t("Current")}</Option>
            <Option value="att">{t("Attention")}</Option>
            <Option value="old">{t("OlderOnes")}</Option>
          </Select> */}
        <Col xs={4} sm={4} lg={3} xl={3}>

          <Select
            placeholder={t("Ταξινόμηση")}
            className={classes.days}
            size={'large'}
            showArrow={false}
            defaultValue={location?.state?.day ? location?.state?.day : '40'}
            onChange={handleChangeTime} >

            <Option value="40">{t('Ταξινόμηση')}<RightOutlined /></Option>
            <Option value="1">{t('Today')}</Option>
            <Option value="2">{t('Αύριο')}</Option>
            <Option value="7">{t('In a week')}</Option>
            <Option value="30">{t('In a month')}</Option>
          </Select>

        </Col>
      </Row>
      <br />



      {/* slice(page * 3, 3 * page + 3). */}
      {listData && listData.slice(page * 3, 3 * page + 3).map((event: { [index: string]: any }, index: number) => {
        console.log(event);

        return (
          <Row >
            {/* <Link to={`../events/${event.id}`}> */}

            <Card
              id_item={event.id_item}
              styleImage={true}
              type={event.category_type}
              image={event.image}
              event={event.title}
              description={event.description}
              municipality={event.municipality}
              location={event.venues[0].title}
              start={event.time_periods[0].starting_date}
              end={event.time_periods[0].ending_date}
              t1={
                event.price.length > 0
                  ?
                  event.price.map((prices: any) => prices.price != 0 && prices.price ? `${prices.description + ' ' + prices.price} € \n` : t("Είσοδος ελεύθερη"))
                  :
                  t("Είσοδος ελεύθερη")
              }
            />
          </Row>
        )
      }

      )
      }
      {/* <h5>{count} {count && count < 2 ? `${t("Αποτέλεσμα")}` : `${t("Αποτελέσματα")}`}  </h5> */}
      {/* <Pagination current={page} onChange={onChange} total={count} pageSize={3} pageSizeOptions={[3]}
        showTotal={(count) => `Αποτελέσματα: ${page} - ${page} από ${count}`}
        
      /> */}
      <Row className={classes.filters}>
        <Pagination className={classes.pagination} itemRender={itemRender} defaultCurrent={1} onChange={onChange} total={count} showTotal={(count) => `Αποτελέσματα: ${count / (page + 1)} από ${count}`} pageSize={3} pageSizeOptions={[3]} />

      </Row>
    </>
  )
}

export default Events