import { ArrowLeftOutlined , ArrowRightOutlined  } from '@ant-design/icons'

export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        color: '#D55E2D',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '35px',
        marginRight: '80px',
        left: "90px",
        top: "102%",
        width: "35px",
        height: "35px",
        borderRadius: '50%',
        background: '#F9F2E6'
      }}
      onClick={onClick}
    >
      <span style={{marginLeft: '8px'}}><ArrowRightOutlined /></span> 
    </div>
  )
}



export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        color: '#D55E2D',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '35px',
        top: "102%",
        left: "20px",
        width: "35px",
        height: "35px",
        borderRadius: '50%',
        background: '#F9F2E6'
      }}
      onClick={onClick}
    >
      <span style={{marginLeft: '8px'}}><ArrowLeftOutlined /></span>  
    </div>
  )
}