import { Fragment, useEffect, useState, useRef } from "react";
import cookies from "js-cookie";
import classes from "./Cookies.module.css";

let timeoutListener = null;
const Cookies = () => {
  const [cookiesAllow, setCookiesAllow] = useState(
    cookies.get("cookies-allow")
  );

  const [cookiesModalAnim, setCookiesModalAnim] = useState(true);

  useEffect(() => {
    return () => clearTimeout(timeoutListener);
  }, []);

  const cookieModalHandler = () => {
    cookies.set("cookies-allow", true, { expires: 365 * 2 });
    timeoutListener = setTimeout(() => setCookiesAllow(false), 1000);
    setCookiesModalAnim(false);
  };

  return (
    <Fragment>
      {cookiesAllow === undefined && (
        <Fragment>
          <div
            className={classes.cookies_backdrop}
            style={
              cookiesModalAnim === true
                ? { display: "block" }
                : { display: "none" }
            }
          />
          <div
            className={
              cookiesModalAnim === true
                ? [classes.cookies_container, classes.expand_cookies].join(" ")
                : classes.cookies_container
            }
          >
            {document.getElementsByTagName("title")[0].text
              ? document.getElementsByTagName("title")[0].text
              : "This website"}{" "}
            uses cookies to enhance your online experience. By using this
            website you agree to our Cookie Policy.
            <button
              type="button"
              onClick={cookieModalHandler}
              className={classes.accept_cookies}
            >
              Accept Cookies
            </button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Cookies;
