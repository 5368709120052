import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { axiosSearch } from "../services/search";

export const useSearch = (lang: string, key: string) => {
  const [Results, setResults] = useState<any[]>([]); // Replace 'any' with the actual type of your data

  const { isLoading, isFetching, error } = useQuery(
    ["pois", lang, key],
    () => axiosSearch.getSearch(lang, key),
    {
      onSuccess: (data) => {
        // Check if data is defined before setting the state
        if (data) {
          setResults(data);
        } else {
          setResults([]); // Set an empty array as the initial state
        }
      },
      onError: (error: any) => {
        alert(error.message);
      },
    }
  );

  return { isLoading, isFetching, error, Results };
};
