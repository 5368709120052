import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`

  padding: 0.1rem 0.5rem;

  background: rgba(255, 255, 255, 0.5);
  position:relative;
  width: 100%;
  height: 122px;
  backdrop-filter: blur(4px);
  z-index:1040;
  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
  
  div{
    // z-index:9999 !important;
    // position:relative;
  }
  // ul{
  //   background:red;
  // }
  a.lang1{
    color:black!important;
    
    font-size:15px;
  }
  a.lang1:hover{
    color: #D55E2D!important;
  }
  .lang2{
    top:8px;
    position:relative,
  }
  }

  span nav a, .anticon {
    color:#000000;
    
font-style: normal;
font-weight: 600;
font-size: 1em;
line-height: 27px;
  } 
  span nav a:hover, .anticon:hover {
    color:#D55E2D;
  } 
  a, option,input,select,.ant-dropdown-menu-title-content{
    z-index:999999!important;
  }
  .search span {
    font-size: 1.5rem;
    margin-left: 6rem;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1328px) {
    // #home{
    //   flex-wrap: nowrap;
    // }
    .search {
      margin-left: 3rem;
    }
    
  }
  @media only screen and (min-width: 1329px){
    .search {
      margin-left: 7vw;
    }
  }
  @media only screen and (min-width: 1650px){
    .search {
      margin-left: 15vw;
    }
  }
  
`;

export const LogoContainer = styled(Link)`
    display: flex;
    color: #000000;
    
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 27px;
 `;


export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;
  @media only screen and (max-width: 411px) {
    width: 150px;
  }
  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div") <any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 1280px) {
    display: block;
  }
  display: none;
  svg {
    fill: fff;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 1280px) {
    display: none;
  }
`;

export const Menus = styled("h5")`
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1rem;
  color: #0f0f0f;
  transition: color 0.1s ease-in;
  margin: 0.5rem 1.1vw;
  z-index:9999999;
  a {margin: 0rem}

  @media only screen and (max-width: 1280px) {
    // margin: 1.25rem 6rem;
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin: 40px 0px 40px 60px;
    width: 99%;
    border-bottom: 1px solid;
    padding-bottom: 1rem;
    text-align: left;

}
    font-weight: bold;
    a {
      color: #0f0f0f !important;
    }
  }
`;

export const CustomNavLinkSmallMap = styled(NavLink)`
  font-size: 1.4rem;
  color: #0f0f0f;
  transition: color 0.1s ease-in;
  margin: 0.5rem 2.8rem;
 
  margin-left: 160px;
  margin-right: -100px;
  a {margin: 0rem;}

  @media only screen and (max-width: 768px) {
    margin: 2rem;
    a {color: #0f0f0f !important; }
  }
`;

export const CustomNavLinkSmallSearch = styled(NavLink)`
  font-size: 1rem;
  color: #0f0f0f;
  transition: color 0.1s ease-in;
  margin: 0.2rem 2.8rem;


  margin-right: 0px;
  a {
    margin: 0rem;
    color: #0f0f0f !important;
  }

  @media screen and (max-width: 1280px) {
    margin-left: -60px;
    font-size: 1.3rem;
  }
`;

export const CustomNavLinkSmallLanguage = styled(NavLink)`
  font-size: 1rem;
  color: #0f0f0f;
  transition: color 0.1s ease-in;
  margin: 0.5rem 0.8rem;
  
  position:relative;
  // z-index:99999999;
  a {margin: 0rem;}

  div {
    z-index: 9999999999 !important;
  }

  @media only screen and (max-width: 1280px) {
    margin: 0rem;
    a {color: #0f0f0f !important; }
    margin-left: 10px;
  }
`;
export const CustomNavLinkSocial = styled(NavLink)`
 visibility: hidden;
  @media only screen and (max-width: 1280px) {
    
    a {
      color: #0f0f0f !important; 
      margin: 0rem;
    }
    font-size: 1rem;
    color: #0f0f0f;
    transition: color 0.1s ease-in;
    margin: 0.5rem 0 0.5rem 0px !important;
    margin-left: 110px;
    position:relative;
    z-index:99999999;
    visibility: visible;
  }
`;



export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  z-index:9999999;
`;

export const Outline = styled(MenuOutlined) <any>`
  font-size: 30px;
  text-align: center;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;



export const GeneralCover = styled('div')`
  height: 60px;
  display: inline-block;
  text-align: center;
  margin-top: 10px; 
  z-index:9999999;
position:relative;

@media only screen and (max-width: 690px) {
  margin-top: 0px; 
}
`;
