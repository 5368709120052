import React, { useState, useEffect, useRef, Suspense } from "react";
import { Canvas, useLoader, useFrame, useThree } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Spinner from "common/Spinner";

export default function ModelViewer({ modelPath, scale }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleModelLoad = () => {
    setIsLoading(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {isLoading && (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Spinner />
        </div>
      )}
      <Canvas>
        <ambientLight intensity={1} />
        <spotLight position={[20, 15, 10]} angle={0.5} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <Suspense fallback={null}>
          <GltfModel modelPath={modelPath} scale={scale} onModelLoad={handleModelLoad} />
        </Suspense>
        <OrbitControls target={[50, 20, 0]} />
      </Canvas>
    </div>
  );
}

const GltfModel = ({ modelPath, scale, onModelLoad }) => {
  const ref = useRef();
  const gltf = useLoader(GLTFLoader, modelPath);

  useFrame(() => (ref.current.rotation.y += 0.0002));

  useThree(({ camera }) => {
    camera.position.x = 120;
    camera.position.y = 60;
    camera.lookAt(0, 0, 0);
  });

  useEffect(() => {
    onModelLoad(); // Notify the parent component that the model has loaded
  }, [onModelLoad]);

  return <primitive ref={ref} object={gltf.scene} scale={scale} />;
};