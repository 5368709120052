import React,{useState,useEffect,useContext} from "react"
import { Row, Col, Card, Button } from 'antd'
import classes from './styles.module.css'
import { usePoint } from 'hooks/useMap'
import { useParams, Link } from 'react-router-dom'
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'
import { SampleNextArrow, SamplePrevArrow } from 'common/ArrowsForCarousel'
import cookies from "js-cookie";
import i18next from "i18next"
import LanguageContext from "../../store/language-context"
import { useTranslation } from "react-i18next"
import { Carousel } from 'antd';


const SingleRoute: React.FC = () => {
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation()
  var currentLanguageCode = cookies.get("i18next") || "el";
  const languageCtx = useContext(LanguageContext);

  const {id} = useParams()
  const [category, setCategories] = useState();
  const [name, setName] = useState();
  const [img, setImg] = useState();
  const [routesPoint, setRoutesPois] = useState([]);

  // Function to collect data
  const pathname = window.location.pathname;
  const activecategory = pathname.split("/").pop(); 

  const getPoints = async () => {
    const url = `https://rethymno.mitos.cityguideplatform.com/api/route?id=${activecategory}&lang=${currentLanguageCode}`
    const response = await fetch(url, 
      {signal, 
      headers: {
       'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
       'Content-Type': 'application/json',
       'Accept': 'application/json'}
       })
       if (!response.ok) {
        throw new Error('Data could not be fetched!')
      } 
    else {
        return response.json()
    }
  };

  useEffect(() => {
    
    getPoints()
      .then((res) => {
        setCategories(res.success)
        setRoutesPois(res.success.pois)
        setName(res.success.name)
        setImg(res.success.pois[0].main_image)
      })
     
      .catch((e) => {
        console.log(e.message)
      })
  },[i18next.language])
 
  return (
    <>
      {/* <HelmetComponent title='lll' descrition={ `${Point.title} ${t("στην")} ${Point.subject}` } image={Point.thumbnail} /> */}
      <div className={classes.dummy}>
        
      </div>
      <Row>
        <div className={classes.gallerycat}>
        <div className="app11">
        <div className={classes.gallery}>
      
                <><Row>
              <div className={classes.top_banner}>    
              <Col xs={24} sm={12} lg={12}>
                  <div className={classes.top_banner_img}>
                    <img src={img} alt="category['name']"/>
                  </div>
                </Col>
                 <Col xs={24} sm={12} lg={12}>
                 <div className={classes.top_banner_txt}>
                    <p className={classes.top_banner_txt1}>
                    {t("Προτεινόμενη Διαδρομή")}
                    </p>
                    <h2>{name}</h2>
                    <h2><a href={`/routes_map?route=${activecategory}`}>{t("Δες τον Χάρτη")}</a></h2>
                  </div>
                </Col> 
                
              </div>
            </Row>
            <Row justify="end">
        <Col xs={24} sm={24} lg={24}>
          <div className={classes.divider}>
            <div className={classes.dividerBlock}></div>
          </div>
        </Col>
      </Row> 
      <Row justify="start">
      <Col xs={24} sm={24} lg={24}>
      <h2> {t("Σημεία Διαδρομής")}</h2>

      <div className="app11">
           {routesPoint.map(p => 
           <Link to={`/rethymno/${p['id']}`} state={{ point: p['name'] }}>
          <div className={classes.categoriesimg }>
             <img src={p['main_image']} />
             <span><b>{ p['name'] }</b></span>
          </div>
          </Link>
          )}
        </div>

      </Col>
      </Row>
          </>
          </div>
        </div>
        <br/>
        <br/>
        </div>
      </Row>     
    </>
   
  )
}

export default SingleRoute