import { Suspense, lazy, useState } from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'
import { useEventsMap } from 'hooks/useEvents'
import { Link } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import YouTube from 'react-youtube';


const PointsCarousel = lazy(() => import('pages/Home/components/PointsCarousel'))
const CategoriesCarousel = lazy(() => import('pages/Home/components/CategoriesCarousel'))
const Applications = lazy(() => import('pages/Home/components/Applications'))
const Routes = lazy(() => import('pages/Home/components/Routes'))
const TimeLineHome = lazy(() => import('pages/Home/components/TimeLineHome'))
const MobileApp = lazy(() => import('pages/Home/components/MobileApp'))
const Explore = lazy(() => import('pages/Home/components/Explore'))
const DigitalExp = lazy(() => import('pages/Home/components/DigitalExp'))
const DigitalQuestBook = lazy(() => import('pages/Home/components/DigitalQuestBook'))
const Events = lazy(() => import('pages/Home/components/Events'))
const EventsList = lazy(() => import('pages/Home/components/EventsList'))
const EventsCarousel = lazy(() => import('pages/Home/components/EventsCarousel'))

declare global {
  interface Window {
    YT: any;
  }
}
const Home = () => {

  const { t } = useTranslation()
  const playerRef = useRef<YouTube | null>(null);



  const bgimg = {
    backgroundImage: `url("/img/rethymno.jpg")`,
    height: '650px',
    width: '100%',
    backgroundSize: '100% 100%', // Updated property
    backgroundRepeat: 'no-repeat',
    marginTop: '190px',
    backgroundPosition: '25% 0%'
  };

  useEffect(() => {
    const player = playerRef.current?.internalPlayer;

    if (player) {
      player.mute();
      player.playVideo();
      player.setOption('controls', 0);

      player.addEventListener('onStateChange', (event: any) => {
        if (event.data === window.YT.PlayerState.ENDED) {
          player.playVideo();
        }
      });
    }
  }, []);

  return (
    <>

      <HelmetComponent title={t('Οδηγός Περιήγησης στο Ρέθυμνο')} descrition={t('Οδηγός Περιήγησης στο Ρέθυμνο')} />

      <Suspense fallback={<Spinner />}>
        <Row justify="end" style={{ position: 'relative' }}>



          <div className={classes.top_banner_txt}>
            <div className={classes.content}>
              <span>{t('Γνώρισε το Ρέθυμνο μέσα από ιστορικό φωτογραφικό υλικό και δες την ιδιαίτερη αρχιτεκτονική του που μένει ζωντανή μέχρι και σήμερα.')}</span>
              <Link to="/categories" state={{ category: "Γνωρίστε το Ρέθυμνο" }}>
                <a className={classes.btn1}><img src='/img/explore.png' />{t("Γνωρίστε το Ρέθυμνο")}</a><div></div>
              </Link>
              <Link to="/categories" state={{ category: "Αρχιτεκτονική" }}>
                <a className={classes.btn2}><img src='/img/arch.png' />{t("Αρχιτεκτονική του Ρεθύμνου")}</a>
              </Link>
            </div>
          </div>


          <div className={classes.bgimg1}>
            <div className={classes.videoWrapper}>
              <YouTube
                videoId="alG31hKibMc"
                opts={{
                  playerVars: {
                    autoplay: 1,
                    controls: 0,
                    modestbranding: 1,
                    mute: 1,
                  },
                }}
                onEnd={() => playerRef.current?.internalPlayer.playVideo()}
                ref={playerRef}
                className={classes.youtubePlayer}
              />
            </div>
          </div>


        </Row>
        {/* <div style={{ float: 'right' }}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=alG31hKibMc&ab_channel=VisitRethymno"
            playing={true}
            controls={true}
            autoplay={true}
            muted={true}
          />
        </div> */}
        <div>

        </div>
        <br /><br /><br />

        <Row>

          <Col xs={24} sm={24} lg={24}>
            {/* <div className={classes.divider2}>
              <div className={classes.dividerBlock}></div>
            </div> */}

            <PointsCarousel />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} lg={24}>
            <div className={classes.divider}>
              <div className={classes.dividerBlock}></div>
            </div>
            <div id="mobile_app">
              <MobileApp /></div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={classes.divider}>
              <div className={classes.dividerBlock}></div>
            </div>
            <div id="mobile_app"> <Explore /></div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} lg={24}>
            <div className={classes.divider}>
              <div className={classes.dividerBlock}></div>
            </div>
            <CategoriesCarousel />
            <div className={classes.divider}>
              <div className={classes.dividerBlock}></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} lg={24}>

            <Routes />

          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} lg={24}>

            <EventsCarousel />

          </Col>
        </Row>
      </Suspense>
    </>
  )
}

export default Home