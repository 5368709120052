import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Footer.module.css"
import SearchClickComponent from "../Search/SearchClickComponent"
import { Icon } from "../../common/Icon"
import { BsFacebook } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import logo from '../../images/logo_gr.jpg';
import logoEN from '../../images/logo_en.jpg';
import yp from '../../images/yp.png';
import espa from '../../images/espa.png';

function Footer() {

  const { t, i18n } = useTranslation()

  return (
    <>
      <Row justify="center">
        <Col xs={24} sm={24} lg={24}>
          <div className={classes.footer_upper}>
            <ul className={classes.footer_menu1}>
              <li><a href="/map">{t("Εξερεύνηση")}</a></li>
              <li><a href="#mobile_app">{t("App")}</a></li>
              <li><a href="/map">{t("Κατηγορίες Σημείων")}</a></li>
              <li><a href="/map">{t("Διαδρομές")}</a></li>
              <li><a href="/events">{t("Εκδηλώσεις")}</a></li>
            </ul>
            <ul className={classes.footer_menu2}>
              {/* <li><a href="#">{t("Terms and conditions")}</a></li> */}
              <li><Link to='page/terms' state={{ page: 'gdpr' }}>{t("Όροι Χρήσης - Προσωπικά Δεδομένα - Πνευματικά Δικαιώματα")}</Link></li>
              {/* <li><Link to='page/privacy-policy' state={{ page: 'privacy-policy' }}>{t("Privacy Policy")}</Link></li>
                <li><Link to='page/gdpr' state={{ page: 'gdpr' }}>{t("Privacy")}</Link></li> */}
            </ul>
          </div>
        </Col>
      </Row>

      <Row justify="center" style={{ backgroundColor: '#F9F2E6' }}>
        <Col xs={24} sm={24} lg={7} xl={7}>
          <Row justify="center">

            <div className={classes.footer_lower_1}>
              <Col xs={24} sm={24} lg={12} xl={12}>
                <Link to="/" className={classes.stylesA} >
                  <img src={logo} width="auto" height="100px" />
                </Link >
              </Col>
              <Col xs={24} sm={24} lg={12} xl={12}>
                <div className={classes.socialSidebar}>
                  <a href="https://www.youtube.com/c/rethymnogronline" target="_blank" >
                    <div className={classes.icon}>
                      <BsYoutube />
                    </div>
                  </a>
                  <a href="https://www.facebook.com/rethymno.gr" target="_blank" >
                    <div className={classes.icon}>
                      <BsFacebook />
                    </div>
                  </a>
                </div>
              </Col>
            </div>
          </Row>
        </Col>

        <Col xs={24} sm={24} lg={5} xl={5}>
          <div className={classes.footer_lower_11}>
            <img src={yp} />
          </div>
        </Col>
        <Col xs={24} sm={24} lg={5} xl={5}>
          <div className={classes.footer_lower_11}>
            <img src={espa} />
          </div>
        </Col>
      </Row>
      <div className={classes.credits} style={{ textAlign: 'center' }}>
        Powered by <a href="https://dotsoft.gr/" target="_blank">Dotsoft</a>
      </div>




    </>
  )
}

export default Footer;