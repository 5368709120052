import axios from 'axios'

const API = 'https://rethymno-admin.repository.gr/api'
const controller = new AbortController();
const { signal } = controller;
export const axiosPoints = {

  async getPoints() {
    
    const url = `https://rethymno.mitos.cityguideplatform.com/api/pois?lang=el`
    const response = await fetch(url, 
      { signal, 
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'}
       })
       if (!response.ok) {
        throw new Error('Data coud not be fetched fff!')
      } 
    else {
        return response.json()
    }
  },

  async getPoint(id: number) {
    
    const url = `https://rethymno.mitos.cityguideplatform.com/api/poi=1_344&lang=el`
    const response = await fetch(url, 
      { signal, 
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'}
       })
       if (!response.ok) {
        throw new Error('Data coud not be fetched nn!')
      } 
    else {
        return response.json()
    }
  },

  async getSource(id: number) {
    const { data } = await axios.get(`${API}/items/${id}`)
    return data
  }

}