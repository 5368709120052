import axios from 'axios';
import ky from 'ky';
import { API_URL, API_KEY, Cors } from './configuration';

axios.defaults.baseURL = API_URL;

export const axiosSearch = {

  async getSearch(language: string, key: string) {
    try {
      // Fetch data from different sources
      // const { data: searchData } = await axios.get(`https://apolithomeno-dev.dotsoft.gr/search?languageCode=${language}&key=${key}`);
      // console.log("Search Data: ", searchData);

      const pois: any = await ky.get(`https://rethymno.mitos.cityguideplatform.com/api/pois?fetch_files=1&search_keyword=${key}&lang=${language === 'gr' ? 'el' : language}`, {
        headers: {
          'X-Authorization': '6AhQEcRa8maJf3NjgdBw'
        }
      }).json();
      console.log("POIs: ", pois);

      const routes: any = await ky.get(`https://rethymno.mitos.cityguideplatform.com/api/routes?lang=${language === 'gr' ? 'el' : language}&search_keyword=${key}`, {
        headers: {
          'X-API-KEY': API_KEY
        }
      }).json();
      console.log("Routes: ", routes);

      const { events }: any = await ky.get(`https://rethymno-events.dotsoft.gr/api/${language === 'gr' ? 'el' : language}/events/search?keywords=${key}`, { headers: Cors }).json()
      console.log("events: ", events);

      // Combine the data into a single array
      const combinedData = [...pois.success,  ...routes.success, ...events ];
      console.log("Combined Data: ", combinedData);

      return combinedData; // Return the combined data as an array
    } catch (error) {
      console.error("Error: ", error);
    }
  }
};