import React, { useEffect } from "react"
import classes from './styles.module.css'

import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import Spinner from 'common/Spinner'
import HelmetComponent from 'components/Helmet'

const CommonPage3: React.FC = () => {

  const { i18n } = useTranslation()
  const location = useLocation()


  // const { isLoading, isFetching, error, data, refetch } = useQuery( ['page'],
  //   async () => {
  //     const { data } = await axios("https://api.chucknorris.io/jokes/random");
  //     return data;
  //   }
  // );

  // console.log('common page')



  return (
    <>
      <HelmetComponent title='Όροι χρήσης και προϋποθέσεις' descrition="SEO Προσωπικά Δεδομένα και Πολιτική Απορρήτου περιγραφή" />

      <div className={classes.container}>
        <h3 className={classes.h3}>Όροι Χρήσης - Προσωπικά Δεδομένα - Πνευματικά Δικαιώματα</h3>
        <div>
          <div className="in">
            <div className="descr"><p style={{ textAlign: 'justify' }}><strong>Όροι Χρήσης</strong></p>
              <p style={{ textAlign: 'justify' }}>Η χρήση της παρούσας Διαδικτυακής Πύλης (εφεξής ΔΠ) υπόκειται στους όρους που παρατίθενται στη συνέχεια. Η χρήση της ΔΠ αποτελεί τεκμήριο ότι ο επισκέπτης - χρήστης έχει μελετήσει, κατανοήσει και αποδεχτεί όλους τους όρους χρήσης της ΔΠ. Σε περίπτωση που ο επισκέπτης - χρήστης δεν συμφωνεί με τους όρους χρήσης της παρούσας, οφείλει να μην κάνει χρήση των υπηρεσιών και του περιεχομένου της ΔΠ.</p>
              <p style={{ textAlign: 'justify' }}>Οι παρόντες όροι χρήσης μπορεί να αναθεωρούνται και να ενημερώνονται οποιαδήποτε στιγμή και χωρίς προειδοποίηση. Παρακαλείσθε να ελέγχετε ανά τακτά χρονικά διαστήματα τους όρους χρήσης της ΔΠ καθώς η συνεχής χρήση της συνεπάγεται ότι αποδέχεστε αυτές τις αλλαγές.</p>
              <p style={{ textAlign: 'justify' }}> </p>
              <p style={{ textAlign: 'justify' }}><strong>Δικαιώματα Πνευματικής Ιδιοκτησίας (Copyright)</strong></p>
              <p style={{ textAlign: 'justify' }}>Όλο το περιεχόμενο της ΔΠ, εκτός των ρητά αναφερόμενων εξαιρέσεων (πνευματικά δικαιώματα τρίτων), που - ενδεικτικά - περιλαμβάνει κείμενα, γραφικά, εικόνες, φωτογραφίες σχέδια, video, ήχους κλπ. (εφεξής περιεχόμενο) αποτελούν πνευματική ιδιοκτησία, κατατεθειμένα σήματα και σήματα υπηρεσιών του Δήμου Ρεθύμνης και κατά περίπτωση και του Ελληνικού Δημοσίου και προστατεύεται από το ισχύον εθνικό, κοινοτικό και διεθνές δίκαιο.</p>
              <p style={{ textAlign: 'justify' }}>Ο Δήμος Ρεθύμνης διατηρεί όλα τα πνευματικά δικαιώματα χρήσης καθώς και τους τίτλους ιδιοκτησίας αναφορικά με το περιεχόμενο, όλα τα αντίγραφα που δημιουργούνται βάσει αυτού, καθώς και το σύνολο των πνευματικών δικαιωμάτων και λοιπών δικαιωμάτων ιδιοκτησίας που άπτονται αυτού.</p>
              <p style={{ textAlign: 'justify' }}>Το περιεχόμενο της ΔΠ διατίθενται στους επισκέπτες/ χρήστες της για προσωπική χρήση. Το περιεχόμενο υπόκειται σε αλλαγές χωρίς σχετική ειδοποίηση κατά την κρίση του διαχειριστή της ΔΠ. Μετά την αποδοχή των όρων χρήσης, επιτρέπεται η μη εμπορική χρήση και η αναπαραγωγή του, ολική ή μερική, με την προϋπόθεση ότι το αναπαραγόμενο προϊόν είναι ελεύθερα διαθέσιμο στη συνέχεια μέσω του Διαδικτύου ή άλλου πρόσφορου μέσου και συνοδεύεται από ευκρινή και διακριτή αναφορά στην πηγή προέλευσής του. Οποιαδήποτε άλλη χρήση απαιτεί την γραπτή ρητή άδεια του ιδιοκτήτη ή του κατόχου των πνευματικών δικαιωμάτων.</p>
              <p style={{ textAlign: 'justify' }}>Τα λοιπά προϊόντα ή υπηρεσίες που αναφέρονται στις ηλεκτρονικές σελίδες της παρούσας ΔΠ και φέρουν τα σήματα των αντίστοιχων οργανισμών, εταιρειών, συνεργατών φορέων, ενώσεων ή εκδόσεων, αποτελούν δική τους πνευματική και βιομηχανική ιδιοκτησία και συνεπώς οι φορείς αυτοί φέρουν τη σχετική ευθύνη.</p>
              <p style={{ textAlign: 'justify' }}>Για οποιαδήποτε ερώτηση σχετικά με τα δικαιώματα αναπαραγωγής οποιουδήποτε τμήματος του περιεχομένου της παρούσας ΔΠ, καθώς και για αιτήσεις έγκρισης αναπαραγωγής περιεχομένου, μπορείτε να επικοινωνήσετε με το it&lt;@&gt;rethymno.gr.</p>
              <p style={{ textAlign: 'justify' }}> </p>
              <p style={{ textAlign: 'justify' }}><strong>Προστασία Προσωπικών Δεδομένων</strong></p>
              <p style={{ textAlign: 'justify' }}>Η διαχείριση και προστασία των προσωπικών δεδομένων του επισκέπτη/ χρήστη της ΔΠ υπόκειται στους όρους της παρούσας ανακοίνωσης καθώς επίσης και στα οριζόμενα στο εθνικό, κοινοτικό και διεθνές δίκαιο σχετικά με την προστασία του ατόμου από την διαχείριση των δεδομένων προσωπικού χαρακτήρα, όπως εκάστοτε ισχύει.</p>
              <p style={{ textAlign: 'justify' }}>Οποιαδήποτε ενδεχόμενη μελλοντική σχετική ρύθμιση θα αποτελέσει αντικείμενο της παρούσας ανακοίνωσης. Σε κάθε περίπτωση ο διαχειριστής διατηρεί το δικαίωμα αλλαγής των όρων προστασίας των προσωπικών δεδομένων, σύμφωνα με το εκάστοτε ισχύον σχετικό νομικό πλαίσιο.</p>
              <p style={{ textAlign: 'justify' }}>Συνεπώς, οι παρόντες όροι προστασίας προσωπικών δεδομένων μπορεί να αναθεωρούνται και να ενημερώνονται οποιαδήποτε στιγμή και χωρίς προειδοποίηση. Οι χρήστες της ΔΠ παρακαλούνται να ελέγχουν ανά τακτά χρονικά διαστήματα τους εν λόγω όρους για τυχόν αλλαγές, καθώς η συνεχής χρήση της ΔΠ συνεπάγεται ότι αποδέχονται όλες τις ενδεχόμενες τροποποιήσεις αυτών.</p>
              <p style={{ textAlign: 'justify' }}>Ο διαχειριστής της ΔΠ συλλέγει προσωπικά στοιχεία των επισκεπτών/ χρηστών της, μόνο όταν αυτοί οι ίδιοι εκουσίως τα παρέχουν. Προσωπικά στοιχεία είναι τα στοιχεία που μπορούν να χρησιμοποιηθούν για τον προσδιορισμό της ταυτότητας ή την επικοινωνία με κάποιο άτομο καθώς και άλλα στοιχεία που αφορούν το εν λόγω άτομο. Τα προσωπικά στοιχεία τα οποία συλλέγονται στη ΔΠ είναι τα ακόλουθα:</p>
              <ul>
                <li>Ονοματεπώνυμο</li>
                <li>Όνομα πατέρα και ονοματεπώνυμο μητέρας</li>
                <li>Ονοματεπώνυμο συζύγου</li>
                <li>Αριθμός ταυτότητας</li>
                <li>Φύλο</li>
                <li>Ημερομηνία γέννησης</li>
                <li>Αριθμός Φορολογικού Μητρώου και Δ.Ο.Υ.</li>
                <li>Ηλεκτρονική διεύθυνση (email)</li>
                <li>Διεύθυνση και αριθμός τηλεφώνου κατοικίας</li>
                <li>Αριθμός τηλεφώνου εργασίας</li>
                <li>Αριθμός κινητού τηλεφώνου και τηλεμοιοτυπίας (φαξ)</li>
              </ul>
              <p> </p>
              <p style={{ textAlign: 'justify' }}>Ο διαχειριστής της ΔΠ δε θα διαθέσει προς πώληση ή άλλως μεταφέρει ή δημοσιοποιήσει προσωπικά στοιχεία των επισκεπτών/ χρηστών της ΔΠ σε τρίτους, που δε σχετίζονται με τον ίδιο (το διαχειριστή της ΔΠ), χωρίς τη συγκατάθεσή του επισκέπτη/ χρήστη, με εξαίρεση την εφαρμογή σχετικών νομικών υπαγορεύσεων και προς τις αρμόδιες και μόνο αρχές.</p>
              <p style={{ textAlign: 'justify' }}>Ο διαχειριστής είναι δυνατόν να επεξεργάζεται τμήμα ή το σύνολο των στοιχείων που έχουν αποστείλει οι επισκέπτες/ χρήστες για λόγους στατιστικούς και βελτίωσης των παρεχομένων υπηρεσιών - πληροφοριών του.</p>
              <p style={{ textAlign: 'justify' }}>Ο επισκέπτης/ χρήστης μπορεί να επικοινωνήσει με το διαχειριστή της ΔΠ προκειμένου να διασταυρώσει την ύπαρξη προσωπικού του αρχείου, την διόρθωση αυτού, την αλλαγή του ή την διαγραφή του.</p>
              <p style={{ textAlign: 'justify' }}>Η παρούσα ΔΠ περιλαμβάνει συνδέσμους (links) προς άλλους δικτυακούς τόπους οι οποίοι βρίσκονται υπό την ευθύνη τρίτων φορέων (φυσικά ή νομικά πρόσωπα). Σε καμία περίπτωση δεν ευθύνεται ο διαχειριστής της ΔΠ για τους όρους προστασίας των προσωπικών δεδομένων τους οποίους αυτοί οι δικτυακοί τόποι ακολουθούν.</p>
              <p style={{ textAlign: 'justify' }}> </p>
              <p style={{ textAlign: 'justify' }}><strong>Ασφάλεια Δεδομένων</strong></p>
              <p>Ο Δήμος Ρεθύμνης χρησιμοποιεί πιστοποιητικά από τον φορέα πιστοποίησης "Let's Encrypt" για την ασφάλεια των δεδομένων που καταχωρείτε στις ιστοσελίδες του www.rethymno.gr, με τους ακόλουθους τρόπους:</p>
              <p>Το πιστοποιητικό "Let's Encrypt" εκδίδεται με βάση τις διεθνείς τεχνολογικές προδιαγραφές. Η πιστοποίηση αυτή σας αποδεικνύει ότι έχετε επισκεφτεί τη σωστή ιστοσελίδα και σας επιτρέπει να καταχωρήσετε με ασφάλεια τα προσωπικά σας στοιχεία.</p>
              <p>Τα προσωπικά σας δεδομένα κρυπτογραφούνται αυτόματα για τη διασφάλιση της προστασίας σας, έτσι ώστε να μπορούν να διαβαστούν μόνο από το Δήμο Ρεθύμνης.</p>
              <p>Ο φορέας πιστοποίησης "Let’s Encrypt Authority X3" παρέχει πιστοποιητικά τύπου X.509 για κρυπτογράφηση TLS. Είναι αναγνωρισμένος φορέας πιστοποίησης διεθνώς και υποστηρίζεται απο οργανισμούς όπως Mozilla Foundation, Facebook, Akamai, Cisco Systems, University of Michigan, the Linux Foundation κ.α. και έχει εκδώσει δεκάδες εκατομμύρια πιστοποιητικά.</p>
              <p> </p>
              <p style={{ textAlign: 'justify' }}><strong>Χρήση περιοχών αλληλεπίδρασης (interactive areas)</strong></p>
              <p style={{ textAlign: 'justify' }}>Ο διαχειριστής της ΔΠ έχει τη δυνατότητα να παράσχει έντυπα επικοινωνίας, πίνακες ανακοινώσεων και άλλες περιοχές αλληλεπίδρασης (interactive areas) στην ΔΠ. Χρήση κάποιας από τις περιοχές αλληλεπίδρασης συνεπάγεται ότι ο επισκέπτης/ χρήστης φέρει την αποκλειστική ευθύνη της επικοινωνίας που πραγματοποιεί και των συνεπειών που απορρέουν από τη δημοσίευση αυτής της επικοινωνίας.</p>
              <p style={{ textAlign: 'justify' }}>Ο επισκέπτης/ χρήστης αποδέχεται και δεσμεύεται σχετικά να μην προβαίνει στις ακόλουθες ενέργειες:</p>
              <ul>
                <li>χρήση περιοχής αλληλεπίδρασης για οποιοδήποτε σκοπό παραβιάζοντας την ισχύουσα νομοθεσία,</li>
                <li>δημοσίευση υλικού που παραβιάζει τα πνευματικά δικαιώματα ιδιοκτησίας τρίτων ή τα δικαιώματα περί απορρήτου ή δημοσιοποίησης στοιχείων τρίτων,</li>
                <li>δημοσίευση υλικού που είναι παράνομο, άσεμνο, δυσφημιστικό, απειλητικό, ενοχλητικό, υβριστικό ή υλικού που προκαλεί δυσαρέσκεια σε τρίτους (φυσικά ή νομικά πρόσωπα), όπως καθορίζεται από τον διαχειριστή της ΔΠ, κατά την κρίση του,</li>
                <li>δημοσίευση υλικού που περιλαμβάνει «ιούς», «κακόβουλο» κώδικα που μπορούν να προκαλέσουν βλάβη ή να καταστρέψουν τα αρχεία ή τα προγράμματα του διαχειριστή της ΔΠ ή των χρηστών του,</li>
                <li>δημοσίευση διαφημίσεων ή άλλου εμπορικού υλικού,</li>
                <li>παραποίηση της ταυτότητα του,</li>
                <li>οποιαδήποτε άλλη ενέργεια που περιορίζει ή εμποδίζει κάποιο άλλο πρόσωπο να χρησιμοποιήσει τις περιοχές αλληλεπίδρασης και γενικότερα την ΔΠ ή ενέργεια που κατά την κρίση του διαχειριστή της ΔΠ εκθέτει τον ίδιο ή κάποιον από τους χρήστες του σε νομικές ευθύνες ή επιζήμιες για αυτούς καταστάσεις.</li>
              </ul>
              <p> </p>
              <p style={{ textAlign: 'justify' }}><strong>Περιορισμός ευθύνης του Δήμου Ρεθύμνης  - Δήλωση αποποίησης</strong></p>
              <p style={{ textAlign: 'justify' }}>Το περιεχόμενο της ΔΠ διατίθεται «ως έχει» και ο Δήμος Ρεθύμνης δεν παρέχει   καμία εγγύηση, ρητή ή μη, σχετικά με την αρτιότητα, ορθότητα, επικαιρότητα, εμπορικότητα, μη παραβίαση ή καταλληλότητα του περιεχομένου αυτού  για οποιαδήποτε χρήση, εφαρμογή ή σκοπό. </p>
              <p style={{ textAlign: 'justify' }}>Ο Δήμος Ρεθύμνης, υπό οποιεσδήποτε συνθήκες, συμπεριλαμβανομένης και της περίπτωσης αμέλειας, δεν ευθύνεται για οποιασδήποτε μορφής ζημία υποστεί ο επισκέπτης/ χρήστης των σελίδων, υπηρεσιών, επιλογών και περιεχομένων της ΔΠ στις οποίες προβαίνει με δική του πρωτοβουλία και με τη γνώση των παρόντων όρων. Επίσης, ο Δήμος Ρεθύμνης δεν εγγυάται ότι οι σελίδες, οι υπηρεσίες, οι επιλογές και τα περιεχόμενα θα παρέχονται χωρίς διακοπή, χωρίς σφάλματα, ότι τα λάθη θα διορθώνονται ή ότι θα δίνονται απαντήσεις σε όλα τα ερωτήματα που της τίθενται. Ομοίως ο Δήμος Ρεθύμνης δεν εγγυάται ότι η ΔΠ ή οποιοδήποτε άλλος συγγενικός δικτυακός τόπος ή οι εξυπηρετητές ("servers") μέσω των οποίων το περιεχόμενο τίθεται στη διάθεση των επισκεπτών/ χρηστών παρέχονται χωρίς "ιούς" ή άλλα επιζήμια συστατικά. Το κόστος των ενδεχόμενων διορθώσεων ή εξυπηρετήσεων, το αναλαμβάνει ο επισκέπτης/ χρήστης και σε καμία περίπτωση ο Δήμος Ρεθύμνης.</p>
              <p style={{ textAlign: 'justify' }}> </p>
              <p style={{ textAlign: 'justify' }}><strong>Χρήση συνδέσμων σε δικτυακούς τόπους τρίτων (links)</strong></p>
              <p style={{ textAlign: 'justify' }}>Η ΔΠ παρέχει τη δυνατότητα πρόσβασης σε δικτυακούς τόπους τρίτων μέσα από κατάλληλους συνδέσμους (links). Οι εν λόγω σύνδεσμοι έχουν τοποθετηθεί αποκλειστικά για τη διευκόλυνση των επισκεπτών/ χρηστών της ΔΠ, ενώ οι δικτυακοί τόποι στους οποίους παραπέμπουν υπόκειται στους αντίστοιχους, των δικτυακών αυτών τόπων, όρους χρήσης. Η τοποθέτηση των συνδέσμων δεν αποτελεί ένδειξη έγκρισης ή αποδοχής του περιεχομένου των αντίστοιχων δικτυακών τόπων από τον διαχειριστή της ΔΠ, ο οποίος δεν φέρει καμία ευθύνη για το περιεχόμενο τους ούτε για τις πρακτικές προστασίας απορρήτου ή την ακρίβεια των υλικών που υπάρχουν σε αυτούς. Εάν ο επισκέπτης/ χρήστης της ΔΠ αποφασίσει να χρησιμοποιήσει, διαμέσου των συνδέσμων της, κάποιον από τους δικτυακούς τόπους τρίτων, αποδέχεται ότι πράττει τούτο με δική του ευθύνη.</p>
              <p style={{ textAlign: 'justify' }}> </p>
              <p style={{ textAlign: 'justify' }}><strong>Εφαρμοστέο δίκαιο και λοιποί όροι</strong></p>
              <p style={{ textAlign: 'justify' }}>Οι ανωτέρω όροι και προϋποθέσεις χρήσης της ΔΠ, καθώς και οποιαδήποτε τροποποίηση, αλλαγή ή αλλοίωσή τους διέπονται και συμπληρώνονται από το εθνικό δίκαιο, το κοινοτικό δίκαιο και τις σχετικές διεθνείς συνθήκες. Οποιαδήποτε διάταξη των ανωτέρω όρων διαπιστωθεί ότι είναι αντίθετη με το ως άνω νομικό πλαίσιο ή καταστεί εκτός ισχύος, παύει αυτοδικαίως να ισχύει και αφαιρείται από το παρόν, χωρίς σε καμία περίπτωση να θίγεται η ισχύς των λοιπών όρων. Το παρόν αποτελεί τη συνολική συμφωνία μεταξύ του διαχειριστή του ΔΠ και του επισκέπτη/ χρήστη των σελίδων και υπηρεσιών του και δε δεσμεύει παρά μόνο αυτούς. Καμία τροποποίηση των όρων αυτών δε θα λαμβάνεται υπόψη και δε θα αποτελεί τμήμα της συμφωνίας αυτής, εάν δεν έχει διατυπωθεί εγγράφως και δεν έχει ενσωματωθεί σε αυτή.</p>
              <p style={{ textAlign: 'justify' }}>Με το παρόν συμφωνείται ρητά ότι ο επισκέπτης/ χρήστης της ΔΠ συναινεί να υπάγονται, οι τυχόν απαιτήσεις, διαφωνίες ή άλλες αντιπαραθέσεις που αφορούν ή προκύπτουν από την εφαρμογή των παρόντων όρων και την εν γένει χρήση της ΔΠ από αυτόν, εφόσον δεν επιλυθούν φιλικά, στη δικαιοδοσία των Δικαστηρίων.</p>
              <p style={{ textAlign: 'justify' }}>Αν έχετε διαπιστώσει οποιαδήποτε προβλήματα στο περιεχόμενο του κόμβου που άπτονται νομικών ή ηθικών θεμάτων, ειδικότερα όσον αφορά στην αναπαραγωγή του και τη χρήση των δικαιωμάτων πνευματικής ιδιοκτησίας, αλλά και για οποιοδήποτε άλλο θέμα παρακαλούμε αποστείλατε ηλεκτρονική επιστολή στη διεύθυνση it&lt;@&gt;rethymno.gr.</p></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommonPage3
