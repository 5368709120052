
import React, { useState, useEffect } from 'react';
import classes from './styles.module.css'
import { Row, Col } from 'antd'
import { useTranslation } from "react-i18next"
import Spinner from 'common/Spinner'

import Modal from './components/Modal/Modal'

import { url } from 'inspector';

const Videos: React.FC = () => {
    const { t } = useTranslation()
    const bgimg = {
        backgroundImage: `url("/img/P1002548.jpg")`,
        height: '',
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        marginTop: '-130px',
        backgroundPosition: '25% 83%'
    };
    const controller = new AbortController();
    const { signal } = controller;
    interface file {
        property1: string;
        property2: string;
    }
    const [videos, setVideos] = useState<[{ caption: string, url: string }]>([{ caption: "", url: "" }]);

    const [isOpen, setOpen] = useState(false)


    var youtubeThumbnail = require('youtube-thumbnail');

    const getVideosPois = async () => {
        //   const furl = `https://rethymno.mitos.cityguideplatform.com/api/files?&lang=el`;
        const furl = `https://rethymno.mitos.cityguideplatform.com/api/pois?&fetch_files=1&file_type_id=5`;

        const response = await fetch(furl,
            {
                signal,
                headers: {
                    'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        }
        else {
            return response.json()
        }

    };
    const getVideosMainCategories = async () => {
        //   const furl = `https://rethymno.mitos.cityguideplatform.com/api/files?&lang=el`;
        const furl = `https://rethymno.mitos.cityguideplatform.com/api/maincategories?&fetch_files=1&file_type_id=5`;

        const response = await fetch(furl,
            {
                signal,
                headers: {
                    'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        }
        else {
            return response.json()
        }

    };
    let vids: [{ caption: string, url: string }] = [{ caption: "", url: "" }];
    vids.shift();
    useEffect(() => {
        getVideosPois()
            .then((res) => {
                const poisVideo = res.success.filter((el: any) => {
                    return el.files[5];
                })

                poisVideo.map((pV: any) => {
                    vids.push(pV.files[5][0]);

                })
                setVideos(vids);
                console.log(vids);
            })
            .catch((e) => {
                console.log(e.message)
            })
        getVideosMainCategories()
            .then((res) => {
                const mainCategoriesVideo = res.success.filter((el: any) => {
                    return el.files[5];
                })

                mainCategoriesVideo.map((mCV: any) => {

                    vids.push(mCV.files[5][0]);

                })

            })
            .catch((e) => {
                console.log(e.message)
            })

    }, [])


    if (videos.length <= 1) {
        return (
            <>
                {<div className={classes.carousel}>
                    <div className={classes.spin}>
                        {videos.length <= 1 ? <Spinner /> : null}
                    </div>
                </div>}
            </>
        )
    }

    return (

        <>
            <Row justify="end">
                <div style={bgimg}>

                    <div className={classes.top_banner_txt}>
                        <div>
                            <span>{t('Γνωρίστε το Ρέθυμνο και τα μνημεία του μέσα από βίντεο')}</span>
                        </div>
                    </div>

                </div>
            </Row>

            <Row justify="end">
                <div className={classes.main}>
                    <br />
                    {/* <strong>{t("Εφαρμογή τρισδιάστατων αναπαραστάσεων")}</strong> */}
                    {videos && videos.map((video, id: any) => {
                        let thumb = youtubeThumbnail(video.url)
                        // console.log(thumb);

                        return <div key={id} className={classes.sketchfab_embed}
                            // style={{ background: url('youtubeThumbnail(video.url)') }}
                            style={{
                                backgroundImage: "url(" + thumb.high.url + ")",
                                backgroundPosition: 'center',
                                // backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}

                        >
                            <Modal caption={video["caption"]} url={video['url']} thumbnail={youtubeThumbnail(video.url)} />
                        </div>
                    })}
                    {/* <div className={classes.sketchfab_embed}><a href="https://sketchfab.com/3d-models/64f12e67cd444f6f98641323ae7f9199" target="_blank"><img src="" /><div className={classes.figcaption}>caption</div></a></div> */}
                    {/* <div className={classes.sketchfab_embed}><a href="https://sketchfab.com/3d-models/d83baac1dd6049dd8420d20130be8071" target="_blank"><img src="" /><div className={classes.figcaption}>Νερατζέ Τζαμί</div></a></div>
                    <div className={classes.sketchfab_embed}><a href="https://sketchfab.com/3d-models/cfdc7ad7dc59483ba2a8c5af240abbad" target="_blank"><img src="" /><div className={classes.figcaption}>Ενετικό Λιμάνι Ρεθύμνου</div></a></div>
                    <div className={classes.sketchfab_embed}><a href="https://sketchfab.com/3d-models/5f812105693d4be291b63a080200c9e3" target="_blank"><img src="" /><div className={classes.figcaption}>Τζαμί Μασταμπά-Παλαιοντολογικό Μουσείο Ρεθύμνου</div></a></div>
                    <div className={classes.sketchfab_embed}><a href="https://sketchfab.com/3d-models/bf5156372a3343c1a74e514cca5247fb" target="_blank"><img src="" /><div className={classes.figcaption}>Φορτέτζα Ρεθύμνου</div></a></div>
                    <div className={classes.sketchfab_embed}><a href="https://sketchfab.com/3d-models/98ba68c871ff4ae28ec4b8efd6a35b6a" target="_blank"><img src="" /><div className={classes.figcaption}>Κελιά-Παλαιοντολογικό Μουσείο Ρεθύμνου</div></a></div>
                    <div className={classes.sketchfab_embed}><a href="https://sketchfab.com/3d-models/5f8e5461748c4a339013053522e87254" target="_blank"><img src="" /><div className={classes.figcaption}>Ενετικό Λιμάνι Ρεθύμνου-Φάρος</div></a></div>
                    <div className={classes.sketchfab_embed}><a href="https://sketchfab.com/3d-models/kara-musa-pasha-cami-a5c26546541e45b09a2718ab8f943efc" target="_blank"><img src="" /><div className={classes.figcaption}>Καρά Μουσά Τζαμί</div></a></div> */}
                    <br /><br />
                </div>
            </Row>
        </>
    );
}

export default Videos