import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { IEvents, GeneralProps, DataProps } from "../services/props"
import { axiosEvents } from "../services/events"


export const useEvents = () => {
  
  const [Events, setEvents] = useState<DataProps[]>([]);

  const [eventsData, setEventsData] = useState<DataProps[]>([]); 
  let eventsSortData: DataProps[] = []; 
  const [listDataTemp, setListDataTemp] = useState<DataProps[]>([]);
  let newEmps: any[] = []
  let newEvents: any[] = []
  const { isLoading, isFetching, error } = useQuery(['events'], () => axiosEvents.getEvents(), {

    onSuccess: (data) => {

     
      let key = 1;
      //  console.log(data)
      newEmps = data.map((listData:any) => {
 
        let array: Array<DataProps> = [];
        
        for (let timePeriod = 0; timePeriod < listData.time_periods.length; timePeriod++) {
      
          let DayStart = parseInt(new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format((listData.time_periods[timePeriod].starting_date * 1000)))
          let DayEnd = parseInt(new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format((listData.time_periods[timePeriod].ending_date * 1000)))
          let year = parseInt(new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format((listData.time_periods[timePeriod].starting_date * 1000)))
          let month = parseInt(new Intl.DateTimeFormat('en-US', { month: 'numeric' }).format((listData.time_periods[timePeriod].starting_date * 1000)))
      
      
          for (let i = 0; i <= DayEnd - DayStart; i++) {
      
            const date = new Date(year, month-1, DayStart+i);
            const unixTimestamp = Math.floor(date.getTime());
            key++
            array.push({
      
              id: key,
              event_id: listData.id,
              day: ( DayStart + i < 10) ? `0${ DayStart + i}` : `${ DayStart + i}`,
              month: new Intl.DateTimeFormat('gr-GR', { month: 'long' }).format((listData.time_periods[timePeriod].starting_date * 1000)),
              year: new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format((listData.time_periods[timePeriod].starting_date * 1000)),
              month_code: new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[timePeriod].starting_date * 1000)),
              day_code: unixTimestamp,
              type: listData.category.title,
              image: listData.image.url,
              event: listData.title,
              hour: `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[timePeriod].starting_date * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[timePeriod].ending_date * 1000))}`,
              price: listData.tickets,
              venues: listData.venues,
              time_periods: [{ starting_date: 1, ending_date: 2 }],
              dateForSort: date
            })
      
          }
        }

        return array
      });
      
      let datas = newEmps
      // console.log(newEmps.length)
      // setEvents(newEmps) 
      for (let i = 0; i < newEmps.length; i++)
      {

        newEmps[i].map( (event: any) => {
          newEvents.push({
      
            id: key,
            event_id: event.event_id,
            day: event.day,
            month: event.month,
            year: event.year,
            month_code: event.month_code,
            day_code: event.day_code,
            type: event.type,
            image:  event.image,
            event: event.event,
            hour:  event.hour,
            price: event.price,
            venues: event.venues,
            time_periods: event.time_periods,
            dateForSort:   event.dateForSort  
          })
        })

       
        setEventsData(prevState => [...prevState, ...newEmps[i]])
        setListDataTemp(prevState => [...prevState, ...newEmps[i]])
      }  

      // eventsSortData = eventsData.sort( (objA, objB) => objB.dateForSort.getTime() - objA.dateForSort.getTime() );  
      setEvents(newEvents) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, Events }
}



export const useEventsMap = () => {
  
  const [Events, setEvents] = useState<GeneralProps[]>([]);
  const { isLoading, error } = useQuery(['events'], () => axiosEvents.getEvents(), {

    onSuccess: (data) => {

      let key = 0
      let array: GeneralProps[] = []
      let lat = '0'
      let lng = '0'
      let hours = ''
      let start_date = ''
      let end_date = ''
      let timePeriodsArray: any[] = []
      let shortMonth: any[] = []
      
      const optionsForDate = { hour12: false, hour: '2-digit', minute: '2-digit', day: 'numeric', month: '2-digit', year: 'numeric' } as const;
      
    
      data.map((listData: any) => {

        key++

        let MonthsArray: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

        if (listData.time_periods != '') {

          let startMonth = 0
          let finishMonth = 0
          let removedItems: any[] = []

          let starting_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].starting_date * 1000))
          let ending_date = new Intl.DateTimeFormat('en-US', { month: 'short' }).format((listData.time_periods[0].ending_date * 1000))

          if (starting_date == ending_date) {
              removedItems = [starting_date]
          }

          for (var i = 0; i < MonthsArray.length; i++) {

            if (MonthsArray[i] == starting_date)
              startMonth = MonthsArray.indexOf(MonthsArray[i]);
            
            if (MonthsArray[i] == ending_date)
              finishMonth = MonthsArray.indexOf(MonthsArray[i]);
          }


          if (startMonth < finishMonth) {
            removedItems = MonthsArray.splice(startMonth, finishMonth - startMonth + 1)
          }

          else if (startMonth > finishMonth) {
            removedItems = MonthsArray.splice(startMonth, startMonth - finishMonth)
            removedItems = [...removedItems, ending_date]
          }
  
          shortMonth.push([...removedItems])

        }

        else{
          shortMonth.push(...MonthsArray)
        }

          
        if (listData.venues.length != 0 && listData.venues[0].coordinate_x != null) {
          lat = listData.venues[0].coordinate_x
          lng = listData.venues[0].coordinate_y
        }
        else {
          lat = '0'
          lng = '0'
        }

        if (listData.time_periods.length == 0) {
          
          hours = '12:00 - 12:00'
          start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000) 
          end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(434800800 * 1000) 
          timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
          
        } 	
        else {
          hours = `${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].starting_date * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).format((listData.time_periods[0].ending_date * 1000))}`
          
          for (let i = 0; i < listData.time_periods.length; i++){

            start_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(listData.time_periods[i].starting_date * 1000)
            end_date = new Intl.DateTimeFormat('el-GR', optionsForDate).format(listData.time_periods[i].ending_date * 1000)
          
            timePeriodsArray.push({ starting_date: start_date, ending_date: end_date })
          }
        }
            
        array.push({
          id: `event_${listData.id.toString()}_${key}`,
          id_item: listData.id.toString(),
          image: listData.image.url,
          title: listData.title,
          description: listData.description,
          lat: lat,
          lng: lng,
          time: '',
          distance: '',
          route_type:'',
          category_id: listData.category.id,
          category_type: listData.category.title,
          month_code: shortMonth,
          hour: hours,
          price: listData.tickets,    
          time_periods: timePeriodsArray,
          venues: listData.venues
        })

        timePeriodsArray=[]
        shortMonth=[]
      });

      setEvents(array)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, error, Events }
}



function setArray(title: string, array: any, setArray:any) {
  
    let Id: number = 0
    let Cardtitle: string = ''
    let Thumbnail: string = ''
    let Type: string = ''
    let Longtitude: string = ''
    let Lantitude: string = ''
    let Description: string = ''
    let Images: string = ''
    let arrayImages: any[] = []
  
    
    for (var item of Object.keys(array)) { 
      switch (item) {
        case 'o:id': Id = array[item]; break;
        case 'o:title': Cardtitle = array[item]; break;
        case 'thumbnail_display_urls': Thumbnail = array[item]['large']; break;
        case 'dcterms:subject': Type = array[item][0]['@value']; break;
        case 'dotsoft:longtitude': Longtitude = array[item][0]['@value']; break;
        case 'dotsoft:lantitude': Lantitude = array[item][0]['@value']; break;
        case 'dotportal:mobileDescription': Description = array[item][0]['@value']; break;
        case 'mobilemedia:lowResImages': Images = array[item][0]['@value']; break;
      }
    }
  
    let images = Images.split('\n') 
    for (let i in images) {
      arrayImages.push({
        id: 'image_' + (parseInt(i)+1),
        url: images[i],
        alt: Cardtitle+' '+(parseInt(i)+1)
      })
    }

    setArray.push({
      id: Id ,
      title: title,
      cardTitle: Cardtitle , 
      media: {
        name: Cardtitle ,
        source: { url: Thumbnail  },
        type: "IMAGE",
      },
      cardDetailedText: Description,
      Images: arrayImages
    })

}
