import React, { useState, useEffect } from "react";
import classes from './sidebar.module.css'
import { useTranslation } from "react-i18next"
import { Select, Radio, Button, Drawer, Space, Divider, Checkbox } from 'antd'
import SideBarList from "./SideBarList"
import { CloseOutlined } from '@ant-design/icons';
import { IoMdCloseCircle } from "react-icons/io";
import { TiThMenu } from "react-icons/ti";


const SideBar = props => {
  const sidebarClass = props.isOpen ? "sidebar_open" : "sidebar";
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation()
  const [checkedList, setCheckedList] = useState([]);
  const [sidebarListOpen, setSideBarListOpen] = useState(false);
  const showPoints = (event) => {
    setSideBarListOpen(!sidebarListOpen);
  };

  const handleSelect = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {

      //Add checked item into checkList
      setCheckedList([...checkedList, value]);

      props.setRadioValue([...checkedList, parseInt(value)]);

    } else {

      //Remove unchecked item from checkList
      const filteredList = checkedList.filter((item) => item !== value);
      setCheckedList(filteredList);
      props.setRadioValue(filteredList)
    }

  };

  const [categories, setCategories] = useState([]);
  // Function to collect data
  const getCategories = async () => {
    const response = await fetch('https://rethymno.mitos.cityguideplatform.com/api/maincategories?lang=el',
      {
        signal,
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    }
    else {
      return response.json()

    }

  };

  useEffect(() => {

    getCategories()
      .then((res) => {
        setCategories(res.success)
      })

      .catch((e) => {
        console.log(e.message)
      })
  }, [props.setRadioValue])

  return (
    <div className={props.isOpen ? classes.sidebar_open : classes.sidebar}>

      <div className={classes.inner}>
        <div className={classes.inner1}>
          <b>{t('Φίλτρα αναζήτησης')}</b><div style={{ float: 'right' }}>{!sidebarListOpen ? <img src="/img/tabicons/grid.png" alt="Εμφάνιση σε Χάρτη" className={classes.btn} title="Εμφάνιση σε Λίστα" onClick={showPoints} /> : <img src="/img/tabicons/submit.png" className={classes.btn} onClick={showPoints} alt="Εμφάνιση σε Λίστα" title="Εμφάνιση σε Χάρτη" />}<IoMdCloseCircle onClick={props.toggleSidebar} className={classes.closeCircle} /></div><br />
        </div>
        <div className="app11">
          <div className={classes.dummy}>
            <label>You Selected:</label>
            {checkedList.map((item, index) => {
              return (
                <div className="chip">
                  <p className="chip-label">{item}</p>
                </div>
              );
            })}
          </div>
          {categories.map(category =>

            <div>
              {(category['extras']['_photo_galleries']['value'] != 1) || (category['id'] == 4) || (category['id'] == 8) ? (
                <div className={classes.category_item}>

                  <div key={category['id']} className={classes.checkboxcontainer}>
                    <input
                      type="checkbox"
                      name="filters"
                      value={category['id']}
                      onChange={handleSelect}
                      key={category['id']}
                    />

                    <label>

                      {category['id'] == 2 ? <div className={classes.carouselicon}><img src='img/categories/Τζαμιά.png' alt={category['name']} />{category['name']} </div> : ""}
                      {category['id'] == 3 ? <div className={classes.carouselicon}><img src='img/categories/Ναοί - Μονές.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 4 ? <div className={classes.carouselicon}><img src='img/categories/Πρόσωπα.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 5 ? <div className={classes.carouselicon}><img src='img/categories/Κρήνες.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 6 ? <div className={classes.carouselicon}><img src='img/categories/Κάστρο Φορτέτζα.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 7 ? <div className={classes.carouselicon}><img src='img/categories/Μνημεία.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 8 ? <div className={classes.carouselicon}><img src='img/categories/Παραδοσιακά επαγγέλματα.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 9 ? <div className={classes.carouselicon}><img src='img/categories/Οικίες.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 10 ? <div className={classes.carouselicon}><img src='img/categories/Κτήρια με επιμέρους αρχιτεκτονικά χαρακτηριστικά.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 11 ? <div className={classes.carouselicon}><img src='img/categories/Μουσεία.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 12 ? <div className={classes.carouselicon}><img src='img/categories/Οδοί - πλατείες.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 13 ? <div className={classes.carouselicon}><img src='img/categories/Ιδιαίτερα Αρχιτεκτονικά Χαρακτηριστικά Κτηρίων.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 14 ? <div className={classes.carouselicon}><img src='img/categories/Οροφογραφίες.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 15 ? <div className={classes.carouselicon}><img src='img/categories/Θυρώματα.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 16 ? <div className={classes.carouselicon}><img src='img/categories/Κιόσκια - Σαχνισιά.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 17 ? <div className={classes.carouselicon}><img src='img/categories/Φωτογραφικό Αρχείο Giuseppe Gerola.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 18 ? <div className={classes.carouselicon}><img src='img/categories/Ιστορία του Ρεθύμνου μέσα απο παλιές φωτογραφίες.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 19 ? <div className={classes.carouselicon}><img src='img/categories/Συλλογή Παλαιοντολογικού.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 20 ? <div className={classes.carouselicon}><img src='img/categories/Συλλογή Λαογραφικού.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 21 ? <div className={classes.carouselicon}><img src='img/categories/Συλλογή Αρχαιολογικού.png' alt={category['name']} />{category['name']}</div> : ""}
                      {category['id'] == 22 ? <div className={classes.carouselicon}><img src='img/categories/Ιστορικοί Χάρτες.png' alt={category['name']} />{category['name']}</div> : ""}
                    </label>

                  </div>

                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
      {sidebarListOpen && !props.isOpen &&
        <button onClick={props.toggleSidebar} className={classes.sidebar_toggle} >
          {!props.isOpen &&
            <div className={classes.menuContainer}><TiThMenu style={{ fontSize: 29, color: '#323F5F' }} /></div>
          }
          {/* {props.isOpen  &&
            <img src='/img/close.png' alt='menu' />
        }      */}
        </button>
      }
      {!sidebarListOpen && props.isOpen &&
        <button onClick={props.toggleSidebar} className={classes.sidebar_toggle} >
          {!props.isOpen &&
            <div className={classes.menuContainer}><TiThMenu style={{ fontSize: 29, color: '#323F5F' }} /></div>
          }
          {/* {props.isOpen  &&
            <img src='/img/close.png' alt='menu' />
        }      */}
        </button>
      }
      {!sidebarListOpen && !props.isOpen &&
        <button onClick={props.toggleSidebar} className={classes.sidebar_toggle} >
          {!props.isOpen &&
            <div className={classes.menuContainer}><TiThMenu style={{ fontSize: 29, color: '#323F5F' }} /></div>
          }
          {/* {props.isOpen  &&
            <img src='/img/close.png' alt='menu' />
        }      */}
        </button>
      }

      {sidebarListOpen && props.isOpen &&
        <button onClick={props.toggleSidebar} className={classes.sidebar_toggle1} >
          {!props.isOpen &&
            <div className={classes.menuContainer}><TiThMenu style={{ fontSize: 29, color: '#323F5F' }} /></div>
          }
          {/* {props.isOpen  &&
            <img src='/img/close.png' alt='menu' />
        }      */}
        </button>
      }
      {sidebarListOpen && !props.isOpen &&
        <button onClick={props.toggleSidebar} className={classes.sidebar_toggle} >
          {!props.isOpen &&
            <div className={classes.menuContainer}><TiThMenu style={{ fontSize: 29, color: '#323F5F' }} /></div>
          }
          {/* {props.isOpen  &&
            <img src='/img/close.png' alt='menu' />
        }      */}
        </button>
      }

      {sidebarListOpen && props.isOpen ? (
        <SideBarList checkedList={checkedList} />
      ) : ""}
    </div>

  );
};
export default SideBar;