import React, { useState, useEffect } from "react"
import classes from "./Search.module.css"
import { useTranslation } from "react-i18next"
import SvgSmallClose from "../Search/SvgSmallClose"
import { useSearch } from '../../hooks/useSearch'
import Spinner from '../../common/Spinner'
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
interface Props {
  onClick?(): void;
}

const Search: React.FC<Props> = ({ onClick }) => {

  const { t, i18n } = useTranslation();

  const [word, setWord] = useState<string>('')

  const { isLoading, error, Results } = useSearch(i18n.language === 'gr' ? 'el' : i18n.language, word)

  const [totalNumberOfResults, setTotalNumberOfResults] = useState<number>(1);

  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    setTotalNumberOfResults(Results.length)
  }, [Results, word])



  const Timer = (e: any) => {
    setLoader(true);
    setTimeout(() => {
      setWord(e)
    }, 300)
  }

  const navigatePoint = (itemId: string) => {
    // Construct the URL with the item ID
    const url = `/rethymno/${itemId}`;

    // Navigate to the new URL
    window.location.href = url;
  };
  const navigateRoute = (itemId: string) => {
    // Construct the URL with the item ID
    const url = `/route/${itemId}`;

    // Navigate to the new URL
    window.location.href = url;
  };
  const navigateEvent = (itemId: string) => {
    // Construct the URL with the item ID
    const url = `/events/${itemId}`;

    // Navigate to the new URL
    window.location.href = url;
  };

  return (
    <>
      <div className={`${classes.search}`}>
        <SvgSmallClose
          className={classes.closeButton}
          width={70}
          height={70}
          strokeWidth={2}
          onClick={() => onClick && onClick()}
        />

        <div className={`${classes.searchWrapper}`}>
          <div className={`${classes.searchContent}`}>
            <h1>{t("Αναζήτηση")}</h1>
            <input
              type={"search"}
              placeholder={t("Πληκτρολογήστε τον όρο αναζήτησης")}
              onChange={e => Timer(e.currentTarget.value)}
            />
          </div>
          <h1 className={classes.result_message}>
            {isLoading ? (
              <Spin indicator={antIcon} className={classes.spinner}/>
            ) : totalNumberOfResults > 0 ? (
              `${t("Βρέθηκαν")} ${totalNumberOfResults} ${t("αποτελέσματα")}`
            ) : word !== '' ? (
              t("Δεν βρέθηκαν αποτελέσματα αναζήτησης")
            ) : null}
          </h1>

          <div className={classes.results_list}>
            {Results &&
              Results.map((item: { [key: string]: any }, index) => (
                <div className={classes.result_item} key={item.id}>
                  {item?.omeka_id ?
                    <section>
                      <h2>{t("Σημεία")}&nbsp;/</h2> <div style={{ cursor: 'pointer' }} onClick={() => navigatePoint(item.id)}><h1>&nbsp;{item.name}</h1></div>

                      {/* <div dangerouslySetInnerHTML={{ __html: item?.description }} /> */}
                    </section>
                    : item?.organizers ?
                      <section>
                        <h2>{t("Εκδήλωσεις")}&nbsp;/</h2>
                        <div style={{ cursor: 'pointer' }} onClick={() => navigateEvent(item.id)}><h1>&nbsp;{item.title}</h1></div>

                        {/* <div dangerouslySetInnerHTML={{ __html: item?.description }} /> */}
                      </section>
                      : <section>
                        <h2>{t("Διαδρομές")}&nbsp;/</h2>
                        <div style={{ cursor: 'pointer' }} onClick={() => navigateRoute(item.id)}><h1>&nbsp;{item.name}</h1></div>

                        {/* <div dangerouslySetInnerHTML={{ __html: item?.description }} /> */}
                      </section>
                  }

                </div>
              ))
            }
          </div>



        </div>
      </div >
    </>
  )
}

export default Search
