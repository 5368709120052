import React, { useState, useContext } from "react"
import type { MenuProps } from 'antd'
import { Row, Col, Drawer, Dropdown, Space, Menu } from 'antd'
import { PushpinOutlined, GlobalOutlined, SearchOutlined, DownOutlined } from '@ant-design/icons'
import 'antd/dist/antd.min.css'
import { HashLink } from 'react-router-hash-link'

import Container from "../../common/Container"
import { Icon } from "../../common/Icon"
import { Link } from "react-router-dom"
import SearchClickComponent from "../Search/SearchClickComponent"
import classes from "./Header.module.css"

import i18next from "i18next"
import LanguageContext from "./../../store/language-context"
import { useTranslation } from "react-i18next"
import cookies from "js-cookie";

import logo from '../../images/logo_gr.jpg';
import logoEN from '../../images/logo_en.jpg';

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menus,
  CustomNavLinkSmall,
  CustomNavLinkSmallSearch,
  CustomNavLinkSmallLanguage,
  CustomNavLinkSocial,
  Label,
  Outline,
  Span,
  GeneralCover,
} from "./styles"

const Header = (props: any) => {


  const [visible, setVisibility] = useState(false)
  const languageCtx = useContext(LanguageContext);
  const [language, setLanguage] = useState();
  const { t, i18n } = useTranslation();

  var currentLanguageCode = cookies.get("i18next") || "el";


  const onClick: MenuProps['onClick'] = ({ key }: any) => {
    // console.log(key)
    setLanguage(key);
    console.log(key);

    i18next.changeLanguage(key);
    languageCtx.languageCodeHandler();
  };

  console.log(languageCtx.languageCodeHandler)


  const menu = (
    <Menu className={classes.menu}
      onClick={onClick}
      items={[
        {
          key: 'el',
          label: (
            <>
              ΕΛ
            </>
          ),
        },
        {
          key: 'en',
          label: (
            <>
              EN
            </>
          )
        },
        {
          key: 'fr',
          label: (
            <>
              FR
            </>
          ),
        },
        {
          key: 'de',
          label: (
            <>
              DE
            </>
          ),
        },
        {
          key: 'it',
          label: (
            <>
              IT
            </>
          ),
        },
      ]}
    />
  )

  const showDrawer = () => {
    setVisibility(!visible)
  };

  const onClose = () => {
    setVisibility(!visible)
  };

  const MenuItem: React.FC = () => {

    return (
      <>
        <Row justify="end">
          <Col xs={24} sm={24} lg={24}>
            <GeneralCover>

              {/* <CustomNavLinkSmall>
          <Span>
            <nav>
              <Link to="/acropolis_of_Lindos" >{t("Acropolis of Lindos")}</Link>
            </nav>
          </Span>
        </CustomNavLinkSmall> */}
              {/*   <CustomNavLinkSmall>
          <Span>
            <nav>
              <Link to="/applications">{t("Applications")}</Link>
            </nav>
          </Span>
        </CustomNavLinkSmall> */}

              {/* <CustomNavLinkSmall>
          <Span>
            <nav>
              <HashLink smooth to="/#MobileSection">Mobile App</HashLink>
            </nav>
          </Span>
        </CustomNavLinkSmall> */}
              <CustomNavLinkSmall>
                <Span>
                  <nav onChange={onClose}>
                    <Link to="/map" className='lang'>{t("Εξερεύνηση")}</Link>
                  </nav>
                </Span>
              </CustomNavLinkSmall>

              <CustomNavLinkSmall>
                <Span>
                  <nav onChange={onClose}>
                    <Link to="/categories" state={{ category: "Όλες" }} className='lang'>{t("Κατηγορίες")}</Link>
                  </nav>
                </Span>
              </CustomNavLinkSmall>
              {/*     <CustomNavLinkSmall>
          <Span>
            <nav>
              <Link to="/map">{t("Διαδρομές")}</Link>
            </nav>
          </Span>
        </CustomNavLinkSmall> */}

              <CustomNavLinkSmall>
                <Span>
                  <nav onChange={onClose}>
                    <Link to="/model3d">{t("Τρισδιάστατη Απεικόνιση")}</Link>
                  </nav>
                </Span>
              </CustomNavLinkSmall>

              <CustomNavLinkSmall>
                <Span>
                  <nav onChange={onClose}>
                    <Link to="/videos">{t("Βίντεο")}</Link>
                  </nav>
                </Span>
              </CustomNavLinkSmall>

              <CustomNavLinkSmall>
                <Span>
                  <nav onChange={onClose}>
                    <Link to="/routes_map">{t("Διαδρομές")}</Link>
                  </nav>
                </Span>
              </CustomNavLinkSmall>

              <CustomNavLinkSmall>
                <Span>
                  <nav onChange={onClose}>
                    <Link to="/events">{t("Εκδηλώσεις")}</Link>
                  </nav>
                </Span>
              </CustomNavLinkSmall>

              {/* <CustomNavLinkSocial>
                <div className={classes.socialSidebar}>
                  <a href="https://www.youtube.com/c/rethymnogronline" target="_blank" >
                    <div className={classes.icon}>
                      <BsYoutube />
                    </div>
                  </a>
                  <a href="https://www.facebook.com/rethymno.gr" target="_blank" >
                    <BsFacebook />
                  </a>
                </div>
              </CustomNavLinkSocial> */}

              <CustomNavLinkSmallSearch className="search">
                <SearchClickComponent item={<Space> <SearchOutlined style={{ margin: '0.2rem -108px 0.2rem 44px !important' }} /> </Space>} />
              </CustomNavLinkSmallSearch>

              <CustomNavLinkSmallLanguage>
                <Dropdown overlay={menu} >
                  <a href="#!" onClick={(e) => e.preventDefault()}><span style={{ display: 'none' }}>Map</span>
                    <span className="whitebubble" style={{ position: 'relative', color: 'black', top: '4px' }}> {currentLanguageCode == 'el' && 'ΕΛ'} {currentLanguageCode == 'en' && 'ΕΝ'} {currentLanguageCode == 'it' && 'IT'} {currentLanguageCode == 'fr' && 'FR'} {currentLanguageCode == 'de' && 'DE'}</span>
                  </a>
                </Dropdown>

              </CustomNavLinkSmallLanguage>



            </GeneralCover>

          </Col >
        </Row >

      </>
    )
  }


  return (

    <HeaderSection>
      <Container>
        <Row justify="space-between" id="home">
          <LogoContainer to="/" aria-label="homepage" className={classes.logoimg} >
            {console.log(i18n.language)!}
            
            {i18n.language === 'el' ?
              <Icon src={'logo_gr.jpg'} width="auto" height="100px" />
              :
              <Icon src={'logo_en.jpg'} width="auto" height="100px" />
            }
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginRight: "1.6rem", marginTop: '2rem' }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menus>{t("Μενού")}</Menus>
              </Col>
              <Col span={10}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
      <Row justify="end">
        <Col xs={24} sm={24} lg={24}>
          <div className={classes.divider}>
            <div className={classes.dividerBlock}></div>
          </div>
        </Col>
      </Row>
    </HeaderSection>
  )
}

export default Header;  