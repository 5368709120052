import React, { useState } from "react"
import { Button, Modal, Image, Row, Col, Typography } from 'antd'
import { CalendarOutlined, TagOutlined } from '@ant-design/icons';
import { MdOutlinePlace } from "react-icons/md";

import classes from "./styles.module.css"
import { useTranslation } from "react-i18next"
import parse from 'html-react-parser';
import { Link } from 'react-router-dom'

interface Card {
  id_item: string
  styleImage: boolean
  type: string
  image: string
  event: string
  location: string
  description: string
  municipality: string
  start: string
  end: string
  t1: any
}

const { Title } = Typography

const Card: React.FC<Card> = ({ id_item, styleImage, type, image, event, location,description, start, end, t1 }: Card) => {
  

  const { t } = useTranslation()

  const [open, setOpen] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false)

  const showModal = () => setOpen(true)

  const hideModal = () => setOpen(false)



  return (
    <>
      <div className={classes.mobapp} id="MobileSection">
        <div className={classes.mobapp_inner}>
          <div className={classes.part1}>
            <h3 onClick={showModal}>  <Link to={`../events/${id_item}`}>{event}</Link></h3>
            <span>
              <div><CalendarOutlined />&nbsp;<span>{start != '12/10/1983, 12:00' ? start != end ? start + ' - ' + end : start : ''} </span></div><br />
              <div><MdOutlinePlace />&nbsp;<span>{location} </span></div><br />
              <div className={classes.price}><TagOutlined />&nbsp;<span className={classes.priceSmall}>{t1}</span></div><br />
              {/* {type} */}
              {showMore ? parse(description) : parse(`${description.substring(0, 655)}...`)}
              <button className={classes.more} onClick={() => { setShowMore(!showMore) }}>{!showMore ? t('Δείτε περισσότερα') : t('Δείτε λιγότερα')}</button>
              <br />
            </span>
          </div>
          <div className={!showMore ? classes.part2a : ""}></div>
          <div className={!showMore ? classes.part2 : classes.part2Open}>
            <p>
              <Link to={`../events/${ id_item }`}> <img src={image} alt={t("Mobile App text")} /></Link>

            </p>
          </div>
        </div>
      </div>

      <div className={classes.divider}>
        <div className={classes.dividerBlock}></div>
      </div>

      <Modal
        title={<><Title level={4}>{event}</Title> <Title level={5} style={{ color: '#265828' }}>{t1}</Title></>}
        centered
        width={1000}
        footer={false}
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
      >

        <Row justify="center" >
          <Col xs={24} sm={10} lg={10}>
            <div className={classes.calendarCard__imgBorder2}>
              <img src={image} alt={event} />
            </div>
          </Col>
          <Col xs={24} sm={14} lg={14}>
            <div className={classes.container} dangerouslySetInnerHTML={{ __html: description }} ></div>
          </Col>
        </Row>


        {/* <div className={classes.p} dangerouslySetInnerHTML={{ __html: !showMore ? textFull : textShort + ' .... ' }} />  */}


      </Modal>




    </>
    // <div className={classes.container} onClick={onClick}>
    // "calendarContent__container__nJzJw calendarContent__overlay__Grid__fbJ9R"     
    //   <div className={`${classes.wrapper} ${styleImage ? classes.boxImage : ""}`} >
    //     <div className={`${classes.box1} ${styleImage ? classes.box1Image : ""}`} style={{ backgroundImage: `url(${image})` }} >
    //       <div className={classes.day} >{day}</div>
    //       <div className={classes.box}><span className={classes.span}>{type}</span></div>
    //       <div className={classes.month}>{month} ({year})</div> 
    //     </div>
    //     <div className={classes.event}>{event} </div>
    //     <div className={classes.municipality}>{municipality.toUpperCase()} </div>
    //     <div className={classes.hour}>{hour}</div> 
    //     <div className={classes.price}><span className={classes.priceSmall}>{t}</span></div>
    //   </div>
    // </div>

  )
}

export default Card