import React, { useState, useEffect, useContext } from "react";
import classes from './sidebarList.module.css'
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import LanguageContext from "../../../store/language-context";
import { Select, Radio, Button, Drawer, Space, Divider, Checkbox } from 'antd'

function CategoryPoint({ categoryID }) {
  const controller = new AbortController();
  const { signal } = controller;
  const { t } = useTranslation();
  const [pointsCat, setPointsCat] = useState([]);
  const languageCtx = useContext(LanguageContext);


  // Function to collect data
  const getPointsCat = async ({ categoryID }) => {
    const furl = `https://rethymno.mitos.cityguideplatform.com/api/pois?category_id=${categoryID}&lang=el`;

    const response = await fetch(furl,
      {
        signal,
        headers: {
          'X-API-KEY': '6AhQEcRa8maJf3NjgdBw',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    }
    else {
      return response.json()
    }

  };

  getPointsCat({ categoryID })
    .then((res) => {
      setPointsCat(res.success)
    })
    .catch((e) => {
      console.log(e.message)
    })


  return (
    <div>
      <div>
        {pointsCat.map((p) => (
          <>
            <div className={classes.list_item}>
              <div className={classes.thumb} style={{ backgroundImage: `url(${p['main_image']})` }}>
                {/* <img src={p['main_image']} alt={p['name']}/> */}<span>{p['name']}</span>
              </div>
              <div className={classes.titlethumb}><strong>{p['name']}</strong>
                {p['address']}

                {categoryID == 2 ? <div className={classes.carouselicon}><img src='img/categories/Τζαμιά.png' />Τζαμιά</div> : ""}
                {categoryID == 3 ? <div className={classes.carouselicon}><img src='img/categories/Ναοί - Μονές.png' />Ναοί - Μονές</div> : ""}
                {categoryID == 4 ? <div className={classes.carouselicon}><img src='img/categories/Πρόσωπα.png' />Σημαντικές Προσωπικότητες</div> : ""}
                {categoryID == 5 ? <div className={classes.carouselicon}><img src='img/categories/Κρήνες.png' />Κρήνες</div> : ""}
                {categoryID == 6 ? <div className={classes.carouselicon}><img src='img/categories/Κάστρο Φορτέτζα.png' />Κάστρο Φορτέτζα</div> : ""}
                {categoryID == 7 ? <div className={classes.carouselicon}><img src='img/categories/Μνημεία.png' />Μνημεία</div> : ""}
                {categoryID == 8 ? <div className={classes.carouselicon}><img src='img/categories/Παραδοσιακά επαγγέλματα.png' />Παραδοσιακά επαγγέλματα</div> : ""}
                {categoryID == 9 ? <div className={classes.carouselicon}><img src='img/categories/Οικίες.png' />Οικίες</div> : ""}
                {categoryID == 10 ? <div className={classes.carouselicon}><img src='img/categories/Κτήρια με επιμέρους αρχιτεκτονικά χαρακτηριστικά.png' />Κτήρια με επιμέρους αρχιτεκτονικά χαρακτηριστικά</div> : ""}
                {categoryID == 11 ? <div className={classes.carouselicon}><img src='img/categories/Μουσεία.png' />Μουσεία</div> : ""}
                {categoryID == 12 ? <div className={classes.carouselicon}><img src='img/categories/Οδοί - πλατείες.png' />Οδοί - πλατείες</div> : ""}
                {categoryID == 13 ? <div className={classes.carouselicon}><img src='img/categories/Ιδιαίτερα Αρχιτεκτονικά Χαρακτηριστικά Κτηρίων.png' />Ιδιαίτερα Αρχιτεκτονικά Χαρακτηριστικά Κτηρίων</div> : ""}
                {categoryID == 14 ? <div className={classes.carouselicon}><img src='img/categories/Οροφογραφίες.png' />Οροφογραφίες</div> : ""}
                {categoryID == 15 ? <div className={classes.carouselicon}><img src='img/categories/Θυρώματα.png' />Θυρώματα</div> : ""}
                {categoryID == 16 ? <div className={classes.carouselicon}><img src='img/categories/Κιόσκια - Σαχνισιά.png' />Κιόσκια - Σαχνισιά</div> : ""}
                {categoryID == 17 ? <div className={classes.carouselicon}><img src='img/categories/Φωτογραφικό Αρχείο Giuseppe Gerola.png' />Φωτογραφικό Αρχείο Giuseppe Gerola</div> : ""}
                {categoryID == 18 ? <div className={classes.carouselicon}><img src='img/categories/Ιστορία του Ρεθύμνου μέσα απο παλιές φωτογραφίες.png' />Ιστορία του Ρεθύμνου μέσα απο παλιές φωτογραφίες</div> : ""}
                {categoryID == 19 ? <div className={classes.carouselicon}><img src='img/categories/Συλλογή Παλαιοντολογικού.png' />Συλλογή Παλαιοντολογικού Μουσείο</div> : ""}
                {categoryID == 20 ? <div className={classes.carouselicon}><img src='img/categories/Συλλογή Λαογραφικού.png' />Συλλογή Λαογραφικού Μουσείο</div> : ""}
                {categoryID == 21 ? <div className={classes.carouselicon}><img src='img/categories/Συλλογή Αρχαιολογικού.png' />Συλλογή Αρχαιολογικού Μουσείο</div> : ""}
                {categoryID == 22 ? <div className={classes.carouselicon}><img src='img/categories/Ιστορικοί Χάρτες.png' />Ιστορικοί Χάρτες</div> : ""}
                <a href={`../rethymno/${p['id']}`} className={classes.btnthumb}>{t('Δείτε Περισσότερα')}</a> 
                {/* <img src="/img/tabicons/morebtn.png" className={classes.btnthumbicon} /> */}
              </div>
            </div>
            
          </>
        ))}
      </div>
    </div>

  );
};
export default CategoryPoint;
